import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SchoolFiltersStatics, IReseauEcole } from '../../models/static.model';
import { validateBasis } from '@angular/flex-layout';
import { map } from 'rxjs/operators';


@Injectable({
	providedIn: 'root'
})
export class StaticDataService {




	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
	}

	getStatics() {
		return this.http.get<SchoolFiltersStatics>(`${this.baseUrl}api/Ecole/choices`).pipe(map(val => { return { ...val, types: [1, 2, 3], typesEstablishment: [] } }));
	}

	getReseauxEcoles() {
		return this.http.get<IReseauEcole[]>(`${this.baseUrl}api/Referentiel/reseauxEcoles`);
	}

	getOffersPrice() {
		return this.http.get<string[]>(`${this.baseUrl}api/Paiement/getOffersPrice/`);
	}

	getOffersDisplayState() {
		return this.http.get<boolean>(`${this.baseUrl}api/Paiement/getDisplayOffersState/`);
	}

}
