import { MatPaginatorIntl } from '@angular/material';

export class MatPaginatorIntlFr extends MatPaginatorIntl {
    itemsPerPageLabel = 'Lignes par page';
    nextPageLabel     = 'Page suivante';
    previousPageLabel = 'Page précédente';

    getRangeLabel = (page, pageSize, length) => {
        if (length === 0 || pageSize === 0) {
            return '0 sur ' + length;
        }
        length = Math.max(length, 0);
        const pageCount = (Math.ceil(length / pageSize));
        return `Page ${page + 1} sur ${pageCount}`;
    }
}
