import { Component, OnInit, Input } from '@angular/core';
import { TYPE_TACHE, STATUT_TACHE, TASK_ACTIONS } from 'src/enums/task.enum';
import { Task } from 'src/models/task.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-task',
	templateUrl: './task.component.html',
	styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit {

	private _task: Task;

	stringTaskType: string;

	@Input() set task(task: Task) {
		this._task = task;
		const obj = JSON.parse(task.data);
		if (task.targetId === null) {
			switch (task.taskType) {
				case TYPE_TACHE.COMPTE:
					this.stringTaskType = obj.Nom && obj.CodeUAI ? `${obj.Nom} | ${obj.CodeUAI}` : `${obj.Nom}`;
					break;
				case TYPE_TACHE.DIPLOME:
					this.stringTaskType = `${task.ecoleNom} | ${task.ecoleReference}`;
					break;
				case TYPE_TACHE.ECOLE:
					this.stringTaskType = `${task.ecoleNom} | ${task.ecoleReference}`;
					break;
				case TYPE_TACHE.IBAN:
					this.stringTaskType = `${task.ecoleNom} | ${task.ecoleReference}`;
					break;
				case TYPE_TACHE.SIGNATURE:
					this.stringTaskType = `${task.ecoleNom} | ${task.ecoleReference}`;
					break;
			}
		} else if (task.taskType === TYPE_TACHE.FORMULE) {
			this.stringTaskType = this.translate.instant('TASKBOARD.TASKTYPE.FORMULE');
		} else {
			this.stringTaskType = `${task.ecoleNom} | ${task.ecoleReference}`;
		}
	}

	get task(): Task {
		return this._task;
	}

	@Input() status;

	taskType = TYPE_TACHE;
	taskStatus = STATUT_TACHE;
	taskActions = TASK_ACTIONS;

	constructor(private translate: TranslateService) { }

	ngOnInit() {
	}

}
