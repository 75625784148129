import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, Injectable } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
    HttpClientModule,
    HttpClient,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material';
import { MatRippleModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorIntlFr } from './classes/matPaginatorIntlFr';

import { NgxPaginationModule } from 'ngx-pagination';

import { HeaderComponent } from './components/layouts/header/header.component';
import { SchoolsListComponent } from './pages/schools-list/schools-list.component';
import { TaskboardComponent } from './pages/taskboard/taskboard.component';
import { GroupsListComponent } from './pages/groups-list/groups-list.component';
import { UsersListComponent } from './pages/users-list/users-list.component';
import { SchoolInfoComponent } from './pages/school-info/school-info.component';
import { DataTableComponent } from './components/utils-components/data-table/data-table.component';
import { BlueSidebarComponent } from './components/utils-components/data-table/blue-sidebar/blue-sidebar.component';
import { AppConfiguration } from './api/app-configuration.service';
import { SchoolSheetComponent } from './pages/school-info/school-sheet/school-sheet.component';
import { SchoolPromiseComponent } from './pages/school-info/school-promise/school-promise.component';
import { SchoolDiplomaComponent } from './pages/school-info/school-diploma/school-diploma.component';
import { SchoolPaymentsComponent } from './pages/school-info/school-payments/school-payments.component';
import { HomeComponent } from './pages/home/home.component';
import { SchoolFilterBoxComponent } from './components/school-components/filter-box/school-filter-box.component';
import { SelectAutoCompleteComponent } from './components/utils-components/select-auto-complete/select-auto-complete.component';
import { FilterTagComponent } from './components/utils-components/filter-tag/filter-tag.component';
import { PopinDeleteComponent } from './components/popins/popin-delete/popin-delete.component';
import { ContextMenuComponent } from './components/utils-components/data-table/context-menu/context-menu.component';
import { SchoolAddComponent } from './pages/school-add/school-add/school-add.component';
import { Observable } from 'rxjs';
import { PopinIbanComponent } from './components/popins/popin-iban/popin-iban.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { PopinPromesseComponent } from './components/popins/popin-promesse/popin-promesse.component';
import { PopinVersementComponent } from './components/popins/popin-versement/popin-versement.component';
import { PopinAddEcolesComponent } from './components/popins/popin-add-ecoles/popin-add-ecoles.component';
import { PopinNewOffreComponent } from './components/popins/popin-new-offre/popin-new-offre.component';
import { GenericPopinComponent } from './components/popins/generic-popin/generic-popin.component';
import { PopinDiplomaComponent } from './components/popins/popin-diploma/popin-diploma.component';
import { PopinErrorComponent } from './components/popins/popin-error/popin-error.component';
import { TaskComponent } from './pages/taskboard/task/task.component';
import { PopinSignatureComponent } from './components/popins/popin-signature/popin-signature.component';
import { TaskDetailsComponent } from './pages/taskboard/task-details/task-details.component';

import { UserInfoComponent } from './pages/user-info/user-info.component';
import { MyHttpInterceptor } from './interceptors/http-interceptor';
import { GroupInfoComponent } from './pages/group-info/group-info.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { ProfilComponent } from './components/account/profil/profil.component';
import { PasswordComponent } from './components/account/password/password.component';
import { FormulesComponent } from './components/account/formules/formules.component';
import { OffresComponent } from './pages/offres/offres.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PopinConfirmationComponent } from './components/popins/popin-confirmation/popin-confirmation.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { PaymentsPromiseItemComponent } from './components/paymentsComponents/payments-promise-item/payments-promise-item.component';
import { ClosePaymentSerieComponent } from './components/popins/close-payment-serie/close-payment-serie.component';
import { StripeSubscribeComponent } from './pages/stripe-subscribe/stripe-subscribe.component';
import { SchoolFilterPromesseComponent } from './components/school-components/filter-promesse/school-filter-promesse.component';
import { SchoolFilterPaymentComponent } from './components/school-components/filter-payment/school-filter-payment.component';

import { ClickOutsideModule } from 'ng-click-outside';
import { UserFilterBoxComponent } from './components/user-components/filter-user/user-filter-box.component';
import { TaskboardFilterTodoComponent } from './components/school-components/filter-taskboard-todo/taskboard-filter-todo.component';
import { TaskboardFilterDoneComponent } from './components/school-components/filter-taskboard-done/taskboard-filter-done.component';
import { FicheEntrepriseComponent } from './components/utils-components/data-table/blue-sidebar/fiche-entreprise/fiche-entreprise.component';
import { FicheEntrerpiseVersementComponent } from './components/utils-components/data-table/blue-sidebar/fiche-entrerpise-versement/fiche-entrerpise-versement.component';
import { RecusComponent } from './pages/recus/recus.component';
import { VersementsRecusFilterBoxComponent } from './components/popins/versements-components/versements-recu-filter-box.component';
import { PopinVersementsWithoutMailComponent } from './components/popins/popin-versements-without-mail/popin-versements-without-mail.component';
import { PopinVersementsReadyToSendComponent } from './components/popins/popin-versements-ready-to-send/popin-versements-ready-to-send.component';
import { RefusedTaskConfirmationComponent } from './components/popins/refused-task-confirmation/refused-task-confirmation.component';
import { BusinessComponent } from './pages/business/business.component';
import { TaxYearComponent } from './components/user-components/tax-year/tax-year.component';
import { PopinEmailComponent } from './components/popins/popin-email/popin-email.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

registerLocaleData(localeFr, 'fr');

@NgModule({
    declarations: [
        AppComponent,
        SchoolsListComponent,
        TaskboardComponent,
        GroupsListComponent,
        UsersListComponent,
        HeaderComponent,
        SchoolInfoComponent,
        DataTableComponent,
        BlueSidebarComponent,
        SchoolPromiseComponent,
        SchoolDiplomaComponent,
        SchoolPaymentsComponent,
        SchoolSheetComponent,
        HomeComponent,
        AppComponent,
        SchoolsListComponent,
        TaskboardComponent,
        GroupsListComponent,
        UsersListComponent,
        HeaderComponent,
        SchoolInfoComponent,
        DataTableComponent,
        SchoolSheetComponent,
        HomeComponent,
        SchoolFilterBoxComponent,
        SchoolFilterPaymentComponent,
        SchoolFilterPromesseComponent,
        UserFilterBoxComponent,
        SelectAutoCompleteComponent,
        FilterTagComponent,
        ContextMenuComponent,
        PopinDeleteComponent,
        SchoolAddComponent,
        PopinIbanComponent,
        PopinPromesseComponent,
        SchoolPaymentsComponent,
        PopinVersementComponent,
        PopinAddEcolesComponent,
        PopinNewOffreComponent,
        PopinConfirmationComponent,
        PopinEmailComponent,
        SchoolPaymentsComponent,
        PopinVersementsReadyToSendComponent,
        GenericPopinComponent,
        PopinDiplomaComponent,
        PopinErrorComponent,
        TaskComponent,
        PopinSignatureComponent,
        PopinVersementsWithoutMailComponent,
        TaskDetailsComponent,
        UserInfoComponent,
        GroupInfoComponent,
        MyAccountComponent,
        ProfilComponent,
        PasswordComponent,
        FormulesComponent,
        OffresComponent,
        ForbiddenComponent,
        NotFoundComponent,
        PaymentsComponent,
        PaymentsPromiseItemComponent,
        ClosePaymentSerieComponent,
        StripeSubscribeComponent,
        TaskboardFilterTodoComponent,
        TaskboardFilterDoneComponent,
        FicheEntrepriseComponent,
        FicheEntrerpiseVersementComponent,
        VersementsRecusFilterBoxComponent,
        RecusComponent,
        RefusedTaskConfirmationComponent,
        BusinessComponent,
        TaxYearComponent,
    ],
    imports: [
        FlexLayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatGridListModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatTabsModule,
        MatCheckboxModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatListModule,
        MatInputModule,
        MatRippleModule,
        MatDividerModule,
        MatDialogModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatTooltipModule,
        ClickOutsideModule,
        MatSelectModule,
        NgxPaginationModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    entryComponents: [
        PopinDeleteComponent,
        PopinIbanComponent,
        PopinPromesseComponent,
        PopinVersementComponent,
        PopinAddEcolesComponent,
        PopinNewOffreComponent,
        PopinConfirmationComponent,
        PopinDeleteComponent,
        PopinIbanComponent,
        GenericPopinComponent,
        PopinDiplomaComponent,
        PopinErrorComponent,
        PopinSignatureComponent,
        PopinVersementsWithoutMailComponent,
        ClosePaymentSerieComponent,
        PopinVersementsReadyToSendComponent,
        RefusedTaskConfirmationComponent,
        PopinEmailComponent,
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorIntlFr,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'fr' },
        { provide: 'BASE_URL', useFactory: getBaseUrl },
        { provide: LOCALE_ID, useValue: 'fr' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MyHttpInterceptor,
            multi: true,
        },
        MatDatepickerModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function AppConfigurationFactory(appConfig: AppConfiguration) {
    return () => appConfig.ensureInit();
}

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            withCredentials: true,
        });

        return next.handle(request);
    }
}
