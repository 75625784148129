import { NgModule } from "@angular/core";
import { Routes, RouterModule, ROUTER_CONFIGURATION } from "@angular/router";
import { TaskboardComponent } from "./pages/taskboard/taskboard.component";
import { SchoolsListComponent } from "./pages/schools-list/schools-list.component";
import { GroupsListComponent } from "./pages/groups-list/groups-list.component";
import { UsersListComponent } from "./pages/users-list/users-list.component";
import { SchoolInfoComponent } from "./pages/school-info/school-info.component";
import { SchoolAddComponent } from "./pages/school-add/school-add/school-add.component";
import { TaskDetailsComponent } from "./pages/taskboard/task-details/task-details.component";
import { UserInfoComponent } from "./pages/user-info/user-info.component";
import { GroupInfoComponent } from "./pages/group-info/group-info.component";
import { MyAccountComponent } from "./pages/my-account/my-account.component";
import { OffresComponent } from "./pages/offres/offres.component";
import { CanActivateRouteGuard } from "./guards/can-activate-route.guard";
import { RouteEnum } from "src/enums/utils.enum";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { PaymentsComponent } from "./pages/payments/payments.component";
import { StripeSubscribeComponent } from "./pages/stripe-subscribe/stripe-subscribe.component";
import { RecusComponent } from "./pages/recus/recus.component";
import { BusinessComponent } from "./pages/business/business.component";

const routes: Routes = [
  {
    path: RouteEnum.BASEURL,
    component: SchoolsListComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.BUSINESS,
    component: BusinessComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.TASKBOARD,
    component: TaskboardComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.SCHOOLS,
    component: SchoolsListComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.GROUPS,
    component: GroupsListComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.USERS,
    component: UsersListComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.RECUS,
    component: RecusComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.SCHOOL_BY_ID,
    component: SchoolInfoComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.CREATE_ECOLE,
    component: SchoolAddComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.CREATE_USER,
    component: UserInfoComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.TASK_BY_ID,
    component: TaskDetailsComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.USER_BY_ID,
    component: UserInfoComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.GROUP_BY_ID,
    component: GroupInfoComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.MY_ACCOUNT,
    component: MyAccountComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.OFFRES,
    component: OffresComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.CREATE_GROUP,
    component: GroupInfoComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.PAYMENTS,
    component: PaymentsComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: RouteEnum.STRIPE_SUBSCRIBE,
    component: StripeSubscribeComponent,
  },
  {
    path: "forbidden",
    component: ForbiddenComponent,
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
