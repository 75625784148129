import { AbstractControl } from "@angular/forms";
import { truncateSync } from "fs";

const alphabet_23: string[] = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "j",
  "k",
  "l",
  "m",
  "n",
  "p",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
const alphabet_26: string[] = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export function checkIfValidUAI(codepostal: any) {
  return (control: AbstractControl) => {
    if (control.value) {
      const code: string = control.value.toLowerCase().replace(/\s/g, "");

      if (control.value.length === 0 || !control.dirty) {
        return null;
      }

      if (control.value.length !== 8 && control.value.length !== 0) {
        return { codeUAI: true };
      }

      if (codepostal) {
        let department = getDepartementFromPostalCode(`${codepostal}`);

        if (department !== code.slice(0, 3)) {
          return { codeUAI: true };
        }
      }

      const codeNbrs = code.slice(0, 7);
      const letter = code[7];

      const foundLetterIndex = +codeNbrs % 23;

      if (alphabet_23[foundLetterIndex] !== letter) {
        return { codeUAI: true };
      } else return null;
    } else return null;
  };
}

export function checkIfValidSiret(
  control: AbstractControl
): { [key: string]: boolean } {
  let returnValue = null;
  if (control.value) {
    const siret = control.value.replace(/\s/g, "");
    if (siret.length != 14 || isNaN(siret)) returnValue = { siret: true };
    else {
      let somme = 0;
      let tmp;
      for (var cpt = 0; cpt < siret.length; cpt++) {
        if (cpt % 2 == 0) {
          // Les positions impaires : 1er, 3è, 5è, etc...
          tmp = siret.charAt(cpt) * 2; // On le multiplie par 2
          if (tmp > 9) tmp -= 9; // Si le résultat est supérieur à 9, on lui soustrait 9
        } else tmp = siret.charAt(cpt);
        somme += parseInt(tmp);
      }
      if (!(somme % 10 == 0)) returnValue = { siret: true };
    }
    return returnValue;
  }
  return returnValue;
}

export function checkIban(bic: string) {
  return (control: AbstractControl): { [key: string]: boolean } => {
    let returnValue = null;
    if (control.value) {
      let iban = control.value.toLowerCase().replace(/\s/g, "");
      const fourFirst = iban.slice(0, 4);
      iban = iban.substring(4, iban.length).concat(fourFirst);
      const ibanArray = iban.split("").map((char) => {
        const indexOf = alphabet_26.indexOf(char);
        if (alphabet_26.indexOf(char) !== -1) {
          return indexOf + 10;
        }
        return char;
      });
      iban = ibanArray.join("");

      if (mod97(iban) !== 1) {
        returnValue = { iban: true };
      }

      if (
        bic.length >= 8 &&
        bic.substring(4, 6).toLowerCase() !==
          control.value.substring(0, 2).toLowerCase() &&
        control.dirty
      ) {
        returnValue = { iban: true };
      }

      return returnValue;
    }
  };
}

export function checkPasswordConsistency(password: any) {
  return (control: AbstractControl): { [key: string]: boolean } => {
    if (password !== control.value && control.dirty) {
      return { password: true };
    } else {
      return null;
    }
  };
}

export function checkBic(iban: string) {
  return (control: AbstractControl): { [key: string]: boolean } => {
    if (
      iban.substring(0, 2).toLowerCase() !==
        control.value.substring(4, 6).toLowerCase() &&
      control.dirty
    ) {
      return { bic: true };
    }
    if (
      !control.value.match(
        /^([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)$/g
      )
    ) {
      return { bic: true };
    } else {
      return null;
    }
  };
}

export function checkIfEmail(
  control: AbstractControl
): { [key: string]: boolean } {
  if (control.value === null || control.value === "") {
    return null;
  }
  if (control.dirty) {
    if (
      !control.value.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*|null$/
      )
    ) {
      return { email: true };
    }
  }

  return null;
}
export function checkIfCheckNbr(
  control: AbstractControl
): { [key: string]: boolean } {
  if (control.dirty && control.value !== "") {
    if (!control.value.match(/^\d{7}$/)) {
      return { checkNbr: true };
    }
  }

  return null;
}
export function checkIfPhone(
  control: AbstractControl
): { [key: string]: boolean } {
  if (control.value === null || control.value === "") {
    return null;
  }
  if (control.dirty) {
    if (
      !control.value.replace(/\s/g, "").match(/^((\+)33|0)[1-9](\d{2}){4}$/)
    ) {
      return { phone: true };
    }
  }

  return null;
}

export function higherThanPrevious(recuNbr: number) {
  return (control: AbstractControl): { [key: string]: boolean } => {
    if (control.dirty && control.value) {
      if (
        !control.value.toString().match(/^[0-9]*$/) ||
        !(control.value >= recuNbr)
      ) {
        return { recuNbr: true };
      } else return null;
    }
    return null;
  };
}

function getDepartementFromPostalCode(postalCode: string): string {
  if (+postalCode.slice(0, 2) === 97) {
    return postalCode.slice(0, 3);
  } else {
    return `0${postalCode.slice(0, 2)}`;
  }
}

function mod97(string) {
  let checksum = string.slice(0, 2),
    fragment;
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}
