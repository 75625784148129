export enum SCHOOL_FILTER_TYPE {
    CODE_UAI = 0,
    CODE_CDM,
    HABILITATIONS,
    REGION,
    DEPARTEMENT,
    TYPE,
}

export enum PROMISE_FILTER_TYPE {
    MONTANT_PROMIS = 0,
    ORDER,
    ETAT,
    ORIGINE,
    YEARS,
}

export enum PAYMENT_FILTER_TYPE {
    MONTANT_VERSE = 0,
    ORDER,
    CODE_GROUPEUR,
}

export enum USER_FILTER_TYPE {
    CODE_UAI = 0,
    REFERENCE,
    PROFIL,
}

export enum VERSEMENTS_RECU_FILTER_TYPE {
    SIRET = 0,
    CODEGROUPEUR,
    CRE,
    TYPEECOLE,
    ORIGINE,
    YEARS,
}

export enum TASKBOARDTODO_FILTER_TYPE {
    TYPE = 0,
}

export enum TASKBOARDOLD_FILTER_TYPE {
    TYPE = 0,
    ETAT,
}

export enum HABILITATIONS {
    Aucune = 0,
    Non_Habilité = 1 << 0, // 1
    A$B = 1 << 2, // 4
    AD = 1 << 4, // 16
}

export enum TYPESECOLES {
    école_membre = 1,
    école_partenaire = 2,
    autres_écoles = 3,
}

export enum MODEPAIEMENT {
    Chèque = 1,
    Virement,
    Espece,
    Prlvt_Sepa,
    TelereglementVirement,
    CB,
}
