export function detectIE() {
	var ua = window.navigator.userAgent;

	// Test values; Uncomment to check result …

	// IE 10
	// ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

	// IE 11
	// ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

	// Edge 12 (Spartan)
	// ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

	// Edge 13
	// ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

	var msie = ua.indexOf('MSIE ');
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	}

	var trident = ua.indexOf('Trident/');
	if (trident > 0) {
		// IE 11 => return version number
		var rv = ua.indexOf('rv:');
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	}

	var edge = ua.indexOf('Edge/');
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	}

	// other browser
	return false;
}

export function IsUploadableFile(file: File) {
	const acceptedFileTypes = ['image/gif', 'image/jpeg', 'image/png', 'application/pdf'];
	return file && acceptedFileTypes.includes(file['type']) && file.size < 2097152;
}




export function downscaleImage(dataUrl, newWidth, imageType, imageArguments) {
	"use strict";
	var image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl;

	// Provide default values
	imageType = imageType || "image/jpeg";
	imageArguments = imageArguments || 0.7;

	// Create a temporary image so that we can compute the height of the downscaled image.
	image = new Image();
	image.src = dataUrl;
	oldWidth = image.width;
	oldHeight = image.height;
	newHeight = Math.floor(oldHeight / oldWidth * newWidth)

	// Create a temporary canvas to draw the downscaled image on.
	canvas = document.createElement("canvas");
	canvas.width = newWidth;
	canvas.height = newHeight;

	// Draw the downscaled image on the canvas and return the new data URL.
	ctx = canvas.getContext("2d");
	ctx.drawImage(image, 0, 0, newWidth, newHeight);
	newDataUrl = canvas.toDataURL(imageType, imageArguments);
	return newDataUrl;
}