import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AccountService } from 'src/app/api/services/account.service';
import { User } from 'src/models/user.model';
import { switchMap } from 'rxjs/internal/operators/switchMap';

@Component({
	selector: 'app-profil',
	templateUrl: './profil.component.html',
	styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

	private _currentUser: User;
	myAccountForm: FormGroup;
	@Input() set currentUser(user: User) {
		if (user) {
			this._currentUser = user;
			this.setAccountForm();
		}
	};
	get currentUser(): User {
		return this._currentUser;
	}

	constructor(private fb: FormBuilder, private accountService: AccountService) { }

	ngOnInit() {
	}

	private setAccountForm() {
		this.myAccountForm = this.fb.group({
			nom: this.currentUser.nom,
			prenom: this.currentUser.prenom,
			email: this.currentUser.email,
			telephone: this.currentUser.telephone,
		})
	}


	updateUserProfile() {
		this.accountService.updateAccountProfile({ ...this.currentUser, ...this.myAccountForm.value }).pipe(switchMap(_ => {
			return this.accountService.loadUserContext()
		})).subscribe();
	}

}
