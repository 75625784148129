import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IEcolePromesse, IEcoleDiplome } from 'src/models/ecole.model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DiplomaService {

	constructor(private http: HttpClient) { }

	getPromesses(id: number): Observable<IEcolePromesse[]> {
		return this.http.get<IEcolePromesse[]>('/api/Ecole/promesse/' + id);
	}

	postDiploma(newDiploma: IEcoleDiplome): Observable<IEcoleDiplome> {
		return this.http.post<IEcoleDiplome>('/api/Ecole/diplome/', newDiploma);
	}

	putDiploma(editedDiploma: IEcoleDiplome, id: number): Observable<IEcoleDiplome> {
		return this.http.put<any>('/api/Ecole/diplome/' + id, editedDiploma);
	}
}
