import { Injectable } from "@angular/core";
import {
  SCHOOL_FILTER_TYPE,
  HABILITATIONS,
  PROMISE_FILTER_TYPE,
  PAYMENT_FILTER_TYPE,
  USER_FILTER_TYPE,
  TASKBOARDTODO_FILTER_TYPE,
  TASKBOARDOLD_FILTER_TYPE,
  VERSEMENTS_RECU_FILTER_TYPE,
} from "../../../../enums/ecole.enum";
import {
  ISchoolFilters,
  IPromiseFilters,
  IPaymentFilters,
  IUserFilters,
  IEcoleDiplome,
  IAPIVersementRecuFilters,
  IEcolePromesse,
  Payment,
  ITaskboardFiltersTodo,
  ITaskboardFiltersOld,
} from "../../../../models/ecole.model";
import { filter } from "rxjs/operators";
import { SchoolApi } from "../../../api/schoolApi";
import {
  Subject,
  Observable,
  of,
  empty,
  BehaviorSubject,
  ReplaySubject,
} from "rxjs";
import { IMPORT_TYPE } from "src/enums/utils.enum";
import { DiplomaService } from "src/app/api/services/diploma.service";
import { IHabilitations } from "src/models/static.model";
import { PromesseService } from "src/app/api/services/promesse.service";
import { VersementService } from "src/app/api/services/versement.service";
import { promise } from "protractor";
import { EntrepriseService } from "src/app/api/services/entreprise.service";

@Injectable({
  providedIn: "root",
})
export class SchoolFunctService {
  //school-list var
  schoolCurrentFilters: { [filtreType: string]: any[] } = {};
  private _totalFilters: number = 0;

  private _totalFilters$: ReplaySubject<number> = new ReplaySubject(1);
  totalFilters$: Observable<number> = this._totalFilters$.asObservable();

  //promise-list var
  promiseCurrentFilters: { [filtreType: string]: any[] } = {};
  private _totalPromiseFilters: number = 0;

  private _totalPromiseFilters$: ReplaySubject<number> = new ReplaySubject(1);
  totalPromiseFilters$: Observable<number> = this._totalPromiseFilters$.asObservable();

  // payment-list var
  paymentCurrentFilters: { [filtreType: string]: any[] } = {};
  private _totalPaymentFilters: number = 0;

  private _totalPaymentFilters$: ReplaySubject<number> = new ReplaySubject(1);
  totalPaymentFilters$: Observable<number> = this._totalPaymentFilters$.asObservable();

  //user-list var
  userCurrentFilters: { [filtreType: string]: any[] } = {};
  private _totalUserFilters: number = 0;

  private _totalUserFilters$: ReplaySubject<number> = new ReplaySubject(1);
  totalUserFilters$: Observable<number> = this._totalUserFilters$.asObservable();

  //taskboard-todo var
  taskboardCurrentFiltersTodo: { [filtreType: string]: any[] } = {};
  private _totalTaskboardFiltersTodo: number = 0;

  private _totalTaskboardFiltersTodo$: ReplaySubject<number> = new ReplaySubject(
    1
  );
  totalTaskboardFiltersTodo$: Observable<number> = this._totalTaskboardFiltersTodo$.asObservable();

  //taskboard-old var
  taskboardCurrentFiltersOld: { [filtreType: string]: any[] } = {};
  private _totalTaskboardFiltersOld: number = 0;

  private _totalTaskboardFiltersOld$: ReplaySubject<number> = new ReplaySubject(
    1
  );
  totalTaskboardFiltersOld$: Observable<number> = this._totalTaskboardFiltersOld$.asObservable();

  //versements-recu var
  VersementsRecuCurrentFilters: { [filtreType: string]: any[] } = {};
  private _totalVersementsRecuFilters: number = 0;

  private _totalVersementsRecuFilters$: ReplaySubject<number> = new ReplaySubject(
    1
  );
  totalVersementsRecuFilters$: Observable<number> = this._totalVersementsRecuFilters$.asObservable();

  private _deleteEvent$: Subject<void> = new Subject();
  deleteEvent$: Observable<void> = this._deleteEvent$.asObservable();

  constructor(
    private schoolApi: SchoolApi,
    private diplomaService: DiplomaService,
    private promesseService: PromesseService,
    private versementService: VersementService,
    private entrepriseService: EntrepriseService
  ) {
    this._totalFilters$.next(0);
  }

  addSchoolFilters(filters: ISchoolFilters) {
    this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_CDM] = this
      .schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_CDM]
      ? this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_CDM]
      : [];
    this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_UAI] = this
      .schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_UAI]
      ? this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_UAI]
      : [];
    this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.HABILITATIONS] = this
      .schoolCurrentFilters[SCHOOL_FILTER_TYPE.HABILITATIONS]
      ? this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.HABILITATIONS]
      : [];
    this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.REGION] = this
      .schoolCurrentFilters[SCHOOL_FILTER_TYPE.REGION]
      ? this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.REGION]
      : [];
    this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.DEPARTEMENT] = this
      .schoolCurrentFilters[SCHOOL_FILTER_TYPE.DEPARTEMENT]
      ? this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.DEPARTEMENT]
      : [];
    this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.TYPE] = this
      .schoolCurrentFilters[SCHOOL_FILTER_TYPE.TYPE]
      ? this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.TYPE]
      : [];
    if (filters.code_uai !== "") {
      this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_UAI].push(
        filters.code_uai
      );
    }
    if (filters.code_cdm !== "") {
      this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_CDM].push(
        filters.code_cdm
      );
    }
    if (filters.habilitations) {
      filters.habilitations.forEach((hab) => {
        if (this.isNewFilter(hab, SCHOOL_FILTER_TYPE.HABILITATIONS)) {
          this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.HABILITATIONS].push(hab);
        }
      });
    }
    if (filters.regions) {
      filters.regions.forEach((reg) => {
        if (this.isNewFilter(reg, SCHOOL_FILTER_TYPE.REGION)) {
          this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.REGION].push(reg);
        }
      });
    }
    if (filters.departements) {
      filters.departements.forEach((dep) => {
        if (this.isNewFilter(dep, SCHOOL_FILTER_TYPE.DEPARTEMENT)) {
          this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.DEPARTEMENT].push(dep);
        }
      });
    }
    if (filters.type) {
      filters.type.forEach((dep) => {
        if (this.isNewFilter(dep, SCHOOL_FILTER_TYPE.TYPE)) {
          this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.TYPE].push(dep);
        }
      });
    }

    this._totalFilters =
      this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_CDM].length +
      this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_UAI].length +
      this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.HABILITATIONS].length +
      this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.REGION].length +
      this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.DEPARTEMENT].length +
      this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.TYPE].length;

    this._totalFilters$.next(this._totalFilters);
  }

  deleteSchoolFilter(filter: {
    id: number;
    name: string;
    type: SCHOOL_FILTER_TYPE;
  }) {
    this.schoolCurrentFilters[filter.type].splice(
      this.schoolCurrentFilters[filter.type]
        .map((el) => el.id)
        .indexOf(filter.id),
      1
    );
    this._totalFilters -= 1;
    this._totalFilters$.next(this._totalFilters);
  }

  deleteAllSchoolFilter() {
    this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_CDM] = [];
    this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_UAI] = [];
    this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.HABILITATIONS] = [];
    this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.REGION] = [];
    this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.DEPARTEMENT] = [];
    this.schoolCurrentFilters[SCHOOL_FILTER_TYPE.TYPE] = [];
    this._totalFilters = 0;
    this._totalFilters$.next(this._totalFilters);
    this._deleteEvent$.next();
  }

  private isNewFilter(filter: any, filterType) {
    return !this.schoolCurrentFilters[filterType].find(
      (val) => val.id === filter.id
    );
  }

  getFiltersAsArray() {
    const keys = Object.keys(this.schoolCurrentFilters);
    const values = keys.map((key) => this.schoolCurrentFilters[key]);
    return this.concatArrays(values, keys);
  }

  private concatArrays(
    arrayOfArray: { id: number; name: string }[][],
    keys: string[]
  ) {
    let finalArray = [];
    arrayOfArray.forEach((array, index) => {
      finalArray = finalArray.concat(
        array.map((el) => {
          if (typeof el === "string") {
            return { id: el, name: el, type: keys[index] };
          } else {
            return { ...el, type: keys[index] };
          }
        })
      );
    });
    return finalArray;
  }

  importCSVFile(
    file: any,
    schoolId: number,
    importType: IMPORT_TYPE
  ): Observable<any> {
    switch (importType) {
      case IMPORT_TYPE.PROMESS:
        return this.schoolApi.importPromesses(file, schoolId);
      case IMPORT_TYPE.ECOLES:
        return this.schoolApi.importEcoles(file);
      case IMPORT_TYPE.PREFECTURES:
        return this.schoolApi.importPrefectures(file);
      default:
        return empty();
    }
  }

  addDiploma(diploma: IEcoleDiplome, schoolId: number) {
    let hab = 0;
    (<any[]>(<unknown>diploma.habilitations)).forEach((habilitation) => {
      hab = hab | HABILITATIONS[<string>habilitation.nom];
    });
    const body = {
      ...diploma,
      ecoleId: schoolId,
      habilitations: hab,
      duree: +diploma.duree,
    };
    return this.diplomaService.postDiploma(body);
  }

  editDiploma(diploma: IEcoleDiplome, schoolId: number, diplomeId: number) {
    let hab = 0;
    (<any[]>(<unknown>diploma.habilitations)).forEach((habilitation) => {
      hab = hab | HABILITATIONS[<string>habilitation.nom];
    });
    const body = {
      ...diploma,
      ecoleId: schoolId,
      habilitations: hab,
      duree: +diploma.duree,
      diplomeId: diplomeId,
    };
    return this.diplomaService.putDiploma(body, diplomeId);
  }

  addPromise(promise: IEcolePromesse, schoolId: number) {
    const body = {
      ...promise,
      siret: promise.siret.toString(),
      ecoleId: schoolId,
      montantPromis: +promise.montantPromis,
    };
    return this.promesseService.postPromise(body);
  }

  editPromise(promise: IEcolePromesse, schoolId: number) {
    const body = {
      ...promise,
      siret: promise.siret.toString(),
      ecoleId: schoolId,
      montantPromis: +promise.montantPromis,
    };
    return this.promesseService.putPromise(body, schoolId);
  }

  getCompanyFromSiret(siretNb: string) {
    return this.entrepriseService.getCompanyFromSiret(siretNb);
  }

  addPayement(transfer: any, promiseId: number) {
    const payment: Payment = {
      banque: transfer.banque,
      complementInfos: transfer.complementInfos,
      montant: transfer.montantVerse,
      modePaiement: transfer.modePaiement,
      date: new Date(transfer.dateVersement).getTime().toString(),
      promesseId: promiseId,
      siret: "",
    };
    return this.versementService.postVersement(payment, promiseId);
  }

  //PROMISE PART
  getPromiseFiltersAsArray() {
    const keys = Object.keys(this.promiseCurrentFilters);
    const values = keys.map((key) => this.promiseCurrentFilters[key]);
    return this.concatArrays(values, keys);
  }

  deletePromiseFilter(filter: {
    id: number;
    name: string;
    type: PROMISE_FILTER_TYPE;
  }) {
    this.promiseCurrentFilters[filter.type].splice(
      this.promiseCurrentFilters[filter.type]
        .map((el) => el.id)
        .indexOf(filter.id),
      1
    );
    this._totalPromiseFilters -= 1;
    this._totalPromiseFilters$.next(this._totalPromiseFilters);
  }

  addPromiseFilters(filters: IPromiseFilters) {
    this.promiseCurrentFilters[PROMISE_FILTER_TYPE.MONTANT_PROMIS] = this
      .promiseCurrentFilters[PROMISE_FILTER_TYPE.MONTANT_PROMIS]
      ? this.promiseCurrentFilters[PROMISE_FILTER_TYPE.MONTANT_PROMIS]
      : [];
    this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORDER] = this
      .promiseCurrentFilters[PROMISE_FILTER_TYPE.ORDER]
      ? this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORDER]
      : [];
    this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ETAT] = this
      .promiseCurrentFilters[PROMISE_FILTER_TYPE.ETAT]
      ? this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ETAT]
      : [];
    this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORIGINE] = this
      .promiseCurrentFilters[PROMISE_FILTER_TYPE.ORIGINE]
      ? this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORIGINE]
      : [];
    if (
      typeof filters.montant_promis === "string" &&
      filters.montant_promis !== ""
    ) {
      this.promiseCurrentFilters[PROMISE_FILTER_TYPE.MONTANT_PROMIS].splice(
        0,
        1,
        filters.montant_promis
      );
    }
    if (filters.order) {
      filters.order.forEach((elem) => {
        if (this.isNewPromiseFilter(elem, PROMISE_FILTER_TYPE.ORDER)) {
          this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORDER].splice(
            0,
            1,
            elem
          );
        }
      });
    }
    if (filters.etat) {
      filters.etat.forEach((elem) => {
        if (this.isNewPromiseFilter(elem, PROMISE_FILTER_TYPE.ETAT)) {
          this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ETAT].push(elem);
        }
      });
    }
    if (filters.origine) {
      filters.origine.forEach((elem) => {
        if (this.isNewPromiseFilter(elem, PROMISE_FILTER_TYPE.ORIGINE)) {
          this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORIGINE].push(elem);
        }
      });
    }

    this._totalPromiseFilters =
      this.promiseCurrentFilters[PROMISE_FILTER_TYPE.MONTANT_PROMIS].length +
      this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ETAT].length +
      this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORDER].length +
      this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORIGINE].length;

    this._totalPromiseFilters$.next(this._totalPromiseFilters);
  }
  private isNewPromiseFilter(filter: any, filterType) {
    return !this.promiseCurrentFilters[filterType].find(
      (val) => val.id === filter.id
    );
  }
  deleteAllPromiseFilter() {
    this.promiseCurrentFilters[PROMISE_FILTER_TYPE.MONTANT_PROMIS] = [];
    this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORDER] = [];
    this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ETAT] = [];
    this.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORIGINE] = [];

    this._totalPromiseFilters = 0;
    this._totalPromiseFilters$.next(this._totalPromiseFilters);
    this._deleteEvent$.next();
  }

  //PAYMENT PART
  getPaymentFiltersAsArray() {
    const keys = Object.keys(this.paymentCurrentFilters);
    const values = keys.map((key) => this.paymentCurrentFilters[key]);
    return this.concatArrays(values, keys);
  }

  deletePaymentFilter(filter: {
    id: number;
    name: string;
    type: PAYMENT_FILTER_TYPE;
  }) {
    this.paymentCurrentFilters[filter.type].splice(
      this.paymentCurrentFilters[filter.type]
        .map((el) => el.id)
        .indexOf(filter.id),
      1
    );
    this._totalPaymentFilters -= 1;
    this._totalPaymentFilters$.next(this._totalPaymentFilters);
  }

  addPaymentFilters(filters: IPaymentFilters) {
    this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.MONTANT_VERSE] = this
      .paymentCurrentFilters[PAYMENT_FILTER_TYPE.MONTANT_VERSE]
      ? this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.MONTANT_VERSE]
      : [];
    this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.CODE_GROUPEUR] = this
      .paymentCurrentFilters[PAYMENT_FILTER_TYPE.CODE_GROUPEUR]
      ? this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.CODE_GROUPEUR]
      : [];

    this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.ORDER] = this
      .paymentCurrentFilters[PAYMENT_FILTER_TYPE.ORDER]
      ? this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.ORDER]
      : [];
    if (
      typeof filters.montant_verse === "string" &&
      filters.montant_verse !== ""
    ) {
      this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.MONTANT_VERSE].push(
        filters.montant_verse
      );
    }
    if (filters.order) {
      filters.order.forEach((elem) => {
        if (this.isNewPaymentFilter(elem, PAYMENT_FILTER_TYPE.ORDER)) {
          this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.ORDER].splice(
            0,
            1,
            elem
          );
        }
      });
    }

    this._totalPaymentFilters =
      this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.MONTANT_VERSE].length +
      this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.ORDER].length;

    this._totalPaymentFilters$.next(this._totalPaymentFilters);
  }
  private isNewPaymentFilter(filter: any, filterType) {
    return !this.paymentCurrentFilters[filterType].find(
      (val) => val.id === filter.id
    );
  }
  deleteAllPaymentFilter() {
    this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.MONTANT_VERSE] = [];
    this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.ORDER] = [];
    this.paymentCurrentFilters[PAYMENT_FILTER_TYPE.CODE_GROUPEUR] = [];

    this._totalPaymentFilters = 0;
    this._totalPaymentFilters$.next(this._totalPaymentFilters);
    this._deleteEvent$.next();
  }

  // USER PART

  getUserFiltersAsArray() {
    const keys = Object.keys(this.userCurrentFilters);
    const values = keys.map((key) => this.userCurrentFilters[key]);
    return this.concatArrays(values, keys);
  }

  deleteUserFilter(filter: {
    id: number;
    name: string;
    type: USER_FILTER_TYPE;
  }) {
    this.userCurrentFilters[filter.type].splice(
      this.userCurrentFilters[filter.type]
        .map((el) => el.id)
        .indexOf(filter.id),
      1
    );
    this._totalUserFilters -= 1;
    this._totalUserFilters$.next(this._totalUserFilters);
  }

  addUserFilters(filters: IUserFilters) {
    this.userCurrentFilters[USER_FILTER_TYPE.CODE_UAI] = this
      .userCurrentFilters[USER_FILTER_TYPE.CODE_UAI]
      ? this.userCurrentFilters[USER_FILTER_TYPE.CODE_UAI]
      : [];
    this.userCurrentFilters[USER_FILTER_TYPE.REFERENCE] = this
      .userCurrentFilters[USER_FILTER_TYPE.REFERENCE]
      ? this.userCurrentFilters[USER_FILTER_TYPE.REFERENCE]
      : [];
    this.userCurrentFilters[USER_FILTER_TYPE.PROFIL] = this.userCurrentFilters[
      USER_FILTER_TYPE.PROFIL
    ]
      ? this.userCurrentFilters[USER_FILTER_TYPE.PROFIL]
      : [];
    if (typeof filters.code_uai === "string" && filters.code_uai !== "") {
      this.userCurrentFilters[USER_FILTER_TYPE.CODE_UAI].push(filters.code_uai);
    }
    if (typeof filters.reference === "string" && filters.reference !== "") {
      this.userCurrentFilters[USER_FILTER_TYPE.REFERENCE].push(
        filters.reference
      );
    }
    if (filters.profil) {
      filters.profil.forEach((elem) => {
        if (this.isNewUserFilter(elem, USER_FILTER_TYPE.PROFIL)) {
          this.userCurrentFilters[USER_FILTER_TYPE.PROFIL].push(elem);
        }
      });
    }

    this._totalUserFilters =
      this.userCurrentFilters[USER_FILTER_TYPE.CODE_UAI].length +
      this.userCurrentFilters[USER_FILTER_TYPE.REFERENCE].length +
      this.userCurrentFilters[USER_FILTER_TYPE.PROFIL].length;

    this._totalUserFilters$.next(this._totalUserFilters);
  }
  private isNewUserFilter(filter: any, filterType) {
    return !this.userCurrentFilters[filterType].find(
      (val) => val.id === filter.id
    );
  }
  deleteAllUserFilter() {
    this.userCurrentFilters[USER_FILTER_TYPE.CODE_UAI] = [];
    this.userCurrentFilters[USER_FILTER_TYPE.REFERENCE] = [];
    this.userCurrentFilters[USER_FILTER_TYPE.PROFIL] = [];

    this._totalUserFilters = 0;
    this._totalUserFilters$.next(this._totalUserFilters);
    this._deleteEvent$.next();
  }

  //TASKBOARDTODO PART
  getTaskboardFiltersTodoAsArray() {
    const keys = Object.keys(this.taskboardCurrentFiltersTodo);
    const values = keys.map((key) => this.taskboardCurrentFiltersTodo[key]);
    return this.concatArrays(values, keys);
  }

  deleteTaskboardFilterTodo(filter: {
    id: number;
    name: string;
    type: TASKBOARDTODO_FILTER_TYPE;
  }) {
    this.taskboardCurrentFiltersTodo[filter.type].splice(
      this.taskboardCurrentFiltersTodo[filter.type]
        .map((el) => el.id)
        .indexOf(filter.id),
      1
    );
    this._totalTaskboardFiltersTodo -= 1;
    this._totalTaskboardFiltersTodo$.next(this._totalTaskboardFiltersTodo);
  }

  addTaskboardFiltersTodo(filters: ITaskboardFiltersTodo) {
    this.taskboardCurrentFiltersTodo[TASKBOARDTODO_FILTER_TYPE.TYPE] = this
      .taskboardCurrentFiltersTodo[TASKBOARDTODO_FILTER_TYPE.TYPE]
      ? this.taskboardCurrentFiltersTodo[TASKBOARDTODO_FILTER_TYPE.TYPE]
      : [];
    if (filters.type) {
      filters.type.forEach((elem) => {
        if (
          this.isNewTaskboardFilterTodo(elem, TASKBOARDTODO_FILTER_TYPE.TYPE)
        ) {
          this.taskboardCurrentFiltersTodo[TASKBOARDTODO_FILTER_TYPE.TYPE].push(
            elem
          );
        }
      });
    }
    this._totalTaskboardFiltersTodo = this.taskboardCurrentFiltersTodo[
      TASKBOARDTODO_FILTER_TYPE.TYPE
    ].length;
    this._totalTaskboardFiltersTodo$.next(this._totalTaskboardFiltersTodo);
  }
  private isNewTaskboardFilterTodo(filter: any, filterType) {
    return !this.taskboardCurrentFiltersTodo[filterType].find(
      (val) => val.id === filter.id
    );
  }
  deleteAllTaskboardFilterTodo() {
    this.taskboardCurrentFiltersTodo[TASKBOARDTODO_FILTER_TYPE.TYPE] = [];

    this._totalTaskboardFiltersTodo = 0;
    this._totalTaskboardFiltersTodo$.next(this._totalTaskboardFiltersTodo);
    this._deleteEvent$.next();
  }

  //TASKBOARDOLD PART
  getTaskboardFiltersOldAsArray() {
    const keys = Object.keys(this.taskboardCurrentFiltersOld);
    const values = keys.map((key) => this.taskboardCurrentFiltersOld[key]);
    return this.concatArrays(values, keys);
  }

  deleteTaskboardFilterOld(filter: {
    id: number;
    name: string;
    type: TASKBOARDOLD_FILTER_TYPE;
  }) {
    this.taskboardCurrentFiltersOld[filter.type].splice(
      this.taskboardCurrentFiltersOld[filter.type]
        .map((el) => el.id)
        .indexOf(filter.id),
      1
    );
    this._totalTaskboardFiltersOld -= 1;
    this._totalTaskboardFiltersOld$.next(this._totalTaskboardFiltersOld);
  }

  addTaskboardFiltersOld(filters: ITaskboardFiltersOld) {
    this.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.TYPE] = this
      .taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.TYPE]
      ? this.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.TYPE]
      : [];
    this.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.ETAT] = this
      .taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.ETAT]
      ? this.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.ETAT]
      : [];
    if (filters.type) {
      filters.type.forEach((elem) => {
        if (this.isNewTaskboardFilterOld(elem, TASKBOARDOLD_FILTER_TYPE.TYPE)) {
          this.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.TYPE].push(
            elem
          );
        }
      });
    }
    if (filters.etat) {
      filters.etat.forEach((elem) => {
        if (this.isNewTaskboardFilterOld(elem, TASKBOARDOLD_FILTER_TYPE.ETAT)) {
          this.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.ETAT] = [];
          this.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.ETAT].push(
            elem
          );
        }
      });
    }
    this._totalTaskboardFiltersOld =
      this.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.TYPE].length +
      this.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.ETAT].length;

    this._totalTaskboardFiltersOld$.next(this._totalTaskboardFiltersOld);
  }
  private isNewTaskboardFilterOld(filter: any, filterType) {
    return !this.taskboardCurrentFiltersOld[filterType].find(
      (val) => val.id === filter.id
    );
  }
  deleteAllTaskboardFilterOld() {
    this.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.TYPE] = [];
    this.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.ETAT] = [];

    this._totalTaskboardFiltersOld = 0;
    this._totalTaskboardFiltersOld$.next(this._totalTaskboardFiltersOld);
    this._deleteEvent$.next();
  }

  // VERSEMENTS-RECU PART

  getVersementsRecuFiltersAsArray() {
    const keys = Object.keys(this.VersementsRecuCurrentFilters);
    const values = keys.map((key) => this.VersementsRecuCurrentFilters[key]);
    return this.concatArrays(values, keys);
  }

  deleteVersementsRecuFilter(filter: {
    id: number;
    name: string;
    type: VERSEMENTS_RECU_FILTER_TYPE;
  }) {
    this.VersementsRecuCurrentFilters[filter.type].splice(
      this.VersementsRecuCurrentFilters[filter.type]
        .map((el) => el.id)
        .indexOf(filter.id),
      1
    );
    this._totalVersementsRecuFilters -= 1;
    this._totalVersementsRecuFilters$.next(this._totalVersementsRecuFilters);
  }

  addVersementsRecuFilters(filters: IAPIVersementRecuFilters) {
    this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.SIRET] = this
      .VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.SIRET]
      ? this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.SIRET]
      : [];
    this.VersementsRecuCurrentFilters[
      VERSEMENTS_RECU_FILTER_TYPE.CODEGROUPEUR
    ] = this.VersementsRecuCurrentFilters[
      VERSEMENTS_RECU_FILTER_TYPE.CODEGROUPEUR
    ]
      ? this.VersementsRecuCurrentFilters[
          VERSEMENTS_RECU_FILTER_TYPE.CODEGROUPEUR
        ]
      : [];
    this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.CRE] = this
      .VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.CRE]
      ? this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.CRE]
      : [];
    this.VersementsRecuCurrentFilters[
      VERSEMENTS_RECU_FILTER_TYPE.TYPEECOLE
    ] = this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.TYPEECOLE]
      ? this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.TYPEECOLE]
      : [];
    this.VersementsRecuCurrentFilters[
      VERSEMENTS_RECU_FILTER_TYPE.ORIGINE
    ] = this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.ORIGINE]
      ? this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.ORIGINE]
      : [];
    this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.YEARS] = this
      .VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.YEARS]
      ? this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.YEARS]
      : [];

    if (typeof filters.siret === "string" && filters.siret !== "") {
      this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.SIRET].push(
        filters.siret
      );
    }
    if (
      typeof filters.codeGroupeur === "string" &&
      filters.codeGroupeur !== ""
    ) {
      this.VersementsRecuCurrentFilters[
        VERSEMENTS_RECU_FILTER_TYPE.CODEGROUPEUR
      ].push(filters.codeGroupeur);
    }
    if (typeof filters.CRE === "string" && filters.CRE !== "") {
      this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.CRE].push(
        filters.CRE
      );
    }
    if (filters.typeEcole) {
      filters.typeEcole.forEach((elem) => {
        if (
          this.isNewVersementsRecuFilter(
            elem,
            VERSEMENTS_RECU_FILTER_TYPE.TYPEECOLE
          )
        ) {
          this.VersementsRecuCurrentFilters[
            VERSEMENTS_RECU_FILTER_TYPE.TYPEECOLE
          ].push(elem);
        }
      });
    }
    if (filters.origine) {
      filters.origine.forEach((elem) => {
        if (
          this.isNewVersementsRecuFilter(
            elem,
            VERSEMENTS_RECU_FILTER_TYPE.ORIGINE
          )
        ) {
          this.VersementsRecuCurrentFilters[
            VERSEMENTS_RECU_FILTER_TYPE.ORIGINE
          ].push(elem);
        }
      });
    }
    if (filters.years) {
      filters.years.forEach((elem) => {
        if (
          this.isNewVersementsRecuFilter(
            elem,
            VERSEMENTS_RECU_FILTER_TYPE.YEARS
          )
        ) {
          this.VersementsRecuCurrentFilters[
            VERSEMENTS_RECU_FILTER_TYPE.YEARS
          ].push(elem);
        }
      });
    }

    this._totalVersementsRecuFilters =
      this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.SIRET]
        .length +
      this.VersementsRecuCurrentFilters[
        VERSEMENTS_RECU_FILTER_TYPE.CODEGROUPEUR
      ].length +
      this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.CRE]
        .length +
      this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.TYPEECOLE]
        .length +
      this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.ORIGINE]
        .length +
      this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.YEARS]
        .length;

    this._totalVersementsRecuFilters$.next(this._totalVersementsRecuFilters);
  }
  private isNewVersementsRecuFilter(filter: any, filterType) {
    return !this.VersementsRecuCurrentFilters[filterType].find(
      (val) => val.id === filter.id
    );
  }
  deleteAllVersementsRecuFilter() {
    this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.SIRET] = [];
    this.VersementsRecuCurrentFilters[
      VERSEMENTS_RECU_FILTER_TYPE.CODEGROUPEUR
    ] = [];
    this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.CRE] = [];
    this.VersementsRecuCurrentFilters[
      VERSEMENTS_RECU_FILTER_TYPE.TYPEECOLE
    ] = [];
    this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.ORIGINE] = [];
    this.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.YEARS] = [];

    this._totalVersementsRecuFilters = 0;
    this._totalVersementsRecuFilters$.next(this._totalVersementsRecuFilters);
    this._deleteEvent$.next();
  }
}
