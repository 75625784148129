export const offres: OffresDescription = {
	subscriber: {
		itemList: [
			"Mise à disposition des promesses de versements des Grands Comptes et Groupes",
			"Suivi et enregistrements de vos encaissements",
			"Génération et gestion des reçus libératoires",
			"Aide juridique"
		],
        prix: "XX,XX€",
        offerName: "",
	},
	extSubscriber: {
		itemList: [
			"Intégration de fichiers d’entreprises / promesses",
			"Gestion de TOUTES les promesses calculées sur notre Plateforme en plus des groupes",
			"Relance des promesses non encaissées",
      "Pointage des règlements",
    ],		
		prix: "XX,XX€",
        offerName: "",
	}
}

export interface OffresDescription {
    subscriber: { offerName: string, itemList: string[], prix: string },
    extSubscriber: { offerName: string, itemList: string[], prix: string },
}
