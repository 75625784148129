import { Injectable, Inject } from "@angular/core";
import { Observable, throwError, of } from "rxjs";
import {
  IEcole,
  IEcoleList,
  IAPIFilters,
  IEcoleDiplome,
  ISearchParams,
  IRegion,
  Signature,
} from "../../models/ecole.model";
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SchoolApi {
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") private baseUrl: string
  ) {}

  getSchoolList(
    pageNb?: number,
    pageSize?: number,
    sortColumn?: string,
    sortOrder?: string
  ): Observable<IEcoleList> {
    let params = new HttpParams();
    if (!!pageNb) params = params.append("pageNb", pageNb.toString());
    if (!!pageSize) params = params.append("pageSize", pageSize.toString());
    if (!!sortColumn) params = params.append("sortColumn", sortColumn);
    if (!!sortOrder) params = params.append("sortOrder", sortOrder);

    return this.http.get<IEcoleList>(`${this.baseUrl}api/Ecole`, {
      params: params,
    });
  }

  getSchoolInfos(id: string): Observable<IEcole> {
    return this.http.get<IEcole>(`${this.baseUrl}api/Ecole` + id);
  }

  getSchoolDetails(id: string): Observable<IEcole> {
    let params = new HttpParams();
    if (!!id) params = params.append("id", id);
    return this.http.get<IEcole>(`${this.baseUrl}api/Ecole/details/` + id);
  }

  getFilteredSchoolList(filters: IAPIFilters) {
    return this.http.post<IEcoleList>(
      `${this.baseUrl}api/Ecole/filteredEcoles`,
      { ...filters }
    );
  }

  getSchoolDiplomas(ecoleId: number): Observable<IEcoleDiplome[]> {
    return this.http.get<IEcoleDiplome[]>(
      `${this.baseUrl}api/Ecole/diplomes/` + ecoleId
    );
  }

  editSchool(ecole: IEcole) {
    return this.http.put<IEcole>(`${this.baseUrl}api/Ecole`, ecole);
  }

  addSchool(ecole: IEcole) {
    return this.http.post<IEcole>(`${this.baseUrl}api/Ecole`, ecole);
  }

  deleteSchool(ecoleId: number) {
    return this.http.delete<void>(`${this.baseUrl}api/Ecole/` + ecoleId).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error);
      })
    );
  }

  postPromessCSVFile(file: any) {
    return this.http.post("", file);
  }
  search(
    searchTerm: string,
    sortOrder: string,
    sortColumn: string,
    pageNumber: number,
    pageSize: number
  ) {
    let searchParams: ISearchParams = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      searchTerm: searchTerm,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
    };
    return this.http.post<IEcoleList>(
      `${this.baseUrl}api/Ecole/search`,
      searchParams
    );
  }

  deleteDiploma(diplomaId: number) {
    return this.http.delete<void>(
      `${this.baseUrl}api/Ecole/diplome/` + diplomaId
    );
  }

  deletePayments(paymentId: number) {
    return this.http.delete<void>(`${this.baseUrl}api/Ecole/` + paymentId);
  }

  getSchoolPayments(schoolId: number) {
    return this.http.get<IEcoleDiplome[]>(
      `${this.baseUrl}api/Ecole/` + schoolId
    );
  }

  getRegionByPostalCode(codePostal: number): Observable<IRegion> {
    return this.http.get<IRegion>(
      `${this.baseUrl}api/Ecole/getRegion/${codePostal}`
    );
  }

  uploadLogo(logo: File, schoolId: number) {
    const formData = new FormData();
    formData.append("logo", logo);

    const headers = new HttpHeaders({ enctype: "multipart/form-data" });

    return this.http.post(
      `${this.baseUrl}api/Ecole/logo/upload/${schoolId}`,
      formData,
      { headers: headers }
    );
  }

  addSignature(signature: Signature) {
    return this.http.post(`${this.baseUrl}api/Ecole/signature`, signature);
  }

  editSignature(signature: Signature) {
    return this.http.post(`${this.baseUrl}api/Ecole/signature/edit`, signature);
  }

  getSignatureList(schoolId: number) {
    return this.http.get(`${this.baseUrl}api/Ecole/signature/${schoolId}`);
  }

  uploadSignature(file: File, signature: Signature) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("ecoleId", signature.ecoleId);
    formData.append("nom", signature.nom);
    formData.append("prenom", signature.prenom);
    formData.append("fonction", signature.fonction);

    const headers = new HttpHeaders({ enctype: "multipart/form-data" });

    return this.http.post(
      `${this.baseUrl}api/Ecole/signature/upload`,
      formData,
      { headers: headers }
    );
  }

  downloadSignature(schoolId: number, id: string): Observable<any> {
    return this.http.get(
      `${this.baseUrl}api/Ecole/signature/download/${schoolId}/${id}`,
      { responseType: "arraybuffer" }
    );
  }

  importPromesses(file: File, schoolId: number) {
    const formData = new FormData();
    formData.append("file", file);

    const headers = new HttpHeaders({ enctype: "multipart/form-data" });

    return this.http.post(
      `${this.baseUrl}api/Ecole/promesses/import/${schoolId}`,
      formData,
      { headers: headers }
    );
  }

  importEcoles(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const headers = new HttpHeaders({ enctype: "multipart/form-data" });

    return this.http.post(`${this.baseUrl}api/Ecole/import`, formData, {
      headers: headers,
    });
  }

  importPrefectures(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const headers = new HttpHeaders({ enctype: "multipart/form-data" });

    return this.http.post(
      `${this.baseUrl}api/Ecole/prefectures/import`,
      formData,
      {
        headers: headers,
      }
    );
  }

  deleteSignature(signatureId: string) {
    return this.http.get(
      `${this.baseUrl}api/Ecole/signature/delete/${signatureId}`
    );
  }

  getSchoolTasksStatus(schoolId: number): Observable<boolean> {
    return this.http.get<boolean>(`api/Ecole/tasksStatus/${schoolId}`);
  }

  uploadTemporaryFile(file: File, schoolId: number): Observable<string> {
    const formData = new FormData();
    formData.append("file", file);

    const headers = new HttpHeaders({ enctype: "multipart/form-data" });

    return this.http.post<string>(
      `/api/Ecole/temporaryUpload/${schoolId}`,
      formData,
      { headers: headers }
    );
  }
}
