import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { IVMSchoolFilters } from '../../../../models/ecole.model';
import { SchoolFunctService } from '../../../services/functional-services/school-service/school-funct.service';
import { TASKBOARDOLD_FILTER_TYPE } from '../../../../enums/ecole.enum';
import { StaticService } from '../../../services/functional-services/static-service/static-service';

@Component({
	selector: 'app-taskboard-filter-done',
	templateUrl: './taskboard-filter-done.component.html',
	styleUrls: ['./taskboard-filter-done.component.scss']
})
export class TaskboardFilterDoneComponent implements OnInit, OnDestroy {

	@Output() getFilteredResult: EventEmitter<void> = new EventEmitter();

	taskboardFilterOldForm: FormGroup;
	currentFilters: IVMSchoolFilters;

	type = [
		{ id: 1, nom: "Compte" },
		{ id: 3, nom: "Iban" },
		{ id: 4, nom: "Ecole" },
		{ id: 5, nom: "Formule" },
	]

	etat = [
		{ id: 2, nom: "Résolue" },
		{ id: 3, nom: "Refusée" }
	]

	typeControl = new FormControl();
	etatControl = new FormControl();

	private _unsubscriber$ = new Subject();

	constructor(private fb: FormBuilder, private schoolFunctService: SchoolFunctService, private staticService: StaticService) {
		this.taskboardFilterOldForm = fb.group({
			type: this.typeControl,
			etat: this.etatControl,
		})

	}

	ngOnInit() {
		this.updateFilterList();

		this.schoolFunctService.deleteEvent$.subscribe(_ => {
			this.currentFilters = this.schoolFunctService.getTaskboardFiltersOldAsArray();
		})
	}

	submit(event) {
		event.preventDefault();
		this.schoolFunctService.addTaskboardFiltersOld(this.taskboardFilterOldForm.value);
		this.updateFilterList();
		this.getFilteredResult.emit()
	}

	reset(event) {
		event.preventDefault();
		this.schoolFunctService.deleteAllTaskboardFilterOld()
		this.taskboardFilterOldForm.get('type').reset();
		this.taskboardFilterOldForm.get('etat').reset();
	}

	deleteFilter(filterTodelete: { id: number, name: string, type: TASKBOARDOLD_FILTER_TYPE }) {
		this.schoolFunctService.deleteTaskboardFilterOld(filterTodelete);
		this.updateFilterList();
	}

	updateFilterList() {
		//update Tag list si on a deja des filtres selectionnés
		this.currentFilters = this.schoolFunctService.getTaskboardFiltersOldAsArray();


		//update 
		this.typeControl.setValue(this.schoolFunctService.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.TYPE]);
		this.etatControl.setValue(this.schoolFunctService.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.ETAT]);
	}

	ngOnDestroy() {
		this._unsubscriber$.next();
		this._unsubscriber$.complete();
	}
}

