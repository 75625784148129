import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { PromesseService } from 'src/app/api/services/promesse.service';
import { debounceTime, switchMap, finalize, takeUntil } from 'rxjs/operators';
import { PaymentPromise, Payment } from 'src/models/ecole.model';
import { MatDialog } from '@angular/material';
import { ClosePaymentSerieComponent } from 'src/app/components/popins/close-payment-serie/close-payment-serie.component';
import { IBank } from '../../../models/static.model';
import { Subject } from 'rxjs';



@Component({
	selector: 'app-payments',
	templateUrl: './payments.component.html',
	styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

	isSearching: boolean;
	searchValue: string = "";

	promises: PaymentPromise[] = [];
	bankList: IBank[];
	modePaiement: number;
	date: string;

	currentNbSession: number = 0;
	maxEntry: number;

	loading: boolean;
	isSendingPayment: boolean;
	errorMessage: string[] = [];
	private _unsubscriber$: Subject<void> = new Subject();

	@ViewChild('input', { static: false }) searchInput: ElementRef;

	constructor(private dialog: MatDialog, private promiseService: PromesseService) { }

	ngOnInit() {
		this.promiseService.getBankList().subscribe(banques => {
			this.bankList = banques;
		});

		this.maxEntry = this.promiseService.getMaxPaymentsEntry();
	}


	getPromiseList(siret: string) {
		this.searchValue = siret;
		this.promises = [];
		if (siret.length >= 9) {
			this.loading = true;
			this.promiseService.getPromisesBySiret(siret).pipe(debounceTime(500)).subscribe(promises => {
				this.loading = false;
				if (promises) {
					this.promises = promises;
				}
			}, err => {
				this.loading = false;
			})
		}
	}

	resetPromiseList() {
		this.promises = [];
	}

	sendPayment(payment: Payment) {
		this.isSendingPayment = true;
		this.errorMessage = [];
		this.promiseService.postPayment(payment).pipe(finalize(() => {
			this.isSendingPayment = false;
		})).subscribe(val => {
			this.currentNbSession = val.actualNb;
			if (val.errorMessage) {
				this.errorMessage[this.promises.map(val => val.promesseId).indexOf(payment.promesseId)] = val.errorMessage;
			} else if (val.maxNb > val.actualNb) {
				this.resetForms();
			} else {
				this.endEntry(true, val.actualNb);
			}
			if (val.maxNb && !this.maxEntry) {
				this.maxEntry = val.maxNb;
			}

		}, err => {
			this.errorMessage[this.promises.map(val => val.promesseId).indexOf(payment.promesseId)] = 'PAYMENTS.GENERICERROR';
		});
	}

	private resetForms() {
		this.searchValue = "";
		this.searchInput.nativeElement.focus();
		this.searchInput.nativeElement.value = "";
		this.promises = [];
	}

	endEntry(auto: boolean, currentNb?: number) {
		this.promiseService.closeEntry().pipe(switchMap(val => {
			const dialogRef = this.dialog.open(ClosePaymentSerieComponent, {
				width: '550px',
				data: { bordereauNbr: val, autoEndEntry: auto, currentNb: currentNb }
			});

			return dialogRef.afterClosed();
		}, () => { this.modePaiement = undefined; this.date = undefined; this.currentNbSession = 0; this.resetForms() })).subscribe()
	}

	updateCurrentPaymentMethod(val: number) {
		if (val !== this.modePaiement) {
			this.modePaiement = val;
		}
	}
	updateCurrentDate(val: string) {
		if (val !== this.date) {
			this.date = val;
		}
	}

	ngOnDestroy() {
		this._unsubscriber$.next();
		this._unsubscriber$.complete();
	}



}
