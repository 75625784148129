import { IEcoleVersement } from "./../../../../../models/ecole.model";
import { VersementService } from "src/app/api/services/versement.service";
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";
import { MatMenu, MatDialog } from "@angular/material";
import { PopinDeleteComponent } from "../../../popins/popin-delete/popin-delete.component";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  TableService,
  RecuToSend,
} from "../../../../../app/services/functional-services/table-service/table-service";
import { LIST_TYPE } from "../../../../../enums/lists.type.enum";
import { PopinVersementComponent } from "src/app/components/popins/popin-versement/popin-versement.component";
import { IEcolePromesse } from "src/models/ecole.model";
import { UserApi } from "src/app/api/userApi";
import { AccountService } from "src/app/api/services/account.service";
import { takeUntil } from "rxjs/operators";
import { UserRights } from "src/models/user.model";
import { PopinConfirmationComponent } from "src/app/components/popins/popin-confirmation/popin-confirmation.component";
import { POPIN_MODS } from "src/enums/utils.enum";
import { TranslateService } from "@ngx-translate/core";
import { USER_ROLE } from "src/enums/users.enum";
import { PromesseService } from "../../../../api/services/promesse.service";
import { PopinEmailComponent } from "../../../popins/popin-email/popin-email.component";
import { GenericPopinComponent } from "src/app/components/popins/generic-popin/generic-popin.component";
import { PopinErrorComponent } from "src/app/components/popins/popin-error/popin-error.component";

@Component({
  selector: "app-context-menu",
  templateUrl: "./context-menu.component.html",
  styleUrls: ["./context-menu.component.scss"],
  exportAs: "contextMenuSpec",
})
export class ContextMenuComponent implements OnInit {
  @Input() tableType: LIST_TYPE;
  @Input() currentItem: any;

  @Output() emitDeleteUser = new EventEmitter();
  @Output() emitDeleteGroup = new EventEmitter();

  listType = LIST_TYPE;
  userRoles = USER_ROLE;
  currentUserRights: UserRights;
  private _unsubscriber$: Subject<void> = new Subject();

  actualTaxYear: number;
  taxYear: number;

  @ViewChild(MatMenu, { static: true }) menu: MatMenu;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private tableService: TableService,
    private promesseService: PromesseService,
    private accountService: AccountService,
    private versementService: VersementService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.accountService.currentUserRights$
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((val) => {
        this.currentUserRights = val;
      });

    this.promesseService.referenceYear$.subscribe((val) => {
      this.actualTaxYear = val;
    });

    this.taxYear = this.promesseService.getTaxYear();
  }

  openDeleteDialog(id): void {
    const dialogRef = this.dialog.open(PopinDeleteComponent, {
      width: "450px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      // delete element
      if (result) {
        this.tableService.onDelete(this.tableType, id);
      }
    });
  }

  openAddPayementDialog(promess: number): void {
    this.tableService.newTransfer(promess);
  }

  goEditFiche() {
    this.router.navigate(["/ecole/" + this.currentItem.schoolId], {
      queryParams: { inModif: true, disabled: false },
    });
  }

  sendReminder(promess: IEcolePromesse) {
    this.tableService.sendReminder(promess.promesseId);
  }

  editPromess(promess: IEcolePromesse) {
    this.tableService.editPromess(promess);
  }

  actionSidebar(promess: IEcolePromesse) {
    this.tableService.entrepriseDetails(promess);
  }

  deleteUser(user) {
    const dialogRef = this.dialog.open(PopinConfirmationComponent, {
      width: "430px",
      data: {
        // mode: POPIN_MODS.EDIT,
        // title: this.translate.instant('SCHOOL.GROUPES.ADD_SCHOOL'),
        bodyComponent: PopinConfirmationComponent,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((val) => {
        if (val === true) {
          this.emitDeleteUser.emit(user);
        }
      });
  }

  deleteGroup(group) {
    const dialogRef = this.dialog.open(PopinConfirmationComponent, {
      width: "430px",
      data: {
        // mode: POPIN_MODS.EDIT,
        // title: this.translate.instant('SCHOOL.GROUPES.ADD_SCHOOL'),
        bodyComponent: PopinConfirmationComponent,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((val) => {
        if (val === true) {
          this.emitDeleteGroup.emit(group);
        }
      });
  }

  generateRecu(versement: IEcoleVersement) {
    this.tableService.startGeneratingReceipt(versement);
  }

  getPaymentDetails(versement: IEcoleVersement) {
    this.tableService.entrepriseVersementDetails(versement);
  }

  getPaymentDetailsFromBusinessVersement(vId: number) {
    this.versementService.getVersementById(vId).subscribe((val) => {
      this.tableService.entrepriseVersementDetails(val);
    });
  }

  sendMail(elem) {
    this.versementService
      .getTargetMail(new RecuToSend(elem.versementId, true))
      .subscribe((val) => {
        if (val != null) {
          const dialogRef = this.dialog.open(PopinEmailComponent, {
            width: "430px",
            data: {
              bodyComponent: PopinEmailComponent,
              mail: val,
            },
          });
          dialogRef
            .afterClosed()
            .pipe(takeUntil(this._unsubscriber$))
            .subscribe((choice) => {
              if (choice === true) {
                this.tableService.sendReceiptMail(
                  new RecuToSend(elem.versementId, true)
                );
              }
            });
        } else {
          this.dialog.open(GenericPopinComponent, {
            width: "630px",
            disableClose: true,
            data: {
              mode: POPIN_MODS.MESSAGE,
              title: this.translate.instant("RECEIPT.ERROR_TITLE"),
              bodyComponent: PopinErrorComponent,
              data: this.translate.instant("SCHOOL.TABLE.POPINS.EMAIL.ERROR"),
            },
          });
        }
      });
  }
}
