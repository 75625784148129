import { MODEPAIEMENT } from "src/enums/ecole.enum";
import { TranslateService } from "@ngx-translate/core";
import { Injectable, INJECTOR } from "@angular/core";
import { RecuLiberatoire, EcoleData } from "src/models/ecole.model";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as writtenNumber from "written-number";
import { isNullOrUndefined } from "util";
import { StaticService } from "src/app/services/functional-services/static-service/static-service";
import { formatCurrency, formatDate } from "@angular/common";
import { truncate } from "fs";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: "root",
})
export class PdfService {
  constructor(
    private translateService: TranslateService,
    private staticService: StaticService
  ) {}

  getDocumentDefinition(
    recu: RecuLiberatoire,
    index: number,
    lastRecuNbr: number,
    taxeYear: number
  ) {
    let titles: any;
    let content: any;

    const moment = require("moment");

    this.translateService
      .get(["RECEIPT.RECEIPT_DISCHARGE", "RECEIPT.TRAINING_LEVY"])
      .subscribe((data) => {
        titles = data;
      });
    this.translateService
      .get([
        "RECEIPT.SCHOOL",
        "RECEIPT.COMPANY",
        "RECEIPT.AMOUNT",
        "RECEIPT.PAYMENT_METHOD",
        "RECEIPT.BANK",
        "RECEIPT.DATE_PAYMENT",
        "RECEIPT.CATEGORY",
        "RECEIPT.RECEIPT_LOC",
        "RECEIPT.RECEIPT_DATE",
      ])
      .subscribe((data) => {
        content = data;
      });

    const length = 595 - 2 * 40;
    const modesPaiement = Object.keys(MODEPAIEMENT).filter(
      (x) => !(parseInt(x) >= 0)
    );
    writtenNumber.defaults.lang = "fr";

    let lettersWrittenAmount = `${writtenNumber(
      Math.floor(recu.montantVerse)
    )} euros`;
    if (recu.montantVerse % 1 !== 0) {
      lettersWrittenAmount = lettersWrittenAmount.concat(
        ` ${writtenNumber((recu.montantVerse % 1) * 100)} centimes`
      );
    }

    return {
      info: {
        title: `recu-${recu.entreprise.siret}-${lastRecuNbr + index}.pdf`,
      },
      pageMargins: [40, 40, 40, 60],
      footer: {
        stack: [
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 10,
                x2: length,
                y2: 10,
                lineWidth: 1,
                color: "#EEEEEE",
              },
            ],
          },
          {
            text: this.writeFooter(recu.ecole),
            style: "footer",
            alignment: "justify",
            margin: [0, 10],
          },
        ],
        margin: [40, 0],
      },
      content: [
        {
          columns: [
            recu.ecole.logoImage
              ? {
                  image: "data:image/png;base64," + recu.ecole.logoImage,
                  fit: [220, 70],
                }
              : "",
            {
              text:
                titles["RECEIPT.RECEIPT_DISCHARGE"] +
                "\nN°" +
                (lastRecuNbr + index),
              style: "title",
              alignment: "right",
              margin: [0, 10],
            },
          ],
        },
        {
          text: titles["RECEIPT.TRAINING_LEVY"] + " " + taxeYear,
          style: "title",
          alignment: "center",
          margin: [0, 40, 0, 20],
        },
        {
          stack: [
            {
              text: content["RECEIPT.SCHOOL"] + " :",
              style: "content",
              margin: [0, 10],
            },
            { text: recu.ecole.codeUai, style: ["content", "info"] },
            { text: recu.ecole.nomEcole, style: ["content", "info"] },
            { text: recu.ecole.adresseEcole, style: ["content", "info"] },
            {
              text: recu.ecole.codePostal + " " + recu.ecole.ville,
              style: ["content", "info"],
            },
          ],
          margin: [0, 10],
        },
        {
          stack: [
            {
              text: content["RECEIPT.COMPANY"] + " :",
              style: "content",
              margin: [0, 10],
            },
            {
              text: `Siret : ${recu.entreprise.siret.substr(
                0,
                9
              )}  ${recu.entreprise.siret.substr(9, 14)}`,
              style: ["content", "info"],
            },
            { text: recu.entreprise.nomEntreprise, style: ["content", "info"] },
            {
              text: recu.entreprise.adresseEntreprise || "-",
              style: ["content", "info"],
            },
            {
              text: this.sendConcatData(
                recu.entreprise.ville,
                recu.entreprise.codePostal
              ),
              style: ["content", "info"],
            },
          ],
          margin: [0, 10],
        },
        {
          stack: [
            {
              text: content["RECEIPT.AMOUNT"] + " :",
              style: "content",
              margin: [0, 10],
            },
            {
              text: formatCurrency(recu.montantVerse, "fr", "€"),
              style: ["content", "info"],
            },
            { text: lettersWrittenAmount, style: ["content", "italic"] },
          ],
          margin: [0, 10],
        },
        {
          columns: [
            {
              stack: [
                {
                  text: content["RECEIPT.PAYMENT_METHOD"] + " :",
                  style: "content",
                  margin: [0, 10],
                },
                {
                  text: recu.modePaiement
                    ? modesPaiement[recu.modePaiement - 1] +
                      (recu.complementInfos ? " : " + recu.complementInfos : "")
                    : "-",
                  style: ["content", "info"],
                },
              ],
              margin: [0, 10],
            },
            {
              stack: [
                {
                  text: content["RECEIPT.BANK"] + " :",
                  style: "content",
                  margin: [0, 10],
                },
                { text: recu.banque || "-", style: ["content", "info"] },
              ],
              margin: [0, 10],
            },
          ],
        },
        {
          columns: [
            {
              stack: [
                {
                  text: content["RECEIPT.DATE_PAYMENT"] + " :",
                  style: "content",
                  margin: [0, 10],
                },
                {
                  text: recu.dateVersement
                    ? formatDate(recu.dateVersement, "dd MMMM yyyy", "fr")
                    : "-",
                  style: ["content", "info"],
                },
              ],
              margin: [0, 10],
            },
          ],
        },
        {
          columns: [
            {
              stack: [
                {
                  text: [
                    {
                      text: content["RECEIPT.RECEIPT_LOC"] + " : ",
                      style: "content",
                    },
                    { text: recu.ecole.ville, style: ["content", "info"] },
                  ],
                },
                {
                  text: [
                    {
                      text: content["RECEIPT.RECEIPT_DATE"] + " : ",
                      style: "content",
                    },
                    {
                      text:
                        moment().valueOf() <
                        moment(recu.dateMaxRecuGeneration).valueOf()
                          ? formatDate(new Date(), "dd MMMM yyyy", "fr")
                          : moment(recu.dateMaxRecuGeneration)
                              .lang("fr")
                              .format("DD MMMM YYYY"),
                      style: ["content", "info"],
                    },
                  ],
                  margin: [0, 5],
                },
              ],
              margin: [0, 10],
            },
            {
              stack: [
                {
                  text: this.sendConcatData(
                    recu.signature.prenom,
                    recu.signature.nom
                  ),
                  style: ["content", "info"],
                },
                {
                  text: recu.signature.fonction || "",
                  style: ["content", "italic"],
                  margin: [0, 5],
                },
                recu.signature.signatureFile
                  ? {
                      image:
                        "data:image/png;base64," + recu.signature.signatureFile,
                      fit: [200, 70],
                      margin: [0, 20],
                    }
                  : "",
              ],
              margin: [0, 10],
            },
          ],
        },
      ],
      styles: {
        title: {
          fontSize: 17,
        },
        subtitle: {
          fontSize: 11,
          italics: true,
        },
        content: {
          fontSize: 11,
        },
        info: {
          fontSize: 13,
          bold: true,
        },
        italic: {
          italics: true,
        },
        footer: {
          fontSize: 10,
          lineHeight: 1.3,
        },
      },
    };
  }

  private sendConcatData(string1: string, string2: string): string {
    if (!string1 && !string2) {
      return "-";
    } else if (string1 && string2) {
      return string1 + " " + string2;
    } else {
      return string1 || string2;
    }
  }

  private writeFooter(ecole: EcoleData) {
    let footerString = "";

    // remove school data that doesn't appear in footer
    const dataToPrint = Object.entries(ecole).filter(
      ([key, value]) =>
        key !== "logo" &&
        key !== "logoImage" &&
        key !== "signature" &&
        key !== "habilitations"
    );

    footerString =
      "{{reference}} - {{nomEcole}} - {{adresseEcole}} - {{codePostal}} {{ville}}{{telephone}}{{email}}{{codeUai}} ";
    let tempStr = "";
    let afterStr = "";
    let isNewLineDone = false;
    dataToPrint.forEach(([key, value], index, array) => {
      if (!isNullOrUndefined(value)) {
        if (footerString.indexOf("{{" + key + "}}") >= 0) {
          if (key == "telephone") {
            tempStr = "\nTél : ";
            afterStr = " ";
            isNewLineDone = true;
          }
          if (key == "email") {
            tempStr = "Email : ";
            afterStr = " ";
            if (isNewLineDone === false) tempStr = "\n" + tempStr;
            else tempStr = " - " + tempStr;
            isNewLineDone = true;
          }
          if (key == "codeUai") {
            tempStr = "Code UAI : ";
            afterStr = " ";
            if (isNewLineDone === false) tempStr = "\n" + tempStr;
            else tempStr = " - " + tempStr;
            isNewLineDone = true;
          }

          footerString = footerString.replace(
            "{{" + key + "}}",
            tempStr + value + afterStr
          );
          tempStr = "";
          afterStr = "";
        }
      }
    });
    footerString = footerString.replace(/\{\{[a-zA-Z]*\}\}/g, "");
    return footerString;
  }
}
