import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupList, Group } from '../../models/group.model';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';



@Injectable({
	providedIn: 'root'
})
export class GroupApi {
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
	}

	getGroupList(pageNb?: number, pageSize?: number, orderBy?: string, sortOrder?: string): Observable<any> {
		let params = new HttpParams();
		if (!!pageNb) params = params.append('pageNb', pageNb.toString());
		if (!!pageSize) params = params.append('pageSize', pageSize.toString());
		if (!!orderBy) params = params.append('orderBy', orderBy);
		if (!!sortOrder) params = params.append('sortOrder', sortOrder);

		return this.http.get<any>(`${this.baseUrl}api/Groupe/groupList/`, { params: params });
	}

	getGroupDetails(id: string): Observable<any> {
		let params = new HttpParams();
		if (!!id) params = params.append('id', id);
		return this.http.get<any>(`${this.baseUrl}api/Groupe/details/` + id);
	}

	addSchools(groupId, ecoles) {
		return this.http.post<any>(`${this.baseUrl}api/Groupe/` + groupId + `/addSchools`, ecoles);
	}

	editGroupInfo(group: { nom: string, users: any }, groupId: number): Observable<any> {
		return this.http.post<any>(`${this.baseUrl}api/Groupe/${groupId}/edit`, group)
	}

	createGroup(group: Group): Observable<any> {
		return this.http.post<any>(`${this.baseUrl}api/Groupe/create`, group)
	}

	deleteGroup(groupId) {
		return this.http.post<any>(`${this.baseUrl}api/Groupe/${groupId}/delete`, {});
	}

	editGroup(group: any, groupId: string) {
		return this.http.post<any>(`${this.baseUrl}api/Groupe/${groupId}/Edit`, group);
	}

	addGroup(group: any) {
		return this.http.post<any>(`${this.baseUrl}api/Groupe/Create`, group);
	}
	searchUserForGroup(searchString: string): Observable<{ nom: string, prenom: string, userId: number }[]> {
		return this.http.get<{ nom: string, prenom: string, userId: number }[]>(`${this.baseUrl}api/Groupe/searchUser/${searchString}`);
	}


	getFirstFifteenUsers() {
		return this.http.get<{ nom: string, prenom: string, userId: number }[]>(`api/Groupe/users`);
	}

}