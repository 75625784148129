import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'app-popin-delete',
  templateUrl: './popin-delete.component.html',
  styleUrls: ['./popin-delete.component.scss']
})
export class PopinDeleteComponent {

  constructor(public dialogRef: MatDialogRef<PopinDeleteComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
