import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { StaticDataService } from '../../../api/static-data.service';
import { IHabilitations, IDepartment, IRegion, ITypeSchool, IReseauEcole } from '../../../../models/static.model';
import { HABILITATIONS } from '../../../../enums/ecole.enum';
import { TYPESECOLES } from '../../../../enums/ecole.enum';

@Injectable({
    providedIn: 'root',
})
export class StaticService {
    private _habilitations$: ReplaySubject<any> = new ReplaySubject(1);
    habilitations$: Observable<IHabilitations[]> = this._habilitations$.asObservable();
    _habilitations: IHabilitations[];

    private _typesEcoles$: BehaviorSubject<any> = new BehaviorSubject(null);
    typesEcoles$: Observable<ITypeSchool[]> = this._typesEcoles$.asObservable();

    habilitationsDictionnary: { [filtreType: number]: string } = {};
    typesDictionnary: { [filtreType: number]: string } = {};

    private _departements$: BehaviorSubject<any> = new BehaviorSubject(null);
    departements$: Observable<IDepartment[]> = this._departements$.asObservable();

    private _regions$: BehaviorSubject<any> = new BehaviorSubject(null);
    regions$: Observable<IRegion[]> = this._regions$.asObservable();

    private _reseauEcole$: BehaviorSubject<IReseauEcole[]> = new BehaviorSubject(null);
    reseauEcole$: Observable<IReseauEcole[]> = this._reseauEcole$.asObservable();

    private _schoolReferentials$: ReplaySubject<{
        habilitations: IHabilitations[];
        types: ITypeSchool[];
    }> = new ReplaySubject(1);
    schoolReferentials$: Observable<{
        habilitations: IHabilitations[];
        types: ITypeSchool[];
    }> = this._schoolReferentials$.asObservable();

    constructor(private staticsData: StaticDataService) {}

    getStatics() {
        this.staticsData.getStatics().subscribe((val) => {
            if (val) {
                this._regions$.next(val.regions);
                this._departements$.next(val.departements);
                this._habilitations = val.habilitations.map((el, index) => this.buildHabilitationsObject(el, index));
                this._habilitations$.next(
                    val.habilitations.map((el, index) => this.buildHabilitationsObject(el, index))
                );
                this._typesEcoles$.next(val.types.map((el, index) => this.buildTypesEcolesObject(el, index)));
                this._schoolReferentials$.next({
                    habilitations: val.habilitations.map((el, index) => this.buildHabilitationsObject(el, index)),
                    types: val.types.map((el, index) => this.buildTypesEcolesObject(el, index)),
                });
            }
        });
        this.staticsData.getReseauxEcoles().subscribe((val) => {
            if (val) {
                this._reseauEcole$.next(val);
            }
        });
    }

    private buildHabilitationsObject(habilitation: number, index: number): IHabilitations {
        let enumKeys = Object.keys(HABILITATIONS).filter((x) => !(parseInt(x) >= 0));
        this.habilitationsDictionnary[habilitation] = enumKeys[index];
        return { habilitationId: habilitation, nom: enumKeys[index].replace('_', ' ').replace('$', ' - ') };
    }

    habilitationToString(habilitations: number): string {
        const habString = this._habilitations.filter((hab) => habilitations & hab.habilitationId);
        return habString.map((hab) => hab.nom).join(', ');
    }

    private buildTypesEcolesObject(type: number, index: number): ITypeSchool {
        let enumKeys = Object.keys(TYPESECOLES).filter((x) => !(parseInt(x) >= 0));
        this.typesDictionnary[type] = enumKeys[index];
        return { id: type, nom: enumKeys[index].replace('_', ' ') };
    }
}
