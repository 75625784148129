import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UserList } from '../../models/user.model';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { IAPIUserFilters } from 'src/models/ecole.model';


@Injectable({
	providedIn: 'root'
})
export class UserApi {

	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
	}

	getUserList(pageNb?: number, pageSize?: number, orderBy?: string, sortOrder?: string): Observable<any> {
		let params = new HttpParams();
		if (!!pageNb) params = params.append('pageNb', pageNb.toString());
		if (!!pageSize) params = params.append('pageSize', pageSize.toString());
		if (!!orderBy) params = params.append('orderBy', orderBy);
		if (!!sortOrder) params = params.append('sortOrder', sortOrder);

		return this.http.get<any>(`${this.baseUrl}api/Utilisateurs/list`, { params: params });
	}

	getUserDetails(id: string): Observable<any> {
		let params = new HttpParams();
		if (!!id) params = params.append('id', id);
		return this.http.get<any>(`${this.baseUrl}api/Utilisateurs/details/${id}`);
	}

	getFilteredUsers(body: IAPIUserFilters): Observable<{ utilisateurs: UserList[], totalCount: number }> {
		return this.http.post<{ utilisateurs: UserList[], totalCount: number }>(`/api/Utilisateurs/FilterUsers`, body);
	}


	editUser(user: any, userId: string) {
		return this.http.post<any>(`${this.baseUrl}api/Utilisateurs/${userId}/Edit`, user);
	}

	addUser(user: any) {
		return this.http.post<any>(`${this.baseUrl}api/Utilisateurs/Create`, user);
	}

	addSchools(userId, ecoles) {
		return this.http.post<any>(`${this.baseUrl}api/Utilisateurs/` + userId + `/addSchools`, ecoles);
	}

	getRoles() {
		return this.http.get<any>(`${this.baseUrl}api/Utilisateurs/getRoles`);
	}

	deleteUser(userId) {
		return this.http.post<any>(`${this.baseUrl}api/Utilisateurs/${userId}/delete`, {});
	}
}