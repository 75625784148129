import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { PromesseService } from 'src/app/api/services/promesse.service';
import { takeUntil } from 'rxjs/operators';
import { checkIfCheckNbr } from 'src/utils/custom-validators';

export interface DialogData {
	mode: string;
	schoolId: number;
	element: any;
}

@Component({
	selector: 'app-popin-versement',
	templateUrl: './popin-versement.component.html',
	styleUrls: ['./popin-versement.component.scss']
})
export class PopinVersementComponent implements OnInit {

	form: FormGroup;
	_unsubscriber$: Subject<void> = new Subject();

	banques = [];
	todayDate: Date = new Date();
	paymentOption: number;

	get complementInfosField() { return this.form.get('complementInfos') };
	get modePaiementField() { return this.form.get('modePaiement') };
	get banqueField() { return this.form.get('banque') };

	constructor(private formBuilder: FormBuilder, private promesseService: PromesseService) {
		this.promesseService.getBankList().subscribe(val => {
			this.banques = val;
		})
		this.form = this.formBuilder.group({
			montantVerse: this.formBuilder.control('', [Validators.required]),
			modePaiement: this.formBuilder.control('', Validators.required),
			complementInfos: this.formBuilder.control('', checkIfCheckNbr),
			banque: this.formBuilder.control(null),
			dateVersement: this.formBuilder.control('', Validators.required),
			deLaPartDe: this.formBuilder.control(''),
			pour: this.formBuilder.control('')
		});

		this.modePaiementField.valueChanges.pipe(takeUntil(this._unsubscriber$)).subscribe(val => {
			if (val === '1') {
				this.paymentOption = 1;
				this.complementInfosField.setValidators([checkIfCheckNbr, Validators.required]);
				this.banqueField.setValidators([Validators.required]);
			} else {
				this.paymentOption = 2;
				this.complementInfosField.setValidators([]);
				this.banqueField.setValidators([]);
			}
			this.complementInfosField.updateValueAndValidity();
		})
	}

	ngOnInit() {

	}

	ngOnDestroy() {
		this._unsubscriber$.next();
		this._unsubscriber$.complete();
	}

}
