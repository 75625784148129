import { Component, OnInit, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { IHabilitations } from '../../../../models/static.model';

@Component({
	selector: 'app-select-auto-complete',
	templateUrl: './select-auto-complete.component.html',
	styleUrls: ['./select-auto-complete.component.scss']
})
export class SelectAutoCompleteComponent implements OnInit {

	@Input() set control(control: FormControl) {
		if (control) {
			this._control = control;
			this.disabled = this._disabled;
			this.selectedElements = this.control.value ? this.control.value : [];
			this.control.valueChanges.subscribe(val => {
				this.selectedElements = val ? val : [];
			});
		}
	};
	get control(): FormControl {
		return this._control;
	}
	@Input() set dataList(data: { id: number; nom: string }[]) {
		this._dataList = data;
		this.filteredElements = this.control.valueChanges.pipe(
			startWith<string | { id: number; nom: string }[]>(''),
			map(value => typeof value === 'string' ? value : this.lastFilter),
			map(filter => this.filter(filter))
		);
	}
	get dataList(): { id: number; nom: string }[] {
		return this._dataList;
	}

	@Input() multi: boolean;
	//TODO à changer sur l'ensemble de l'app
	@Input() trueSoloSelect: boolean;
	@Input() set disabled(e) {
		if (this.control) {
			if (e) {
				this.control.disable();
			} else {
				this.control.enable();
			}
		} else {
			this._disabled = e;
		}
	};

	get disabled(): boolean {
		return this._disabled;
	}

	optionOpen = false;
	filteredElements: Observable<any>;
	selectedElements: any[];
	lastFilter: string = '';
	private _dataList: { id: number; nom: string }[];
	private _control: FormControl;
	private _disabled: boolean;

	constructor() { }

	ngOnInit() {
	}

	filter(filter: string): { id: number; nom: string }[] {
		this.lastFilter = filter;
		if (filter) {
			return this.dataList.filter(option => {
				return option.nom.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
			})
		} else {
			return this.dataList.slice();
		}
	}

	displayFn(value: IHabilitations[] | IHabilitations): string | undefined {
		let displayValue: string;
		if (Array.isArray(value)) {
			value.forEach((habilitations, index) => {
				if (index === 0) {
					displayValue = habilitations.nom;
				} else {
					displayValue += ', ' + habilitations.nom;
				}
			});
		} else if (value) {
			displayValue = value.nom;
		}
		return displayValue;
	}

	optionClicked(event: Event, habilitations: IHabilitations, selected: boolean) {
		event.stopPropagation();
		this.toggleSelection(habilitations, selected);
	}

	toggleSelection(habilitations: IHabilitations, selected: boolean) {
		habilitations.selected = !selected;
		if (habilitations.selected) {
			this.selectedElements.push(habilitations);
		} else {
			const i = this.selectedElements.findIndex(value => value.nom === habilitations.nom);
			this.selectedElements.splice(i, 1);
		}

		this.control.setValue(this.selectedElements);
	}

	isSelected(el) {
		return !!this.selectedElements.find(val => val.id === el.id);
	}

	selectSolo(el) {
		this.control.setValue([el]);
	}

}
