import { Component, OnInit, Input } from '@angular/core';
import { GroupApi } from '../../../app/api/groupApi';
import { Group } from '../../../models/group.model';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { StaticService } from '../../services/functional-services/static-service/static-service';
import { Subject, of, empty } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { takeUntil, switchMap, debounceTime, tap } from 'rxjs/operators';

import { MatTableDataSource } from '@angular/material/table';
import { IEcoleListDisplay } from '../../../models/ecole.model';

import { PopinAddEcolesComponent } from 'src/app/components/popins/popin-add-ecoles/popin-add-ecoles.component';

import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { POPIN_MODS } from 'src/enums/utils.enum';
import { formatDate } from '@angular/common';
import { LIST_TYPE } from 'src/enums/lists.type.enum';


@Component({
	selector: 'app-group-info',
	templateUrl: './group-info.component.html',
	styleUrls: ['./group-info.component.scss']
})

export class GroupInfoComponent implements OnInit {

	inModif = false;
	inCreateMode = false;
	disabled = true;
	group: any;
	groupId: string;
	groupForm: FormGroup;
	originGroup: any;
	orderObj: any;
	groupNb: number;

	selectOpen = false;
	firstFifteenPickableUser: { nom: string, prenom: string, userId: number }[];
	searchFormControl = new FormControl('');
	private _searchValue: string;
	isLoadingUsers: boolean;
	editedSelectedUserList: { nom: string, prenom: string, userId: number }[] = [];

	listType = LIST_TYPE.DUPLICATE_SCHOOL_LIST;
	
	isLoadingGroups = true;
	dataSource: any;

	tableProperties: string[] = ['reference', 'codeUAI', 'name', 'postalCode', 'city', 'habilitations'];

	private _unsubscriber$: Subject<void> = new Subject();

	constructor(
		private groupApi: GroupApi,
		private route: ActivatedRoute,
		private router: Router,
		private staticService: StaticService,
		private fb: FormBuilder,
		public dialog: MatDialog,
		private translate: TranslateService,
	) { }

	ngOnInit() {
		this.route.params.subscribe(data => {
			this.groupId = data.id;
		});

		this.route.queryParamMap.pipe(takeUntil(this._unsubscriber$)).subscribe(params => {
			this.orderObj = { ...params.keys, ...params };
			if (this.orderObj[0] === 'inModif') {
				this.inModif = true;
				this.disabled = false;
				this.inCreateMode = false;
			} else if (this.orderObj[0] === 'createGroup') {
				this.inModif = true;
				this.inCreateMode = true;
				this.disabled = false;
			} else {
				this.inCreateMode = false;
			}
		});

		// this.groupForm.get('dateModif').disable();

		if (this.inCreateMode === false) {
			this.groupApi.getGroupDetails(this.groupId).subscribe(data => {
				this.group = data;
				this.groupNb = data.ecoles.totalCount;
				this.firstFifteenPickableUser = data.notSelectedUsers;
				this.editedSelectedUserList = [...data.utilisateurs];

				this.staticService.habilitations$.subscribe(_ => {
					this.dataSource = new MatTableDataSource<IEcoleListDisplay>(this.schoolToSchoolVM(this.group.ecoles));
				});

				this.groupForm = new FormGroup({
					nom: this.fb.control(this.group.nom),
					utilisateurs: this.fb.control(this.group.utilisateurs)
				});

				this.originGroup = this.groupForm.getRawValue();
			});
			this.isLoadingGroups = false;
		} else {
			this.group = {
				nom: '',
				utilisateurs: [],
				dateModif: '',
			}
			this.groupApi.getFirstFifteenUsers().subscribe(users => {
				this.firstFifteenPickableUser = users;
				this.group.notSelectedUsers = users;
			})

			this.groupForm = new FormGroup({
				nom: this.fb.control(this.group.nom, [Validators.required]),
				utilisateurs: this.fb.control(this.group.utilisateurs)
			});
			this.originGroup = this.groupForm.getRawValue();

			this.isLoadingGroups = false;
		}

		this.searchFormControl.valueChanges.pipe(debounceTime(1000), switchMap(_ => {
			if (this.searchFormControl.value === "") {
				this.firstFifteenPickableUser = this.group.notSelectedUsers;
				return empty()
			} if (this.searchFormControl.value !== this._searchValue) {
				this.isLoadingUsers = true;
				this._searchValue = this.searchFormControl.value;
				return this.searchUsers();
			} else {
				return empty();
			}
		}), takeUntil(this._unsubscriber$)).subscribe();
	}

	private searchUsers() {
		return this.groupApi.searchUserForGroup(this.searchFormControl.value).pipe(tap(val => {
			this.firstFifteenPickableUser = val;
		}));
	}

	schoolToSchoolVM(ecoleList: any) {
		return ecoleList.ecoles.map(ec => { ec.habilitations = this.staticService.habilitationToString(+ec.habilitations); return ec; });
	}

	cancelForm() {
		if (this.inCreateMode) {
			this.router.navigate(['/groupes']);
		} else {
			this.group.utilisateurs = this.originGroup.utilisateurs;
			this.groupForm.setValue(this.originGroup)
			this.disabled = true;
			this.inModif = false;
		}
	}

	addSchool() {
		const dialogRef = this.dialog.open(PopinAddEcolesComponent, {
			width: '1000px',
			height: '735px',
			data: {
				mode: POPIN_MODS.EDIT,
				title: this.translate.instant('SCHOOL.GROUPES.ADD_SCHOOL'),
				bodyComponent: PopinAddEcolesComponent,
				data: this.dataSource,
				group: this.groupId,
				user: -1,
			}
		});
		dialogRef.afterClosed().pipe(takeUntil(this._unsubscriber$)).subscribe((val) => {
			if (val !== "") {
				this.dataSource.data = val.data;
			};
		});
	}

	newValues(elements) {
		this.dataSource.data = elements.data;
	}

	openSelectUsers() {
		this.selectOpen = !this.selectOpen;
	}

	openEditMod() {
		this.disabled = !this.disabled;
		this.inModif = true;
		this.groupApi.getGroupDetails(this.groupId).subscribe(data => {
			this.group = data;
			this.firstFifteenPickableUser = this.group.notSelectedUsers;
		});
	}


	toggleUser(user: { nom: string, prenom: string, userId: number }) {
		const i = this.editedSelectedUserList.map(val => val.userId).indexOf(user.userId);
		if (i > -1) {
			this.editedSelectedUserList.splice(i, 1);
		} else {
			this.editedSelectedUserList.push(user);
		}
	}

	isUserSelected(user) {
		const i = this.editedSelectedUserList.map(val => val.userId).indexOf(user.userId);
		if (i > -1) {
			return true;
		} else {
			return false;
		}
	}

	addToSelectedUser(user) {
		const i = this.group.notSelectedUsers.map(val => val.userId).indexOf(user.userId);
		this.group.notSelectedUsers.splice(i, 1);
		this.group.utilisateurs.push(user);
		this.editedSelectedUserList.push(user);
	}

	updateSortParam(event: Event) {

	}

	updatePageParam(event: Event) {

	}

	submit() {
		if (this.groupForm.valid === true) {
			if (this.inCreateMode === false) {
				this.group.utilisateurs = this.editedSelectedUserList
				// on modifie l'utilisateur selectionnée, car on est sur le mode édition
				this.groupApi.editGroupInfo({ nom: this.groupForm.get('nom').value, users: this.editedSelectedUserList }, this.group.groupId)
					.subscribe();
			} else {
				// on creer le nouvel utilisateur, car on est sur le mode creation
				this.groupApi.addGroup({ nom: this.groupForm.get('nom').value, users: this.editedSelectedUserList })
					.subscribe(() => {
						this.router.navigateByUrl('/groupes');
					});
			}
			this.disabled = !this.disabled;
			this.inModif = false;
		}
	}
}