import { PopinPromesseComponent } from './../../../components/popins/popin-promesse/popin-promesse.component';
import { LIST_TYPE } from 'src/enums/lists.type.enum';
import { TableService, ObjectToDelete } from './../../../services/functional-services/table-service/table-service';
import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SchoolFunctService } from 'src/app/services/functional-services/school-service/school-funct.service';
import { IMPORT_TYPE, POPIN_MODS } from 'src/enums/utils.enum';
import { ActivatedRoute } from '@angular/router';
import { PromesseService } from 'src/app/api/services/promesse.service';
import { IEcolePromesse, IEcoleVersement, AddPromesseModel, IAPIPromiseFilters } from '../../../../models/ecole.model';
import { EntrepriseService } from 'src/app/api/services/entreprise.service';
import { EntrepriseInfos } from '../../../../models/entreprise.model';
import { takeUntil, switchMap, debounceTime, finalize, catchError } from 'rxjs/operators';

import { Subject, empty, Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { GenericPopinComponent } from 'src/app/components/popins/generic-popin/generic-popin.component';
import { TranslateService } from '@ngx-translate/core';
import { PopinErrorComponent } from 'src/app/components/popins/popin-error/popin-error.component';
import { PopinVersementComponent } from 'src/app/components/popins/popin-versement/popin-versement.component';
import { AccountService } from 'src/app/api/services/account.service';
import { UserRights } from 'src/models/user.model';
import { USER_ROLE } from 'src/enums/users.enum';
import { FormControl } from '@angular/forms';
import { PROMISE_FILTER_TYPE } from 'src/enums/ecole.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-school-promise',
    templateUrl: './school-promise.component.html',
    styleUrls: ['./school-promise.component.scss'],
})
export class SchoolPromiseComponent implements OnInit {
    @Output() refreshValue = new EventEmitter();

    @Input() set forceUpdate(val: boolean) {
        if (val) {
            this.getPaginatedPromises();
            this.refreshValue.emit();
        }
    }

    @ViewChild('fileInput', { static: true }) fileInput: Input;
    tableProperties: string[] = [
        'etat',
        'gpCodeUnique',
        'siret',
        'nomEntreprise',
        'origine',
        'montantPromis',
        'montantVerse',
        'dateRelance',
    ];
    dataSource: MatTableDataSource<IEcolePromesse>;

    schoolId: number;
    isSearching = false;
    toggleFilters = true;
    searchValue = null;
    searchFormControl = new FormControl();
    formCtrlSub: Subscription;
    activeFiltersNb = 0;

    isLoadingPromises: boolean;

    currentUserRights: UserRights;
    currentUserRole: USER_ROLE;
    roleList = USER_ROLE;
    listType = LIST_TYPE.PROMESS_LIST;

    actualTaxYear: number;
    taxYear: number;

    number = 0;

    pageIndex = 1;
    pageSize = 50;
    sortColumn = 'siret';
    sortOrder = 'asc';
    promiseNumber: number;
    resetPaginator = true;

    currentEntrepriseInfos: EntrepriseInfos;

    private _unsubscriber$: Subject<void> = new Subject();

    @Output() nbPromise = new EventEmitter();

    constructor(
        private route: ActivatedRoute,
        private promesseService: PromesseService,
        private schoolFunctService: SchoolFunctService,
        private entrepriseService: EntrepriseService,
        private tableService: TableService,
        private translate: TranslateService,
        public dialog: MatDialog,
        private accountService: AccountService
    ) {}

    ngOnInit() {
        this.route.params.subscribe((data) => {
            this.schoolId = +data.id;
        });

        this.getPaginatedPromises();

        this.promesseService.referenceYear$.subscribe((val) => {
            this.actualTaxYear = val;
        });

        this.taxYear = this.promesseService.getTaxYear();

        this.tableService.itemToDelete$.pipe(takeUntil(this._unsubscriber$)).subscribe((data) => {
            if (data.listType === LIST_TYPE.PROMESS_LIST) {
                this.deletePromise(data);
            }
        });

        this.tableService.sendReminder$.pipe(takeUntil(this._unsubscriber$)).subscribe((data) => {
            this.sendReminder(data);
        });

        this.tableService.newTransfer$.pipe(takeUntil(this._unsubscriber$)).subscribe((data) => {
            this.addTransfer(data);
        });

        this.tableService.editPromess$.pipe(takeUntil(this._unsubscriber$)).subscribe((promess) => {
            this.editPromess(promess);
        });

        // this.tableService.openEntrepriseDetails$.pipe(takeUntil(this._unsubscriber$)).subscribe(promess => {
        // 	this.actionSidebar(promess);
        // })

        this.accountService.currentUserRights$.pipe(takeUntil(this._unsubscriber$)).subscribe((val) => {
            this.currentUserRights = val;
            this.currentUserRole = this.currentUserRights.role;
        });

        this.formCtrlSub = this.searchFormControl.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
            if (value !== this.searchValue) {
                this.isLoadingPromises = true;
                this.searchValue = value;
                this.resetPaginator = true;
                this.getProcessedPromiseList();
            }
        });

        this.schoolFunctService.totalPromiseFilters$.pipe(takeUntil(this._unsubscriber$)).subscribe((val) => {
            // On vérifie d'appeller la fonction seulement quand on delete des filtres
            if (val < this.activeFiltersNb) {
                this.activeFiltersNb = val;
                this.resetPaginator = true;
                this.getProcessedPromiseList();
            } else {
                this.activeFiltersNb = val;
                this.resetPaginator = true;
            }
        });
    }

    resetSearchCriteria() {
        this.searchValue = '';
        this.isSearching = false;
        this.getProcessedPromiseList();
    }

    getProcessedPromiseList() {
        this.isLoadingPromises = true;
        this.toggleFilters = true;
        if (this.activeFiltersNb >= 1 || this.isSearching) {
            this.getFilteredPromiseList(this.transformFiltersToBody());
        } else {
            this.getPaginatedPromises();
        }
    }

    private getFilteredPromiseList(body: IAPIPromiseFilters) {
        this.promesseService
            .getFilteredPromesses(this.schoolId, body)
            .pipe(finalize(() => (this.isLoadingPromises = false)))
            .subscribe((data) => {
                this.promiseNumber = data.totalCount;
                this.dataSource = new MatTableDataSource<IEcolePromesse>(data.promesses);
                this.nbPromise.emit(data.totalCount);
                this.number = this.dataSource.data.length;
            });
    }

    private getPaginatedPromises() {
        this.promesseService
            .getPromesses(this.schoolId, this.pageIndex, this.pageSize, this.sortColumn, this.sortOrder)
            .pipe(finalize(() => (this.isLoadingPromises = false)))
            .subscribe((data) => {
                this.promiseNumber = data.totalCount;
                this.dataSource = new MatTableDataSource<IEcolePromesse>(data.promesses);
                this.nbPromise.emit(data.totalCount);
                this.number = this.dataSource.data.length;
            });
    }

    deletePromise(item: ObjectToDelete) {
        this.promesseService
            .deletePromesse(item.itemId)
            .pipe(
                switchMap((_) => {
                    return this.promesseService.getPromesses(
                        this.schoolId,
                        this.pageIndex,
                        this.pageSize,
                        this.sortColumn,
                        this.sortOrder
                    );
                })
            )
            .subscribe(
                (data) => {
                    this.dataSource = new MatTableDataSource<IEcolePromesse>(data.promesses);
                    this.nbPromise.emit(data.totalCount);
                },
                (err) => {
                    this.dialog.open(GenericPopinComponent, {
                        width: '630px',
                        data: {
                            mode: POPIN_MODS.MESSAGE,
                            title: this.translate.instant('ATTENTION'),
                            bodyComponent: PopinErrorComponent,
                            data: `${err.message}.`,
                        },
                    });
                    return;
                }
            );
    }

    private sendReminder(promess: number) {
        this.promesseService
            .sendReminder(promess)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 404) {
                        this._openErrorDialog("L'entreprise concernée n'a aucun email enregistré");
                    } else {
                        this._openErrorDialog(this.translate.instant('SCHOOL.PROMISE.ERROR_CONTENT'));
                    }
                    return empty();
                })
            )
            .subscribe((data) => {
                if (this.activeFiltersNb >= 1 || this.isSearching) {
                    this.getFilteredPromiseList(this.transformFiltersToBody());
                } else {
                    this.getPaginatedPromises();
                }
            });
    }

    actionSidebar(promess: IEcolePromesse) {
        this.tableService.entrepriseDetails({
            ...promess,
            nextClickWillClose: true,
        });
    }

    importCSV(file: File, importType = IMPORT_TYPE.PROMESS) {
        this.isLoadingPromises = true;
        this.schoolFunctService
            .importCSVFile(file[0], this.schoolId, importType)
            .pipe(
                switchMap((_) => {
                    this.isLoadingPromises = false;
                    return this.promesseService.getPromesses(this.schoolId);
                })
            )
            .subscribe(
                (data) => {
                    this.dataSource = new MatTableDataSource<IEcolePromesse>(data.promesses);
                    this.nbPromise.emit(data.totalCount);
                },
                (err) => {
                    this.isLoadingPromises = false;

                    if (err.status === 400) {
                        this.dialog.open(GenericPopinComponent, {
                            width: '630px',
                            data: {
                                mode: POPIN_MODS.MESSAGE,
                                title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
                                bodyComponent: PopinErrorComponent,
                                data: "Une erreur est survenue lors de l'import : " + err.error.message,
                            },
                        });
                    } else {
                        this.dialog.open(GenericPopinComponent, {
                            width: '630px',
                            data: {
                                mode: POPIN_MODS.MESSAGE,
                                title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
                                bodyComponent: PopinErrorComponent,
                                data: "Une erreur est survenue lors de l'import",
                            },
                        });
                    }
                }
            );
    }

    createPromess() {
        this.openDialog(
            POPIN_MODS.CREATION,
            'SCHOOL.PROMISE.ADD',
            PopinPromesseComponent,
            {},
            this.schoolFunctService.addPromise.bind(this)
        );
    }

    private editPromess(promess: IEcolePromesse) {
        this.entrepriseService.getEntreprise(promess.entrepriseId, this.schoolId).subscribe((val) => {
            this.openDialog(
                POPIN_MODS.EDIT,
                'SCHOOL.PROMISE.EDIT',
                PopinPromesseComponent,
                {
                    ...promess,
                    ...val.contact,
                    nomContact: val.contact ? val.contact.nom : '',
                    prenomContact: val.contact ? val.contact.prenom : '',
                },
                this.schoolFunctService.editPromise.bind(this)
            );
        });
    }

    private addTransfer(promesseId: number) {
        const dialogRef = this.dialog.open(GenericPopinComponent, {
            width: '630px',
            data: {
                mode: POPIN_MODS.CREATION,
                title: this.translate.instant('SCHOOL.PROMISE.ADD_TRANSFER'),
                bodyComponent: PopinVersementComponent,
                data: {},
            },
        });
        dialogRef
            .beforeClosed()
            .pipe(
                switchMap((val) => {
                    if (val) {
                        return this.schoolFunctService.addPayement(
                            { ...val, modePaiement: +val.modePaiement },
                            promesseId
                        );
                    } else {
                        return empty();
                    }
                }),
                takeUntil(this._unsubscriber$)
            )
            .subscribe(
                (val: IEcoleVersement) => {
                    if (val) {
                        const data = this.dataSource.data;
                        const editedEntryIndex = data.map((el) => el.promesseId).indexOf(promesseId);
                        const newTransferedAmount =
                            Math.round((val.montantVerse + data[editedEntryIndex].montantVerse) * 100) / 100;
                        data[editedEntryIndex] = {
                            ...data[editedEntryIndex],
                            montantVerse: newTransferedAmount,
                            etat: data[editedEntryIndex].montantPromis > newTransferedAmount ? 2 : 3,
                        };
                        this.dataSource.data = data;
                        this.tableService.addPayment(val);
                    } else {
                        this.dialog.open(GenericPopinComponent, {
                            width: '630px',
                            data: {
                                mode: POPIN_MODS.MESSAGE,
                                title: 'Attention',
                                bodyComponent: PopinErrorComponent,
                                data:
                                    "Votre dernier versement n'est pas pris en compte car le montant promis à été depassé.",
                            },
                        });
                    }
                },
                (err) => {
                    this.dialog.open(GenericPopinComponent, {
                        width: '630px',
                        data: {
                            mode: POPIN_MODS.MESSAGE,
                            title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
                            bodyComponent: PopinErrorComponent,
                            data:
                                'Une erreur est survenue. Veuillez réessayer. Si le problème persiste veuillez contacter un administrateur',
                        },
                    });
                }
            );
    }

    ngOnDestroy() {
        this._unsubscriber$.next();
        this._unsubscriber$.complete();
    }

    openDialog(
        dialogMod: POPIN_MODS,
        translateKey: string,
        component: any,
        data: any,
        responseHandler: (val: any, id: number) => Observable<any>
    ) {
        const dialogRef = this.dialog.open(GenericPopinComponent, {
            width: '630px',
            data: {
                mode: dialogMod,
                title: this.translate.instant(translateKey),
                bodyComponent: component,
                data,
            },
        });
        dialogRef
            .beforeClosed()
            .pipe(
                switchMap((val) => {
                    if (val) {
                        if (dialogMod === POPIN_MODS.CREATION) {
                            this.isLoadingPromises = true;
                        }
                        return responseHandler({ ...data, ...val }, this.schoolId);
                    } else {
                        return empty();
                    }
                }),
                takeUntil(this._unsubscriber$)
            )
            .subscribe(
                (val: AddPromesseModel) => {
                    if (dialogMod === POPIN_MODS.CREATION) {
                        this.addDataToDataSource(val);
                        this.isLoadingPromises = false;
                    } else if (dialogMod === POPIN_MODS.EDIT) {
                        this.editDataFromDataSource(val);
                    }
                },
                (err) => this._openErrorDialog()
            );
    }

    downloadTemplate() {
        this.promesseService.getTemplate().subscribe((data) => {
            const fileExtension = '.xlsx';
            const blob = new Blob([data]);
            const downloadLink = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = downloadLink;
            a.download = `template${fileExtension}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    }

    private _openErrorDialog(err?: string) {
        this.isLoadingPromises = false;
        this.dialog.open(GenericPopinComponent, {
            width: '630px',
            data: {
                mode: POPIN_MODS.MESSAGE,
                title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
                bodyComponent: PopinErrorComponent,
                data: err
                    ? err
                    : 'Une erreur est survenue. Veuillez réessayer. Si le problème persiste veuillez contacter un administrateur',
            },
        });
    }

    private editDataFromDataSource(editedEntry) {
        const data = this.dataSource.data;
        const editedEntryIndex = data.map((el) => el.promesseId).indexOf(editedEntry.promesseId);
        data[editedEntryIndex] = editedEntry;
        this.dataSource.data = data;
    }

    private addDataToDataSource(newEntry) {
        const data = this.dataSource.data;
        data.push({ ...newEntry, montantVerse: 0 });
        this.dataSource.data = data;
        this.nbPromise.emit(this.promiseNumber + 1);
    }

    updateSortParam(event) {
        this.sortColumn = event.column;
        this.sortOrder = event.sortDirection;
        this.getProcessedPromiseList();
    }

    updatePageParam(event) {
        this.resetPaginator = false;
        this.pageIndex = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.getProcessedPromiseList();
    }

    exportPromesses() {
        this.isLoadingPromises = true;
        this.promesseService.exportPromessList(this.schoolId).subscribe(
            (data) => {
                this.isLoadingPromises = false;
                const fileExtension = '.xlsx';
                const blob = new Blob([data]);
                const downloadLink = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = downloadLink;
                a.download = `export-promesses-${new Date().toLocaleDateString().replace(/\//g, '-')}${fileExtension}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            },
            (_) =>
                this.dialog.open(GenericPopinComponent, {
                    width: '630px',
                    data: {
                        mode: POPIN_MODS.MESSAGE,
                        title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
                        bodyComponent: PopinErrorComponent,
                        data: "Une erreur s'est produite lors de l'export des données",
                    },
                })
        );
    }

    onClickedOutside(e) {
        if (e.target.title !== 'openPromesseFilterDiv') {
            if (e.target.offsetParent !== null) {
                if (e.target.offsetParent.title !== 'openPromesseFilterDiv') {
                    if (!this.toggleFilters) {
                        this.toggleFilters = true;
                    }
                }
            } else {
                if (e.target.title !== 'deleteFilter' && e.target.title !== 'selectOrder') {
                    if (!this.toggleFilters) {
                        this.toggleFilters = true;
                    }
                }
            }
        }
    }

    private transformFiltersToBody(): IAPIPromiseFilters {
        const body: IAPIPromiseFilters = {
            searchValue: this.searchValue,
            etat: [],
            origin: [],
            montantPromis: null,
            order: '=',
            PageNumber: this.pageIndex,
            PageSize: this.pageSize,
            sortColumn: this.sortColumn,
            sortOrder: this.sortOrder,
            year: parseInt(this.taxYear.toString(), 10),
        };
        if (this.activeFiltersNb >= 1) {
            body.montantPromis = parseFloat(
                this.schoolFunctService.promiseCurrentFilters[PROMISE_FILTER_TYPE.MONTANT_PROMIS][0]
            );
            if (this.schoolFunctService.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORDER][0]) {
                body.order = this.schoolFunctService.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORDER][0].nom;
            }
            body.etat = this.schoolFunctService.promiseCurrentFilters[PROMISE_FILTER_TYPE.ETAT].map((el) => el.id);
            body.origin = this.schoolFunctService.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORIGINE].map(
                (el) => el.nom
            );
        }

        return body;
    }
}
