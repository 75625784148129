import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { UserApi } from '../../api/userApi'
import { MatTableDataSource } from '@angular/material/table';
import { UserListDisplay, UserList } from '../../../models/user.model';
import { StaticService } from '../../services/functional-services/static-service/static-service';
import { Router } from '@angular/router';
import { LIST_TYPE } from 'src/enums/lists.type.enum';
import { SchoolFunctService } from 'src/app/services/functional-services/school-service/school-funct.service';
import { takeUntil, switchMap, debounceTime } from 'rxjs/operators';
import { USER_FILTER_TYPE } from 'src/enums/ecole.enum';
import { IAPIUserFilters } from 'src/models/ecole.model';

@Component({
	selector: 'app-users-list',
	templateUrl: './users-list.component.html',
	styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

	tableProperties: string[] = ['nom', 'equipe', 'ecoles', 'profil'];
	dataSource: any;

	toggleFilters = true;
	activeFiltersNb: number = 0;

	pageIndex: number = 1;
	pageSize: number = 50;
	sortColumn = 'name';
	sortOrder = 'asc';
	userNb: number;
	resetPaginator = true;

	isLoadingUsers = true;
	isSearching = false;
	searchValue = null;
	searchFormControl = new FormControl();
	formCtrlSub: Subscription;
	listType = LIST_TYPE.USER_LIST;

	private _unsubscriber$: Subject<void> = new Subject();

	constructor(
		private userApi: UserApi,
		private router: Router,
		private schoolFunctService: SchoolFunctService,
	) { }

	ngOnInit() {
		this.getUserList();

		this.formCtrlSub = this.searchFormControl.valueChanges.pipe(debounceTime(1000))
			.subscribe(value => {
				if (value !== this.searchValue) {
					this.isLoadingUsers = true;
					this.searchValue = value;
					this.resetPaginator = true;
					this.getProcessedUsersList();
				}
			});

		this.schoolFunctService.totalUserFilters$.pipe(takeUntil(this._unsubscriber$)).subscribe(val => {
			// On vérifie d'appeller la fonction seulement quand on delete des filtres
			if (val < this.activeFiltersNb) {
				this.activeFiltersNb = val;
				this.resetPaginator = true;
				this.getProcessedUsersList();
			} else {
				this.activeFiltersNb = val;
				this.resetPaginator = true;
			}
		});
	}

	resetSearchCriteria() {
		this.searchValue = '';
		this.isSearching = !this.isSearching;
		this.getProcessedUsersList();
	}

	getProcessedUsersList() {
		this.toggleFilters = true;
		this.isLoadingUsers = true;
		if (this.activeFiltersNb >= 1 || this.isSearching) {
			this.getFilteredUserList(this.transformFiltersToBody());
		} else {
			this.getUserList();
		}
	}

	getFilteredUserList(body: IAPIUserFilters) {
		this.userApi.getFilteredUsers(body).subscribe(data => {
			this.dataSource = new MatTableDataSource<UserList>(data.utilisateurs)
			this.isLoadingUsers = false;
			this.userNb = data.totalCount;
			this.dataSource = data.utilisateurs;
		})

	}

	getUserList() {
		this.userApi.getUserList(this.pageIndex, this.pageSize, this.sortColumn, this.sortOrder)
			.subscribe(data => {
				this.isLoadingUsers = false;
				this.userNb = data.totalCount;
				this.dataSource = data.utilisateurs;
			});
	}

	goCreateUserForm() {
		this.router.navigate(['/creation-user'], { queryParams: { createUser: true } });
	}


	updateSortParam(event) {
		this.toggleFilters = !this.toggleFilters;
		this.sortColumn = event.column;
		this.sortOrder = event.sortDirection;
		this.getProcessedUsersList();
	}

	updatePageParam(event) {
		this.resetPaginator = false;
		this.pageIndex = event.pageIndex + 1;
		this.pageSize = event.pageSize;
		this.getProcessedUsersList();
	}

	onClickedOutside(e) {
		if (e.target.title !== 'openUserFilterDiv') {
			if (e.target.offsetParent !== null) {
				if (e.target.offsetParent.title !== 'openUserFilterDiv') {
					if (!this.toggleFilters) {
						this.toggleFilters = true;
					}
				}
			} else {
				if (!this.toggleFilters) {
					this.toggleFilters = true;
				}
			}
		}
	}

	private transformFiltersToBody(): IAPIUserFilters {
		const body: IAPIUserFilters = {
			searchValue: this.searchValue,
			codeUAI: [],
			reference: [],
			profil: [],
			PageNumber: this.pageIndex,
			PageSize: this.pageSize,
			sortColumn: this.sortColumn,
			sortOrder: this.sortOrder
		}
		if (this.activeFiltersNb >= 1) {
			body.codeUAI = this.schoolFunctService.userCurrentFilters[USER_FILTER_TYPE.CODE_UAI];
			body.reference = this.schoolFunctService.userCurrentFilters[USER_FILTER_TYPE.REFERENCE];
			if (this.schoolFunctService.userCurrentFilters[USER_FILTER_TYPE.PROFIL]) {
				body.profil = this.schoolFunctService.userCurrentFilters[USER_FILTER_TYPE.PROFIL].map(el => el.id);
			}

		}
		return body;
	}
}
