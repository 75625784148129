import { Injectable, Injector } from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {

	constructor(private router: Router) { }

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		//send the newly created request
		return next.handle(req).pipe(catchError((err) => this.handleError(err)));
	}


	private handleError(err: HttpErrorResponse): Observable<any> {
		if (err.status === 401) {
			location.href = '/identity/account/login'
		}
		return throwError(err);
	}
}