import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { IVMSchoolFilters } from '../../../../models/ecole.model';
import { SchoolFunctService } from '../../../services/functional-services/school-service/school-funct.service';
import { PAYMENT_FILTER_TYPE } from '../../../../enums/ecole.enum';
import { StaticService } from '../../../services/functional-services/static-service/static-service';

@Component({
	selector: 'app-school-filter-payment',
	templateUrl: './school-filter-payment.component.html',
	styleUrls: ['./school-filter-payment.component.scss']
})
export class SchoolFilterPaymentComponent implements OnInit, OnDestroy {

	@Output() getFilteredResult: EventEmitter<void> = new EventEmitter();

	paymentFilterForm: FormGroup;
	currentFilters: IVMSchoolFilters;

	order = [
		{ id: 1, nom: "<" },
		{ id: 2, nom: ">" },
		{ id: 3, nom: "=" }
	]

	orderControl = new FormControl();
	montantverseControl = new FormControl('');

	private _unsubscriber$ = new Subject();

	constructor(private fb: FormBuilder, private schoolFunctService: SchoolFunctService, private staticService: StaticService) {
		this.paymentFilterForm = fb.group({
			order: this.orderControl,
			montant_verse: this.montantverseControl,
		})

	}

	ngOnInit() {
		this.updateFilterList();

		this.schoolFunctService.deleteEvent$.subscribe(_ => {
			this.currentFilters = this.schoolFunctService.getPaymentFiltersAsArray();
		})
	}

	submit(event) {
		event.preventDefault();
		this.schoolFunctService.addPaymentFilters(this.paymentFilterForm.value);
		this.updateFilterList();
		this.getFilteredResult.emit()
		this.paymentFilterForm.get('montant_verse').reset('');
	}

	reset(event) {
		event.preventDefault();
		this.schoolFunctService.deleteAllPaymentFilter()
		this.paymentFilterForm.get('montant_verse').reset('');
		this.paymentFilterForm.get('order').reset();
	}

	deleteFilter(filterTodelete: { id: number, name: string, type: PAYMENT_FILTER_TYPE }) {
		this.schoolFunctService.deletePaymentFilter(filterTodelete);
		this.updateFilterList();
	}

	updateFilterList() {
		//update Tag list si on a deja des filtres selectionnés
		this.currentFilters = this.schoolFunctService.getPaymentFiltersAsArray();


		//update 
		this.orderControl.setValue(this.schoolFunctService.paymentCurrentFilters[PAYMENT_FILTER_TYPE.ORDER]);
		this.montantverseControl.setValue(this.schoolFunctService.paymentCurrentFilters[PAYMENT_FILTER_TYPE.MONTANT_VERSE]);
	}

	ngOnDestroy() {
		this._unsubscriber$.next();
		this._unsubscriber$.complete();
	}
}