import { switchMap, takeUntil } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IbanService } from './../../../api/services/iban.service';
import { of } from 'rxjs/internal/observable/of';
import { checkIban, checkBic } from 'src/utils/custom-validators';
import { TaskboardService } from 'src/app/api/services/taskboard.service';
import { TYPE_TACHE, TASK_STATUS_ID } from 'src/enums/task.enum';
import { POPIN_MODS } from 'src/enums/utils.enum';
import { IEcoleIban } from 'src/models/ecole.model';
import { AccountService } from 'src/app/api/services/account.service';
import { UserRights } from 'src/models/user.model';
import { Subject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SchoolApi } from 'src/app/api/schoolApi';
import { IsUploadableFile } from 'src/utils/custom-functions';

export interface DialogData {
	mode: number;
	schoolId: number;
	iban: IEcoleIban;
}

@Component({
	selector: 'app-popin-iban',
	templateUrl: './popin-iban.component.html',
	styleUrls: ['./popin-iban.component.scss']
})
export class PopinIbanComponent implements OnInit {

	createIban: FormGroup;
	uploadedFile: File;
	error: string = undefined;
	currentUserRights: UserRights;
	private _unsubscriber$: Subject<void> = new Subject();

	constructor(
		public dialogRef: MatDialogRef<PopinIbanComponent>,
		private route: ActivatedRoute,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private formBuilder: FormBuilder,
		private ibanService: IbanService,
		private taskboardService: TaskboardService,
		private accountService: AccountService,
		private translateService: TranslateService,
		private schoolService: SchoolApi) {

		if (this.data.mode === POPIN_MODS.CREATION) {
			this.createIban = this.formBuilder.group({
				ecoleId: this.data.schoolId,
				titulaire: '',
				iban: this.formBuilder.control("", [Validators.required, checkIban('')]),
				bic: this.formBuilder.control("", [Validators.required, checkBic('')]),
				file: this.formBuilder.control("", [Validators.required])
			});
		} else if (this.data.mode === POPIN_MODS.EDIT) {
			this.createIban = this.formBuilder.group({
				ecoleId: this.data.schoolId,
				titulaire: this.data.iban.titulaire,
				iban: this.formBuilder.control(this.data.iban.iban, [Validators.required, checkIban('')]),
				bic: this.formBuilder.control(this.data.iban.bic, [Validators.required, checkBic('')]),
				file: this.formBuilder.control("", [Validators.required])
			});
		}
	}

	ngOnInit() {
		let dropFileEl = document.getElementById("file-box");
		dropFileEl.addEventListener("dragenter", this.dragenter, false);
		dropFileEl.addEventListener("dragover", this.dragover, false);
		dropFileEl.addEventListener("drop", this.dropFile.bind(this), false);
		this.accountService.currentUserRights$.pipe(takeUntil(this._unsubscriber$)).subscribe(va => {
			this.currentUserRights = va;
			if (this.currentUserRights.canCreateUpdateDelete) {
				this.createIban.get('file').setValidators([]);
			} else {
				this.createIban.get('file').setValidators([Validators.required]);
			}
		})
		this.createIban.get('iban').valueChanges.pipe(takeUntil(this._unsubscriber$)).subscribe(val => {
			this.createIban.get('bic').setValidators([Validators.required, checkBic(val)]);
		})
		this.createIban.get('bic').valueChanges.pipe(takeUntil(this._unsubscriber$)).subscribe(val => {
			this.createIban.get('iban').setValidators([Validators.required, checkIban(val)]);
		})
	}

	dragenter(e) {
		e.stopPropagation();
		e.preventDefault();
	}

	dragover(e) {
		e.stopPropagation();
		e.preventDefault();
	}

	dropFile(e) {
		e.stopPropagation();
		e.preventDefault();
		const dt = e.dataTransfer;
		const files = dt.files;
		this.uploadedFile = files[0];

	}
	onNoClick(): void {
		this.dialogRef.close();
	}

	onSubmit(ibanData) {
		this.error = undefined;
		if (this.currentUserRights.canCreateUpdateDelete) {
			if (this.data.mode === POPIN_MODS.CREATION) {
				this.ibanService.addIban({ ...ibanData, iban: ibanData.iban.replace(/\s/g, '') }).pipe(switchMap(_ => {
					if (this.uploadedFile) {
						return this.ibanService.uploadIban(this.uploadedFile, this.data.schoolId.toString());
					} else {
						return of(null);
					}
				}), switchMap(_ => {
					return this.ibanService.getIbans(this.data.schoolId);
				})).subscribe(val => {
					this.dialogRef.close(val);
				}, err => {
					if (err.status === 400) {
						this.error = this.translateService.instant('SCHOOL.SECTION.ERRORIBAN');
					}
				});
			} else if (this.data.mode === POPIN_MODS.EDIT) {
				ibanData.ibanId = this.data.iban.ibanId;
				this.ibanService.editIban(this.data.iban.ibanId, { ...ibanData, iban: ibanData.iban.replace(/\s/g, '') }).pipe(switchMap(_ => {
					if (this.uploadedFile) {
						return this.ibanService.uploadIban(this.uploadedFile, this.data.schoolId.toString(), this.data.iban.ibanId);
					} else {
						return of(null);
					}
				}), switchMap(_ => {
					return this.ibanService.getIbans(this.data.schoolId);
				})).subscribe(val => {
					this.dialogRef.close(val);
				});
			}
		} else {
			this.createTask();
		}
	}


	deleteFile() {
		this.uploadedFile = undefined;
	}

	newFileInput(files: File[]) {
		if (IsUploadableFile(files[0])) {
			this.uploadedFile = files[0];
		}
	}


	private createTask() {
		const taskEcoleData = { after: {} };
		Object.keys(this.createIban.controls).forEach(key => {
			let currentControl = this.createIban.controls[key];
			if (currentControl.dirty) {
				taskEcoleData['after'][key] = currentControl.value;
			}
		});

		if (this.uploadedFile) {
			this.schoolService.uploadTemporaryFile(this.uploadedFile, this.data.schoolId).pipe(switchMap(data => {
				taskEcoleData['after']['fichierIban'] = data;
				return this.handleCreateTask(taskEcoleData);
			})).subscribe(_ => {
				this.dialogRef.close();
			});
		} else {
			this.handleCreateTask(taskEcoleData).subscribe(_ => {
				this.dialogRef.close();
			});
		}
	}

	handleCreateTask(taskEcoleData): Observable<boolean> {
		return this.taskboardService.createTask({
			taskType: TYPE_TACHE.IBAN,
			statut: TASK_STATUS_ID.WAITING,
			targetId: this.data.mode !== POPIN_MODS.CREATION ? this.data.iban.ibanId : null,
			data: JSON.stringify(taskEcoleData),
			ecoleId: this.data.schoolId
		});
	}

	ngOnDestroy() {
		this._unsubscriber$.next();
		this._unsubscriber$.complete();
	}
}
