import { Component, OnInit } from "@angular/core";
import { UserApi } from "../../../app/api/userApi";
import { User } from "../../../models/user.model";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { StaticService } from "../../services/functional-services/static-service/static-service";
import { Subject } from "rxjs";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";

import { MatTableDataSource } from "@angular/material/table";
import { IEcoleListDisplay, IEcoleList } from "../../../models/ecole.model";
import { USER_PROFILE } from "src/enums/users.enum";
import { PopinAddEcolesComponent } from "src/app/components/popins/popin-add-ecoles/popin-add-ecoles.component";
import { POPIN_MODS } from "src/enums/utils.enum";

import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material";
import { checkIfPhone, checkIfEmail } from "src/utils/custom-validators";
import { GenericPopinComponent } from "src/app/components/popins/generic-popin/generic-popin.component";
import { PopinErrorComponent } from "src/app/components/popins/popin-error/popin-error.component";
import { LIST_TYPE } from "src/enums/lists.type.enum";

@Component({
  selector: "app-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"],
})
export class UserInfoComponent implements OnInit {
  get mail() {
    return this.userForm.get("email");
  }
  get telephone() {
    return this.userForm.get("telephone");
  }

  ENUM_PROFILE = USER_PROFILE;
  roles: any;
  selectOpen = false;
  inModif = false;
  createUser = false;
  inCreateMode: boolean;
  disabled = true;
  user: any;
  userId: string;
  userForm: FormGroup;
  originUser: any;
  orderObj: any;
  userNb: number;
  isLoadingUsers = true;
  dataSource: MatTableDataSource<IEcoleListDisplay>;
  tableProperties: string[] = [
    "reference",
    "codeUAI",
    "name",
    "postalCode",
    "city",
    "habilitations",
  ];
  listType = LIST_TYPE.DUPLICATE_SCHOOL_LIST;

  private _unsubscriber$: Subject<void> = new Subject();

  constructor(
    private userApi: UserApi,
    private route: ActivatedRoute,
    private router: Router,
    private staticService: StaticService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.userId = data.id;
    });

    this.route.queryParamMap
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((params) => {
        this.orderObj = { ...params.keys, ...params };
        if (this.orderObj[0] === "inModif") {
          this.inModif = true;
          this.disabled = false;
          this.inCreateMode = false;
        } else if (this.orderObj[0] === "createUser") {
          this.inModif = true;
          this.inCreateMode = true;
          this.disabled = false;
        } else {
          this.inCreateMode = false;
        }
      });
    if (this.inCreateMode === false) {
      this.userApi.getUserDetails(this.userId).subscribe((data) => {
        let tableInfos: IEcoleList = {
          ecoles: data.ecoles,
          totalCount: data.totalCount,
        };

        this.user = {
          ecoles: data.ecoles,
          email: data.email,
          nom: data.nom,
          prenom: data.prenom,
          profile: data.profile ? data.profile.roleId : 0,
          telephone: data.telephone ? data.telephone : "",
        };

        this.userNb = data.totalCount;
        this.staticService.habilitations$.subscribe((_) => {
          this.dataSource = new MatTableDataSource<IEcoleListDisplay>(
            this.schoolToSchoolVM(tableInfos)
          );
        });

        this.userForm = new FormGroup({
          nom: this.fb.control(this.user.nom),
          prenom: this.fb.control(this.user.prenom),
          profile: this.fb.control(data.profile ? data.profile.name : "", [
            Validators.required,
          ]),
          email: this.fb.control(this.user.email, checkIfEmail),
          telephone: this.fb.control(this.user.telephone, checkIfPhone),
        });
        this.originUser = this.userForm.getRawValue();

        this.isLoadingUsers = false;
      });
    } else {
      this.user = {
        ecoles: [],
        email: "",
        nom: "",
        prenom: "",
        profile: "",
        telephone: "",
      };
      this.userForm = new FormGroup({
        nom: this.fb.control(this.user.nom),
        prenom: this.fb.control(this.user.prenom),
        profile: this.fb.control(this.user.profile, Validators.required),
        email: this.fb.control(this.user.email, checkIfEmail),
        telephone: this.fb.control(this.user.telephone, checkIfPhone),
      });
      this.originUser = this.userForm.getRawValue();

      this.isLoadingUsers = false;
    }
  }

  schoolToSchoolVM(ecoleList: IEcoleList) {
    return ecoleList.ecoles.map((ec) => {
      ec.habilitations = this.staticService.habilitationToString(
        +ec.habilitations
      );
      return ec;
    });
  }

  cancelForm() {
    if (this.inCreateMode === true) {
      this.router.navigate(["/utilisateurs"]);
    } else {
      this.selectOpen = false;
      this.userForm.setValue(this.originUser);
      this.disabled = true;
      this.inModif = false;
    }
  }

  modifProfilValue(value, stringValue) {
    this.selectOpen = !this.selectOpen;
    this.userApi
      .getRoles()
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((items) => {
        this.roles = items;
        this.user.profile = value;
      });
    this.userForm = new FormGroup({
      nom: this.fb.control(this.userForm.value.nom),
      prenom: this.fb.control(this.userForm.value.prenom),
      profile: this.fb.control(stringValue, Validators.required),
      email: this.fb.control(this.userForm.value.email),
      telephone: this.fb.control(this.userForm.value.telephone),
    });
  }

  submit() {
    this.selectOpen = false;
    if (this.userForm.valid === true) {
      if (this.inCreateMode === false) {
        // on modifie l'utilisateur selectionnée, car on est sur le mode édition
        this.userApi
          .editUser(
            { ...this.userForm.value, profile: this.user.profile },
            this.userId
          )
          .subscribe(
            (_) => {
              this.disabled = !this.disabled;
              this.inModif = false;
            },
            (err) => {
              this.dialog.open(GenericPopinComponent, {
                width: "630px",
                data: {
                  mode: POPIN_MODS.MESSAGE,
                  title: this.translate.instant("COMMON.POPINS.ERROR_TITLE"),
                  bodyComponent: PopinErrorComponent,
                  data:
                    "Une erreur est survenue à l'édition du nouvel utilisateur",
                },
              });
            }
          );
      } else {
        // on creer le nouvel utilisateur, car on est sur le mode creation
        this.userApi
          .addUser({ ...this.userForm.value, profile: this.user.profile })
          .subscribe(
            (r) => {
              if (r) {
                this.disabled = !this.disabled;
                this.inModif = false;
                this.router.navigateByUrl("/utilisateurs");
              } else {
                this.displayErrorMsg();
              }
            },
            (err) => {
              this.displayErrorMsg();
            }
          );
      }
    }
  }

  addSchool() {
    const dialogRef = this.dialog.open(PopinAddEcolesComponent, {
      width: "1000px",
      height: "735px",
      data: {
        mode: POPIN_MODS.EDIT,
        title: this.translate.instant("SCHOOL.GROUPES.ADD_SCHOOL"),
        bodyComponent: PopinAddEcolesComponent,
        data: this.dataSource,
        group: -1,
        user: this.userId,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((val) => {
        if (val !== "") {
          this.dataSource.data = val.data;
        }
      });
  }

  newValues(elements) {
    this.dataSource.data = elements.data;
  }
  updateSortParam(event: Event) {}

  updatePageParam(event: Event) {}

  private displayErrorMsg() {
    this.dialog.open(GenericPopinComponent, {
      width: "630px",
      data: {
        mode: POPIN_MODS.MESSAGE,
        title: this.translate.instant("COMMON.POPINS.ERROR_TITLE"),
        bodyComponent: PopinErrorComponent,
        data: "Une erreur est survenue à l'enregitrement du nouvel utilisateur",
      },
    });
  }

  onClickedOutside(e) {
    if (e.target.title !== "openSelectDiv") {
      if (e.target.offsetParent !== null) {
        if (e.target.offsetParent.title !== "openSelectDiv") {
          if (this.selectOpen) {
            this.selectOpen = false;
          }
        }
      } else {
        if (e.target.title !== "openSelectDiv") {
          if (this.selectOpen) {
            this.selectOpen = false;
          }
        }
      }
    }
  }
  s;

  ngOnDestroy() {
    this._unsubscriber$.next();
    this._unsubscriber$.complete();
  }
}
