export enum USER_PROFILE {
	ADMIN = 2,
	N1,
	N2,
	N3,
	N4,
	SUPERVISEUR,
	SUPERVISEUR_PLUS,
	HOTLINER,
	OPERATOR
}

export enum USER_ROLE {
	ADMIN = "Admin",
	N1 = "N1",
	N2 = "N2",
	N3 = "N3",
	N4 = "N4",
	SUPERVISEUR = "Superviseur",
	SUPERVISEUR_PLUS = "SuperviseurPlus",
	HOTLINER = "Hotliner",
	OPERATOR = "Operateur",
}

export enum USER_FORMULE {
	NONE = 0,
	ABONNE,
	MEMBRE_EXTERIEUR,
}
