import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from 'src/app/api/services/account.service';
import { checkPasswordConsistency } from 'src/utils/custom-validators';

@Component({
	selector: 'app-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

	private _currentUser: User;
	passwordForm: FormGroup;
	@Input() set currentUser(user: User) {
		if (user) {
			this._currentUser = user;
			this.setAccountForm();
		}
	};

	passwordUpdateError = false;
	passwordSent = false;

	get currentUser(): User {
		return this._currentUser;
	}

	get oldPasswordControl() { return this.passwordForm.get('oldPassword') };
	get newPasswordControl() { return this.passwordForm.get('newPassword') };
	get newPasswordCheckControl() { return this.passwordForm.get('newPasswordCheck') };

	constructor(private fb: FormBuilder, private accountService: AccountService) { }

	ngOnInit() {
	}

	private setAccountForm() {
		this.passwordForm = this.fb.group({
			oldPassword: this.fb.control('', Validators.required),
			newPassword: this.fb.control('', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$§+\]¤%µ£\\[z°~^¨""`&*(),.?\:{}|<>_\/=-]).{8,}$/)]),
			newPasswordCheck: this.fb.control('')
		});

		this.newPasswordControl.valueChanges.subscribe(val => {
			this.newPasswordCheckControl.setValidators([Validators.required, checkPasswordConsistency(val)]);
		})

		this.passwordForm.errors
	}


	updatePassword() {

		this.accountService.updatePassword(
			{ oldPassword: this.oldPasswordControl.value, newPassword: this.newPasswordCheckControl.value }
		).subscribe(val => {
			this.passwordSent = true
			this.passwordForm.reset();
			this.passwordUpdateError = !val;
		}, err => {
			this.passwordSent = true
			this.passwordForm.reset();
			this.passwordUpdateError = true;
		});
	}

}
