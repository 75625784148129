import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/models/user.model';
import { USER_FORMULE } from 'src/enums/users.enum';
import { TranslateService } from '@ngx-translate/core';
import { MAT_HAMMER_OPTIONS } from '@angular/material';
import { offres } from 'src/assets/offres/offres';
import { Router } from '@angular/router';

@Component({
  selector: 'app-formules',
  templateUrl: './formules.component.html',
  styleUrls: ['./formules.component.scss']
})
export class FormulesComponent implements OnInit {

  @Input() set infoUser(user: User) {
    if (user) {
      this.idOffre = user.formule;
      this.typeOffre = user.formule === USER_FORMULE.ABONNE ?
      this.translate.instant('OFFRES.SUBCRIBERACCES') : this.translate.instant('OFFRES.EXTACCES');
    }
  }

  get infoUser() {
    return this._infoUser;
  }

  private _infoUser: User;

  idOffre: number;
  typeOffre: string;
  myOffre: any;

  constructor(private translate: TranslateService, private router: Router) { }

  ngOnInit() {
    this.myOffre = offres;
  }

  redirectOffres() {
    this.router.navigateByUrl('/offres');
  }
}
