import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { LIST_TYPE } from "../../../../enums/lists.type.enum";
import {
  IEcole,
  IEcoleDiplome,
  IEcolePromesse,
  IEcoleVersement,
} from "src/models/ecole.model";
import { runInThisContext } from "vm";

export interface ObjectToDelete {
  listType: LIST_TYPE;
  itemId: number;
}

export class RecuToSend {
  constructor(versementId: number, isFromUnitarySelection: boolean) {
    this.versementId = versementId;
    this.isFromUnitarySelection = isFromUnitarySelection;
  }

  versementId: number;
  isFromUnitarySelection: boolean;
}

@Injectable({
  providedIn: "root",
})
export class TableService {
  private _itemToDelete$: Subject<ObjectToDelete> = new Subject();
  itemToDelete$: Observable<ObjectToDelete> = this._itemToDelete$.asObservable();

  private _editDiploma$: Subject<IEcoleDiplome> = new Subject();
  editDiploma$: Observable<IEcoleDiplome> = this._editDiploma$.asObservable();

  private _editPromess$: Subject<IEcolePromesse> = new Subject();
  editPromess$: Observable<IEcolePromesse> = this._editPromess$.asObservable();

  private _sendReminder$: Subject<number> = new Subject();
  sendReminder$: Observable<number> = this._sendReminder$.asObservable();

  private _sendReceiptMail$: Subject<RecuToSend> = new Subject();
  sendReceiptMail$: Observable<RecuToSend> = this._sendReceiptMail$.asObservable();

  private _newTransfer$: Subject<any> = new Subject();
  newTransfer$: Observable<any> = this._newTransfer$.asObservable();

  private _openEntrepriseDetails$: Subject<IEcolePromesse> = new Subject();
  openEntrepriseDetails$: Observable<IEcolePromesse> = this._openEntrepriseDetails$.asObservable();

  private _openEntrepriseVersementDetails$: Subject<IEcoleVersement> = new Subject();
  openEntrepriseVersementDetails$: Observable<IEcoleVersement> = this._openEntrepriseVersementDetails$.asObservable();

  private _addPayment$: Subject<IEcoleVersement> = new Subject();
  addPayment$: Observable<IEcoleVersement> = this._addPayment$.asObservable();

  private _startGeneratingReceipt$: Subject<any> = new Subject();
  startGeneratingReceipt$: Observable<any> = this._startGeneratingReceipt$.asObservable();

  private _downloadRecu$: Subject<any> = new Subject();
  downloadRecu$: Observable<any> = this._downloadRecu$.asObservable();

  onDelete(type: LIST_TYPE, id: number) {
    var obj: ObjectToDelete = {
      listType: type,
      itemId: id,
    };
    this._itemToDelete$.next(obj);
  }

  editDiploma(diploma: IEcoleDiplome) {
    this._editDiploma$.next(diploma);
  }

  editPromess(promess: IEcolePromesse) {
    this._editPromess$.next(promess);
  }

  sendReminder(promessId: any) {
    this._sendReminder$.next(promessId);
  }

  newTransfer(promessId: any) {
    this._newTransfer$.next(promessId);
  }

  entrepriseDetails(promesse: IEcolePromesse) {
    this._openEntrepriseDetails$.next(promesse);
  }

  entrepriseVersementDetails(versement: IEcoleVersement) {
    this._openEntrepriseVersementDetails$.next(versement);
  }

  addPayment(promesse: IEcoleVersement) {
    this._addPayment$.next(promesse);
  }

  startGeneratingReceipt(versement: IEcoleVersement) {
    this._startGeneratingReceipt$.next(versement);
  }

  downloadRecu(versement: IEcoleVersement) {
    this._downloadRecu$.next(versement);
  }

  sendReceiptMail(recuToSend: RecuToSend) {
    this._sendReceiptMail$.next(recuToSend);
  }
}
