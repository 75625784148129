import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { IEcoleList, IEcoleListDisplay, IAPIFilters } from 'src/models/ecole.model';
import { SchoolApi } from 'src/app/api/schoolApi';
import { GroupApi } from 'src/app/api/groupApi';
import { StaticService } from 'src/app/services/functional-services/static-service/static-service';
import { SchoolFunctService } from 'src/app/services/functional-services/school-service/school-funct.service';
import { SCHOOL_FILTER_TYPE } from 'src/enums/ecole.enum';
import { FormControl } from '@angular/forms';
import { takeUntil, debounceTime, switchMap } from 'rxjs/operators';
import { UserApi } from 'src/app/api/userApi';
import { LIST_TYPE } from 'src/enums/lists.type.enum';


export interface DialogData {
	user: any;
	group: any;
	mode: string;
	schoolId: number;
	element: any;
}

@Component({
	selector: 'app-popin-add-ecoles',
	templateUrl: './popin-add-ecoles.component.html',
	styleUrls: ['./popin-add-ecoles.component.scss']
})
export class PopinAddEcolesComponent implements OnInit {

	toggleFilters = true;
	activeFiltersNb: number = 0;
	isSearching = false;
	searchValue = ""

	ecolesAlreadyLink: any;
	groupId: any;
	userId: any;

	tableProperties: string[] = ['reference', 'name', 'postalCode', 'city'];
	dataSource: MatTableDataSource<IEcoleListDisplay>;
	schools: IEcoleListDisplay[] = [];

	pageIndex: number = 1;
	pageSize: number = 5;
	sortColumn = "name";
	sortOrder = "asc";
	schoolNb: number;
	isLoadingSchools = true;
	newValuesArray: any;
	formCtrlSub: Subscription;
	searchFormControl = new FormControl();

	listType = LIST_TYPE.ADD_SCHOOL_LIST;

	private _allSchoolList: IEcoleList;
	_unsubscriber$: Subject<void> = new Subject();

	constructor(
		public dialogRef: MatDialogRef<PopinAddEcolesComponent>,
		private formBuilder: FormBuilder,
		private groupApi: GroupApi,
		private userApi: UserApi,
		private schoolApi: SchoolApi,
		private staticService: StaticService,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		@Inject(MAT_DIALOG_DATA) public group: DialogData,
		@Inject(MAT_DIALOG_DATA) public user: DialogData,
		private schoolFunctService: SchoolFunctService) {
	}

	ngOnInit() {
		this.getSchoolList();
		this.ecolesAlreadyLink = this.data;
		this.groupId = this.data.group;
		this.userId = this.data.user;


		this.formCtrlSub = this.searchFormControl.valueChanges.pipe(debounceTime(1000))
			.subscribe(value => {
				if (value !== this.searchValue) {
					this.isLoadingSchools = true;
					this.searchValue = value;
					this.search(this.searchValue);
				}
			});
	}

	getSchoolList() {
		this.schoolApi.getSchoolList(this.pageIndex, this.pageSize, this.sortColumn, this.sortOrder).pipe(
			switchMap(_ => this.staticService.habilitations$,
				(data, _) => {
					if (!this._allSchoolList) {
						this._allSchoolList = data
					}
					this.isLoadingSchools = false;
					this.schoolNb = data.totalCount;
					this.staticService.habilitations$.subscribe(_ => {
						this.dataSource = new MatTableDataSource<IEcoleListDisplay>(this.schoolToSchoolVM(data))
					})
				})
		).subscribe();
	}

	schoolToSchoolVM(ecoleList: IEcoleList) {
		return ecoleList.ecoles.map(ec => { ec.habilitations = this.staticService.habilitationToString(+ec.habilitations); return ec; });
	}

	updateSortParam(event) {
		this.toggleFilters = !this.toggleFilters;
		this.sortColumn = event.column;
		this.sortOrder = event.sortDirection;
		this.getProcessedSchoolList();
	}

	updatePageParam(event) {
		this.pageIndex = event.pageIndex + 1;
		this.pageSize = event.pageSize;
		this.getProcessedSchoolList();
	}

	getProcessedSchoolList() {
		this.toggleFilters = true;
		if (this.activeFiltersNb >= 1) {
			this.getFilteredSchoolList(this.transformFiltersToBody());
		} else if (this.isSearching) {
			this.isLoadingSchools = true;
			this.search(this.searchValue);
		} else {
			this.isLoadingSchools = true;
			this.getSchoolList();
		}
	}

	getFilteredSchoolList(filters: IAPIFilters) {
		this.schoolApi.getFilteredSchoolList(filters).subscribe(data => {
			this.schoolNb = data.totalCount; this.dataSource = new MatTableDataSource<IEcoleListDisplay>(this.schoolToSchoolVM(data))
		});
	}

	private transformFiltersToBody(): IAPIFilters {
		const body: IAPIFilters = {
			CodeCDM: [],
			CodeUAI: [],
			Habilitations: [],
			Regions: [],
			Departements: [],
			Type: [],
			PageNumber: this.pageIndex,
			PageSize: this.pageSize,
			sortColumn: this.sortColumn,
			sortOrder: this.sortOrder
		}

		body.Habilitations = this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.HABILITATIONS].map(el => el.id);
		body.Regions = this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.REGION];
		body.Departements = this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.DEPARTEMENT];
		body.Type = this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.TYPE].map(el => el.id);
		body.CodeUAI = this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_UAI];
		body.CodeCDM = this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_CDM];

		return body;
	}

	search(searchString: string) {
		if (this.searchValue !== "") {
			this.schoolApi.search(searchString, this.sortOrder, this.sortColumn, this.pageIndex, this.pageSize).subscribe(data => {
				this.isLoadingSchools = false;
				this.dataSource = new MatTableDataSource<IEcoleListDisplay>(this.schoolToSchoolVM(data));
				this.schoolNb = data.totalCount;
				this.dataSource.paginator.firstPage();
			});
		} else {
			this.dataSource = new MatTableDataSource<IEcoleListDisplay>(this.schoolToSchoolVM(this._allSchoolList));
			this.isLoadingSchools = false;
		}
	}

	newValues(elements) {
		this.newValuesArray = elements;
	}

	onSubmit() {
		const sendNewValues = this.newValuesArray.data.map(element => element.schoolId);
		if (this.groupId !== -1) {
			this.groupApi.addSchools(this.groupId, sendNewValues)
				.subscribe();
		} else {
			this.userApi.addSchools(this.userId, sendNewValues)
				.subscribe();
		}
		this.dialogRef.close(this.newValuesArray);
	}

}
