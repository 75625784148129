import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SchoolApi } from '../../api/schoolApi';
import { IEcoleListDisplay, IAPIFilters, IEcoleList } from '../../../models/ecole.model';
import { SchoolFunctService } from '../../services/functional-services/school-service/school-funct.service';
import { SCHOOL_FILTER_TYPE } from '../../../enums/ecole.enum';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, debounceTime, switchMap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { StaticService } from '../../services/functional-services/static-service/static-service';
import { TableService, ObjectToDelete } from '../../services/functional-services/table-service/table-service';
import { LIST_TYPE } from '../../../enums/lists.type.enum';
import { FormControl } from '@angular/forms';
import { GenericPopinComponent } from 'src/app/components/popins/generic-popin/generic-popin.component';
import { POPIN_MODS, IMPORT_TYPE } from 'src/enums/utils.enum';
import { PopinErrorComponent } from 'src/app/components/popins/popin-error/popin-error.component';
import { MatDialog } from '@angular/material';
import { UserRights } from 'src/models/user.model';
import { AccountService } from 'src/app/api/services/account.service';
import { USER_ROLE } from 'src/enums/users.enum';
import { PromesseService } from 'src/app/api/services/promesse.service';
import { VersementService } from 'src/app/api/services/versement.service';

@Component({
    selector: 'app-schools-list',
    templateUrl: './schools-list.component.html',
    styleUrls: ['./schools-list.component.scss'],
})
export class SchoolsListComponent implements OnInit, OnDestroy {
    tableProperties: string[] = ['reference', 'codeUAI', 'name', 'postalCode', 'city', 'habilitations'];
    dataSource: MatTableDataSource<IEcoleListDisplay>;
    schools: IEcoleListDisplay[] = [];

    clickOnSearch = false;

    toggleFilters = true;
    activeFiltersNb: number = 0;

    pageIndex: number = 1;
    pageSize: number = 50;
    sortColumn = 'name';
    sortOrder = 'asc';
    schoolNb: number;
    resetPaginator = true;

    isSearching = false;
    isLoadingSchools = true;
    searchValue = '';
    searchFormControl = new FormControl();
    formCtrlSub: Subscription;
    currentUserRights: UserRights;
    userRoleAdmin: USER_ROLE.ADMIN;
    listType: LIST_TYPE = LIST_TYPE.SCHOOL_LIST;

    private _unsubscriber$: Subject<void> = new Subject();
    private _allSchoolList: IEcoleList;

    constructor(
        private schoolApi: SchoolApi,
        private promesseService: PromesseService,
        private versementService: VersementService,
        private schoolFunctService: SchoolFunctService,
        private router: Router,
        private staticService: StaticService,
        private tableService: TableService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private accountService: AccountService
    ) {}

    ngOnInit() {
        this.accountService.currentUserRights$
            .pipe(takeUntil(this._unsubscriber$))
            .subscribe((val) => (this.currentUserRights = val));
        this.schoolFunctService.totalFilters$.pipe(takeUntil(this._unsubscriber$)).subscribe((val) => {
            this.activeFiltersNb = val;
            this.resetPaginator = true;
            this.getProcessedSchoolList();
        });
        this.tableService.itemToDelete$.pipe(takeUntil(this._unsubscriber$)).subscribe((val) => {
            if (val.listType === LIST_TYPE.SCHOOL_LIST) {
                this.deleteSchool(val);
            }
        });
        this.formCtrlSub = this.searchFormControl.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
            if (value !== this.searchValue) {
                this.isLoadingSchools = true;
                this.searchValue = value;
                this.resetPaginator = true;
                this.search(this.searchValue);
            }
        });
    }

    exportPayments() {
        this.isLoadingSchools = true;
        let schoolIds = this._allSchoolList.ecoles.map((x) => Number(x.schoolId));
        this.versementService.exportMultiVersementList(schoolIds).subscribe(
            (data) => {
                this.isLoadingSchools = false;
                const fileExtension = '.xlsx';
                const blob = new Blob([data]);
                const downloadLink = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = downloadLink;
                a.download = `export-versements-${new Date().toLocaleDateString().replace(/\//g, '-')}${fileExtension}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            },
            (_) =>
                this.dialog.open(GenericPopinComponent, {
                    width: '630px',
                    data: {
                        mode: POPIN_MODS.MESSAGE,
                        title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
                        bodyComponent: PopinErrorComponent,
                        data: "Une erreur s'est produite lors de l'export des données",
                    },
                })
        );
    }

    exportPromesses() {
        this.isLoadingSchools = true;
        let schoolIds = this._allSchoolList.ecoles.map((x) => Number(x.schoolId));
        this.promesseService.exportMultiPromessList(schoolIds).subscribe(
            (data) => {
                this.isLoadingSchools = false;
                const fileExtension = '.xlsx';
                const blob = new Blob([data]);
                const downloadLink = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = downloadLink;
                a.download = `export-promesses-${new Date().toLocaleDateString().replace(/\//g, '-')}${fileExtension}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            },
            (_) =>
                this.dialog.open(GenericPopinComponent, {
                    width: '630px',
                    data: {
                        mode: POPIN_MODS.MESSAGE,
                        title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
                        bodyComponent: PopinErrorComponent,
                        data: "Une erreur s'est produite lors de l'export des données",
                    },
                })
        );
    }

    updateSortParam(event) {
        this.toggleFilters = !this.toggleFilters;
        this.sortColumn = event.column;
        this.sortOrder = event.sortDirection;
        this.getProcessedSchoolList();
    }

    getProcessedSchoolList() {
        if (this.activeFiltersNb >= 1) {
            this.getFilteredSchoolList(this.transformFiltersToBody());
        } else if (this.isSearching) {
            this.isLoadingSchools = true;
            this.search(this.searchValue);
        } else {
            this.isLoadingSchools = true;
            this.getSchoolList();
        }
    }

    getSchoolList() {
        this.schoolApi
            .getSchoolList(this.pageIndex, this.pageSize, this.sortColumn, this.sortOrder)
            .pipe(
                switchMap(
                    (_) => this.staticService.habilitations$,
                    (data, _) => {
                        if (data.totalCount === 1) {
                            this.router.navigateByUrl(`/ecole/${data.ecoles[0].schoolId}`);
                        }
                        if (!this._allSchoolList) {
                            this._allSchoolList = data;
                        }

                        this.isLoadingSchools = false;
                        this.schoolNb = data.totalCount;
                        this.staticService.habilitations$.subscribe((_) => {
                            this.dataSource = new MatTableDataSource<IEcoleListDisplay>(this.schoolToSchoolVM(data));
                        });
                    }
                )
            )
            .subscribe();
    }

    schoolToSchoolVM(ecoleList: IEcoleList) {
        return ecoleList.ecoles.map((ec) => {
            ec.habilitations = this.staticService.habilitationToString(+ec.habilitations);
            return ec;
        });
    }

    getFilteredSchoolList(filters: IAPIFilters) {
        this.schoolApi.getFilteredSchoolList(filters).subscribe((data) => {
            this.schoolNb = data.totalCount;
            this.dataSource = new MatTableDataSource<IEcoleListDisplay>(this.schoolToSchoolVM(data));
            this.isLoadingSchools = false;
        });
    }

    private transformFiltersToBody(): IAPIFilters {
        const body: IAPIFilters = {
            CodeCDM: [],
            CodeUAI: [],
            Habilitations: [],
            Regions: [],
            Departements: [],
            Type: [],
            PageNumber: this.pageIndex,
            PageSize: this.pageSize,
            sortColumn: this.sortColumn,
            sortOrder: this.sortOrder,
        };

        body.Habilitations = this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.HABILITATIONS].map(
            (el) => el.id
        );
        body.Regions = this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.REGION];
        body.Departements = this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.DEPARTEMENT];
        body.Type = this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.TYPE].map((el) => el.id);
        body.CodeUAI = this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_UAI];
        body.CodeCDM = this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.CODE_CDM];

        return body;
    }

    goCreateSchoolForm() {
        this.router.navigate(['/creation-ecole'], {
            queryParams: { createSchool: true },
        });
    }

    deleteSchool(item: ObjectToDelete) {
        this.schoolApi.deleteSchool(item.itemId).subscribe(
            (e) => {
                this.schoolApi
                    .getSchoolList(this.pageIndex, this.pageSize, this.sortColumn, this.sortOrder)
                    .subscribe(
                        (data) =>
                            (this.dataSource = new MatTableDataSource<IEcoleListDisplay>(this.schoolToSchoolVM(data)))
                    );
            },
            (err) => {
                this.dialog.open(GenericPopinComponent, {
                    width: '630px',
                    data: {
                        mode: POPIN_MODS.MESSAGE,
                        title: this.translate.instant('ATTENTION'),
                        bodyComponent: PopinErrorComponent,
                        data: `${err.message}.`,
                    },
                });
                return;
            }
        );
    }

    search(searchString: string) {
        if (this.searchValue !== '') {
            this.schoolApi
                .search(searchString, this.sortOrder, this.sortColumn, this.pageIndex, this.pageSize)
                .subscribe((data) => {
                    this.isLoadingSchools = false;
                    this.dataSource = new MatTableDataSource<IEcoleListDisplay>(this.schoolToSchoolVM(data));
                    this.schoolNb = data.totalCount;
                });
        } else {
            this.dataSource = new MatTableDataSource<IEcoleListDisplay>(this.schoolToSchoolVM(this._allSchoolList));
            this.schoolNb = this._allSchoolList.totalCount;
            this.isLoadingSchools = false;
        }
    }

    updatePageParam(event) {
        this.resetPaginator = false;
        this.pageIndex = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.getProcessedSchoolList();
    }

    ngOnDestroy() {
        this._unsubscriber$.next();
        this._unsubscriber$.complete();
    }

    focusFilter() {
        document.getElementById('myInput').focus();
        this.clickOnSearch = !this.clickOnSearch;
    }

    importCSV(file: File, importType = IMPORT_TYPE.ECOLES) {
        this.isLoadingSchools = true;
        this.schoolFunctService
            .importCSVFile(file[0], 0, importType)
            .pipe(
                switchMap((_) => {
                    this.isLoadingSchools = false;
                    return this.schoolApi.getSchoolList(this.pageIndex, this.pageSize, this.sortColumn, this.sortOrder);
                })
            )
            .subscribe(
                (data) => {
                    if (!this._allSchoolList) {
                        this._allSchoolList = data;
                    }

                    this.isLoadingSchools = false;
                    this.schoolNb = data.totalCount;

                    this.dataSource = new MatTableDataSource<IEcoleListDisplay>(this.schoolToSchoolVM(data));
                },
                (err) => {
                    if (err.status === 400) {
                        this.dialog.open(GenericPopinComponent, {
                            width: '630px',
                            data: {
                                mode: POPIN_MODS.MESSAGE,
                                title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
                                bodyComponent: PopinErrorComponent,
                                data: "Une erreur est survenue lors de l'import : " + err.error.message,
                            },
                        });
                    } else {
                        this.dialog.open(GenericPopinComponent, {
                            width: '630px',
                            data: {
                                mode: POPIN_MODS.MESSAGE,
                                title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
                                bodyComponent: PopinErrorComponent,
                                data: "Une erreur est survenue lors de l'import",
                            },
                        });
                    }
                    this.schoolApi
                        .getSchoolList(this.pageIndex, this.pageSize, this.sortColumn, this.sortOrder)
                        .subscribe((data) => {
                            if (!this._allSchoolList) {
                                this._allSchoolList = data;
                            }

                            this.isLoadingSchools = false;
                            this.schoolNb = data.totalCount;
                            this.dataSource = new MatTableDataSource<IEcoleListDisplay>(this.schoolToSchoolVM(data));
                        });
                }
            );
    }

    onClickedOutside(e) {
        if (e.target.title !== 'openSchoolFilterDiv') {
            if (e.target.offsetParent !== null) {
                if (e.target.offsetParent.title !== 'openSchoolFilterDiv') {
                    if (!this.toggleFilters) {
                        this.toggleFilters = true;
                    }
                }
            } else {
                if (e.target.title !== 'deleteFilter' && e.target.title !== 'selectOrder') {
                    if (!this.toggleFilters) {
                        this.toggleFilters = true;
                    }
                }
            }
        }
    }
}
