import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-fiche-entreprise",
  templateUrl: "./fiche-entreprise.component.html",
  styleUrls: ["./fiche-entreprise.component.scss"],
})
export class FicheEntrepriseComponent implements OnInit {
  @Input() set entrepriseForm(form: FormGroup) {
    if (form) {
      this.telephone = form.get("telephone");
      this.email = form.get("email");
      this._entrepriseForm = form;
    }
  }

  @Input() loading;

  @Input() entreprise;

  @Input() isAdmin;

  @Input() disabled;

  @Input() set inModif(val) {
    this._inModif = val;
  }
  @Input() set open(val) {
    if (!val) {
      this._inModif = false;
      this.disabled = true;
    }
  }

  @Output() gettingModified: EventEmitter<void> = new EventEmitter();

  @Output() submitForm: EventEmitter<void> = new EventEmitter();

  get entrepriseForm(): FormGroup {
    return this._entrepriseForm;
  }

  get inModif(): boolean {
    return this._inModif;
  }

  telephone;
  email;

  private _entrepriseForm: FormGroup;
  private _inModif: boolean;

  constructor() {}

  ngOnInit() {}
}
