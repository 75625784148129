import { PdfService } from './pdf.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
    IEcoleVersement,
    RecuLiberatoire,
    IAPIVersementFilters,
    Payment,
    ReceiptSendingDetails,
} from 'src/models/ecole.model';
import { Observable, forkJoin, of } from 'rxjs';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { StaticService } from 'src/app/services/functional-services/static-service/static-service';
import { switchMap, catchError } from 'rxjs/operators';
import { RecuToSend } from '../../services/functional-services/table-service/table-service';
import { PromesseService } from './promesse.service';
import { IAPINotSentPaymentsFilters } from '../../../models/ecole.model';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root',
})
export class VersementService {
    constructor(private http: HttpClient, private pdfService: PdfService, private PromesseService: PromesseService) {}

    // get payments list
    getVersements(
        id: number,
        pageNb: number,
        pageSize: number,
        sortColumn?: string,
        sortOrder?: string
    ): Observable<{ versements: IEcoleVersement[]; totalCount: number }> {
        let params = new HttpParams();
        if (!!pageNb) {
            params = params.append('pageNb', pageNb.toString());
        }
        if (!!pageSize) {
            params = params.append('pageSize', pageSize.toString());
        }
        if (!!sortColumn) {
            params = params.append('sortColumn', sortColumn);
        }
        if (!!sortOrder) {
            params = params.append('sortOrder', sortOrder);
        }
        params = params.append('year', this.PromesseService.getTaxYear().toString());
        return this.http.get<{ versements: IEcoleVersement[]; totalCount: number }>('/api/Ecole/versements/' + id, {
            params: params,
        });
    }

    // Deleting a payment
    deleteVersement(versementId: number): Observable<any> {
        return this.http.delete<any>('/api/Ecole/versement/' + versementId);
    }

    postVersement(payment: Payment, promesseId: number) {
        return this.http.post<any>(`/api/Ecole/versement/add`, {
            ...payment,
            promesseId,
        });
    }

    getRecuData(promesseId: number): Observable<RecuLiberatoire> {
        return this.http.get<RecuLiberatoire>(`/api/Ecole/versement/recu/${promesseId}`);
    }

    getLastRecuNbr(ecoleId: number): Observable<number> {
        let params = new HttpParams();
        params = params.append('year', this.PromesseService.getTaxYear().toString());
        return this.http.get<number>(`/api/Ecole/versement/LastRecuNbr/${ecoleId}`);
    }

    uploadLastReceiptDateInEcole(schoolId: number) {
        let params = new HttpParams();
        params = params.append('year', this.PromesseService.getTaxYear().toString());
        return this.http.get<any>(`/api/Ecole/versement/updateLastReceiptDateInEcole/${schoolId}`, { params });
    }

    uploadRecu(
        recu: File | Blob,
        schoolId: number,
        schoolReference: string,
        versementId: number,
        recuNbr: number,
        entSiret: string
    ): Observable<any> {
        const formData = new FormData();
        formData.append('file', recu, 'recu.pdf');
        let params = new HttpParams()
            .append('receiptNbr', recuNbr.toString())
            .append('entSiretNbr', entSiret.replace(/\s/g, ''))
            .append('schoolReference', schoolReference);

        const headers = new HttpHeaders({ enctype: 'multipart/form-data' });

        return this.http.post<any>(
            `/api/Ecole/versement/upload/${schoolId}/${versementId}/${this.PromesseService.taxYear}`,
            formData,
            { headers, params }
        );
    }

    downloadRecu(versementId: number): Observable<any> {
        return this.http.get(`/api/Ecole/versement/recu/download/` + versementId, {
            responseType: 'arraybuffer',
        });
    }

    generateRecu(recu: RecuLiberatoire, index: number, lastRecuNbr: number) {
        return new Promise((resolve) => {
            const documentDefinition = this.pdfService.getDocumentDefinition(
                recu,
                index,
                lastRecuNbr,
                this.PromesseService.taxYear
            );
            let file: any;

            const pdfGen = pdfMake.createPdf(documentDefinition);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                pdfGen.getBlob((blob) => {
                    file = new Blob([blob], { type: 'application/pdf' });
                    file = this.blobToFile(file, 'recu.pdf');
                    resolve(file);
                });
            } else {
                pdfGen.getBlob((blob) => {
                    file = new File([blob], 'recu.pdf', { type: 'application/pdf' });
                    resolve(file);
                });
            }
        });
    }

    blobToFile(theBlob: Blob, fileName: string): File {
        const b: any = theBlob;
        b.lastModified = new Date().getTime();
        b.lastModifiedDate = new Date();
        b.name = fileName;
        b.webkitRelativePath = '';
        return b as File;
    }
    //versementId: number, isFromUnitarySelection: boolean
    sendReceiptMail(recuToSend: RecuToSend): Observable<IEcoleVersement> {
        return this.http.post<IEcoleVersement>(
            `/api/Ecole/versement/mailRecu/${recuToSend.versementId}/${recuToSend.isFromUnitarySelection}`,
            {}
        );
    }

    getTargetMail(recuToSend: RecuToSend): Observable<string> {
        return this.http.get<string>(
            `/api/Ecole/versement/target/${recuToSend.versementId}/${recuToSend.isFromUnitarySelection}`,
            {}
        );
    }

    getVersementById(id: number): Observable<IEcoleVersement> {
        return this.http.get<IEcoleVersement>(`/api/Ecole/versement/${id}`, {});
    }

    sendMultipleReceiptsMail(body: IEcoleVersement[][], isAdmin: boolean): Observable<any> {
        let params = new HttpParams();
        params = params.append('isRecu', isAdmin.toString());
        const processedArray = this.splitVersementReceiptMailArrayByGPCodeUnique(body);

        var arrayForMultipleSending = processedArray.filter(
            (versementArray) =>
                versementArray.length > 1 ||
                (versementArray[0].gpCodeUnique != null &&
                    versementArray[0].gpCodeUnique != '' &&
                    versementArray[0].gpCodeUnique != ' ')
        );

        var arrayForSingleSending = processedArray.filter(
            (versementArray) =>
                versementArray.length == 1 &&
                !(
                    versementArray[0].gpCodeUnique != null &&
                    versementArray[0].gpCodeUnique != '' &&
                    versementArray[0].gpCodeUnique != ' '
                )
        );

        const singleSendingObservableArray = [];

        arrayForSingleSending.forEach((versement: IEcoleVersement[]) => {
            singleSendingObservableArray.push(
                this.sendReceiptMail(new RecuToSend(versement[0].versementId, false)).pipe(
                    catchError(() => {
                        return of({
                            siren: versement[0].siret,
                            nomEntreprise: versement[0].nomEntreprise,
                            gpCodeUnique: versement[0].gpCodeUnique,
                        });
                    })
                )
            );
        });

        return this.http.post<any>(`/api/Ecole/versement/mailRecus`, arrayForMultipleSending, { params }).pipe(
            switchMap(
                (_) => {
                    if (singleSendingObservableArray.length === 0) {
                        return of(singleSendingObservableArray);
                    }
                    return forkJoin(singleSendingObservableArray);
                },
                (mailError, singleMailErrors) => {
                    let err = [];
                    singleMailErrors.forEach((val) => {
                        if (Object.keys(val).length === 4) {
                            err.push(val);
                        }
                    });

                    return mailError.concat(err);
                }
            )
        );
    }

    exportVersementList(schoolId: number) {
        let params = new HttpParams();
        params = params.append('year', this.PromesseService.getTaxYear().toString());
        return this.http.get(`api/Ecole/${schoolId}/versements/export`, {
            params,
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
        });
    }

    exportMultiVersementList(schoolIds: number[]) {
        let params = new HttpParams();
        params = params.append('year', this.PromesseService.getTaxYear().toString());
        params = params.append('schoolIds', schoolIds.toString());
        return this.http.get(`api/Ecole/versements/multiExport`, {
            params,
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
        });
    }

    getFilteredVersements(
        id: number,
        body: IAPIVersementFilters
    ): Observable<{ versements: IEcoleVersement[]; totalCount: number }> {
        body.year = this.PromesseService.getTaxYear();
        return this.http.post<{
            versements: IEcoleVersement[];
            totalCount: number;
        }>(`/api/Ecole/FilterPayments/${id}`, body);
    }

    getNotSentPayments(body: any): Observable<{ versements: IEcoleVersement[]; totalCount: number }> {
        // Le parseint palit un bug lors du changement d'année dans le select qui renvoi une string (urgence)
        body.year = parseInt(this.PromesseService.getTaxYear().toString(), 10);
        return this.http.post<{
            versements: IEcoleVersement[];
            totalCount: number;
        }>(`/api/Ecole/versement/notSentPayments/`, body);
    }

    receiptSendingDetails(body: IEcoleVersement[][]) {
        return this.http.post<ReceiptSendingDetails[]>(
            `/api/Ecole/versement/receiptSendingDetails`,
            this.splitVersementReceiptMailArrayByGPCodeUnique(body)
        );
    }

    private splitVersementReceiptMailArrayByGPCodeUnique(versements: IEcoleVersement[][]): IEcoleVersement[][] {
        const splittedByGpCodeData = [];
        versements.forEach((sameMailArray) => {
            const gpCodeUniqueList = [...new Set(sameMailArray.map((el) => el.gpCodeUnique))];
            gpCodeUniqueList.forEach((gpCodeUnique) => {
                // Separe les mail identique si pas de gpunique
                if (gpCodeUnique === '' || gpCodeUnique === ' ' || gpCodeUnique === null) {
                    sameMailArray
                        .filter(
                            (versement) =>
                                versement.gpCodeUnique === gpCodeUnique &&
                                (versement.mail !== null || versement.mail !== '')
                        )
                        .forEach((versement) => {
                            splittedByGpCodeData.push([versement]);
                        });
                } else {
                    splittedByGpCodeData.push(
                        sameMailArray.filter(
                            (versement) =>
                                versement.gpCodeUnique === gpCodeUnique &&
                                (versement.mail !== null || versement.mail !== '')
                        )
                    );
                }
            });
        });
        return splittedByGpCodeData;
    }
}
