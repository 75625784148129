import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IEcolePromesse, IEcoleDiplome } from "src/models/ecole.model";
import { Observable, ReplaySubject } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { User, UserRights } from "src/models/user.model";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  private _currentUser$: ReplaySubject<User> = new ReplaySubject(1);
  currentUser$: Observable<User> = this._currentUser$.asObservable();

  private _currentUserRights$: ReplaySubject<UserRights> = new ReplaySubject(1);
  currentUserRights$: Observable<UserRights> = this._currentUserRights$.asObservable();

  constructor(private http: HttpClient) {}

  logout() {
    return this.http.get("/api/Account/logout");
  }

  me(): Observable<any> {
    return this.http.get<any>("api/account/me");
  }

  getUserDetails(id: string): Observable<any> {
    let params = new HttpParams();
    if (!!id) params = params.append("id", id);
    return this.http.get<any>(`api/Utilisateurs/details/${id}`);
  }

  updateAccountProfile(user: User) {
    return this.http.put<User>(`api/Account/profile/${user.userId}`, user);
  }

  updatePassword(password: { oldPassword: string; newPassword: string }) {
    return this.http.post<any>(`api/account/password/`, password);
  }

  getAccount(id: string): Observable<User> {
    return this.http.get<User>(`api/Account/profile/${id}`);
  }

  loadUserContext() {
    return this.me().pipe(
      switchMap(
        (val) => {
          this._currentUserRights$.next(val);
          return this.getAccount(val.userId);
        },
        (val, user) => {
          this._currentUser$.next({ ...user, userId: val.userId });
        }
      )
    );
  }
}
