import { Injectable, Inject } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PaiementApi {
    constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {

    }

    getInitPayment(planId: string, emailAbonne: string, modePaiement): Observable<any> {
        let params = new HttpParams();
        if (!!planId) {
            params = params.append('planId', planId);
        }
      params = params.append('modePaiement', modePaiement);
      params = params.append('emailAbonne', emailAbonne);

        return this.http.get<any>(`${this.baseUrl}api/Paiement/initpayment/`, { params: params });
    }
  
    getFormules(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}api/Paiement/getFormules/`, {});
    }

    postUpdatePaymentIntent(paymentMethod): Observable<any> {
        let params = new HttpParams();
        if (!!paymentMethod) {
            params = params.append('paymentMethod', paymentMethod);
        }
        return this.http.post<any>(`${this.baseUrl}api/Paiement/updatepaymentintent`, { params: params });
    }

    postCreateSouscription(emailabonne) {
        return this.http.get<any>(`${this.baseUrl}api/Paiement/createsouscription/${emailabonne}`);
    }

    getApiKey(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}api/Paiement/getapikey/`, {});
    }
}
