import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { IEcoleDiplome } from 'src/models/ecole.model';
import { Subject, Observable } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { IHabilitations } from 'src/models/static.model';
import { StaticService } from 'src/app/services/functional-services/static-service/static-service';
import { HABILITATIONS } from 'src/enums/ecole.enum';

@Component({
    selector: 'app-popin-diploma',
    templateUrl: './popin-diploma.component.html',
    styleUrls: ['./popin-diploma.component.scss'],
})
export class PopinDiplomaComponent implements OnInit {
    unsubscriber$: Subject<void> = new Subject();

    form: FormGroup;
    private _data: IEcoleDiplome;

    get codeDiplomeControl() {
        return this.form.get('codeDiplome');
    }
    get nomControl() {
        return this.form.get('nom');
    }
    get nomDiplomeControl() {
        return this.form.get('nomDiplome');
    }
    get dureeControl() {
        return this.form.get('duree');
    }

    @Input() set data(data: IEcoleDiplome) {
        this._data = data;
        this.form = this.fb.group({
            codeDiplome: this.fb.control(this.data.codeDiplome, Validators.required),
            nom: this.fb.control(this.data.nom, Validators.required),
            nomDiplome: this.fb.control(this.data.nomDiplome, Validators.required),
            habilitations: this.habilitationsControl,
            duree: this.fb.control(this.data.duree, [Validators.required, Validators.pattern('^[0-9]*$')]),
            estActif: this.fb.control(true),
        });
        this.staticService.habilitations$.pipe(takeUntil(this._unsubscriber$)).subscribe((val) => {
            if (val) {
                this.habilitations = val
                    .map((el) => {
                        return { ...el, id: el.habilitationId, nom: el.nom };
                    })
                    .filter((el) => el.id >= HABILITATIONS.A$B);
                if (this.data.habilitations) {
                    this.habilitationsControl.setValue(
                        this.habilitations.filter(
                            (hab) => HABILITATIONS[(<IHabilitations>this.data.habilitations).nom] & hab.habilitationId
                        )
                    );
                }
            }
        });
    }
    get data(): IEcoleDiplome {
        return this._data;
    }

    habilitations: IHabilitations[] = [];
    habilitationsControl = new FormControl();
    types: { id: number; name: string }[] = [];
    typesControl = new FormControl();
    names: { id: number; name: string }[] = [];
    namesControl = new FormControl();

    private _unsubscriber$: Subject<void> = new Subject();

    constructor(private fb: FormBuilder, private staticService: StaticService) {}

    ngOnInit() {
        this.habilitationsControl.setValidators(Validators.required);
        this.typesControl.setValidators(Validators.required);
        this.namesControl.setValidators(Validators.required);
    }

    ngOnDestroy() {
        this.unsubscriber$.next();
        this.unsubscriber$.complete();
    }
}
