import { Component, OnInit, Inject } from '@angular/core';
import { SchoolApi } from 'src/app/api/schoolApi';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Signature } from 'src/models/ecole.model';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { of } from 'rxjs/internal/observable/of';
import { POPIN_MODS } from 'src/enums/utils.enum';
import { UserRights } from 'src/models/user.model';
import { AccountService } from 'src/app/api/services/account.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { TaskboardService } from 'src/app/api/services/taskboard.service';
import { TYPE_TACHE, TASK_STATUS_ID } from 'src/enums/task.enum';
import { Observable } from 'rxjs';
import { IsUploadableFile } from 'src/utils/custom-functions';

export interface DialogData {
	mode: number;
	schoolId: number;
	signature: Signature;
}

@Component({
	selector: 'app-popin-signature',
	templateUrl: './popin-signature.component.html',
	styleUrls: ['./popin-signature.component.scss']
})
export class PopinSignatureComponent implements OnInit {
	schoolId: number;
	uploadedFile: File;
	error: string = "";
	signatureForm: FormGroup;
	currentUserRights: UserRights;

	private _unsubscriber$: Subject<void> = new Subject();

	constructor(
		public dialogRef: MatDialogRef<PopinSignatureComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private formBuilder: FormBuilder,
		private schoolApi: SchoolApi,
		private accountService: AccountService,
		private taskboardService: TaskboardService
	) {
		this.schoolId = this.data.schoolId;
		if (this.data.mode === POPIN_MODS.CREATION) {
			this.signatureForm = this.formBuilder.group({
				ecoleId: this.data.schoolId,
				nom: '',
				prenom: '',
				fonction: ''
			});
		} else if (this.data.mode === POPIN_MODS.EDIT) {
			this.signatureForm = this.formBuilder.group({
				ecoleId: this.data.schoolId,
				nom: this.data.signature.nom,
				prenom: this.data.signature.prenom,
				fonction: this.data.signature.fonction
			});
		}
	}

	ngOnInit() {
		let dropFileEl = document.getElementById("file-box");
		dropFileEl.addEventListener("dragenter", this.dragenter, false);
		dropFileEl.addEventListener("dragover", this.dragover, false);
		dropFileEl.addEventListener("drop", this.dropFile.bind(this), false);

		this.accountService.currentUserRights$.pipe(takeUntil(this._unsubscriber$)).subscribe(va => {
			this.currentUserRights = va;
		})
	}

	dragenter(e) {
		e.stopPropagation();
		e.preventDefault();
	}

	dragover(e) {
		e.stopPropagation();
		e.preventDefault();
	}

	dropFile(e) {
		e.stopPropagation();
		e.preventDefault();
		const dt = e.dataTransfer;
		const files = dt.files;
		this.uploadedFile = files[0];
	}
	onNoClick(): void {
		this.dialogRef.close();
	}

	deleteFile() {
		this.uploadedFile = undefined;
	}

	newFileInput(files: File[]) {
		if (IsUploadableFile(files[0])) {
			this.uploadedFile = files[0];
		}
	}

	onSubmit(signatureData: Signature) {
		if (this.currentUserRights.canManageLogoAndSignature) {
			if (this.data.mode === POPIN_MODS.CREATION) {
				this.schoolApi.addSignature(signatureData).pipe(switchMap(_ => {
					if (this.uploadedFile) {
						return this.schoolApi.uploadSignature(this.uploadedFile, signatureData);
					} else {
						return of(null);
					}
				}), switchMap(_ => {
					return this.schoolApi.getSignatureList(this.schoolId);
				})).subscribe(val => {
					this.dialogRef.close(val);
				});
			} else if (this.data.mode === POPIN_MODS.EDIT) {
				signatureData.signatureId = this.data.signature.signatureId;
				this.schoolApi.editSignature(signatureData).pipe(switchMap(_ => {
					if (this.uploadedFile) {
						return this.schoolApi.uploadSignature(this.uploadedFile, signatureData);
					} else {
						return of(null);
					}
				}), switchMap(_ => {
					return this.schoolApi.getSignatureList(this.schoolId);
				})).subscribe(val => {
					this.dialogRef.close(val);
				});
			}
		} else {
			this.createTask();
		}
	}

	private createTask() {
		const taskEcoleData = { after: {} };
		Object.keys(this.signatureForm.controls).forEach(key => {
			let currentControl = this.signatureForm.controls[key];
			if (currentControl.dirty) {
				taskEcoleData['after'][key] = currentControl.value;
			}
		});
		if (this.uploadedFile) {
			this.schoolApi.uploadTemporaryFile(this.uploadedFile, this.data.schoolId).pipe(switchMap(data => {
				taskEcoleData['after']['signature'] = data;
				return this.handleCreateTask(taskEcoleData);
			})).subscribe(_ => {
				this.dialogRef.close();
			});
		} else {
			this.handleCreateTask(taskEcoleData).subscribe(_ => {
				this.dialogRef.close();
			});;
		}
	}

	handleCreateTask(taskEcoleData): Observable<boolean> {
		return this.taskboardService.createTask({
			taskType: TYPE_TACHE.SIGNATURE,
			statut: TASK_STATUS_ID.WAITING,
			targetId: this.data.mode !== POPIN_MODS.CREATION ? parseInt(this.data.signature.signatureId) : null,
			data: JSON.stringify(taskEcoleData),
			ecoleId: this.data.schoolId
		})
	}
}
