export enum TASK_STATUS_TEXT {
	WAITING = "Non traitée",
	RESOLVED = "Résolue",
	REFUSED = "Refusée",
	DELETED = " Supprimée",
	TRAITEE = "Traitée"
}
export enum TASK_STATUS_ID {
	WAITING = 1,
	RESOLVED,
	REFUSED,
	DELETED,
	TRAITEE
}

export enum TASK_ACTIONS {
	CREATE = 1,
	EDIT,
	DELETE
}

export enum TYPE_TACHE {
	COMPTE = 1,
	DIPLOME,
	IBAN,
	ECOLE,
	FORMULE,
	SIGNATURE
}

export enum STATUT_TACHE {
	NON_TRAITEE = 1,
	RESOLUE,
	REFUSEE,
	SUPPRIMEE,
	TRAITEE
}