import { Component, OnInit, Input, Output, EventEmitter, ɵEMPTY_ARRAY, HostListener, ElementRef } from '@angular/core';
import { EntrepriseInfos } from '../../../../../models/entreprise.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EntrepriseService } from 'src/app/api/services/entreprise.service';
import { checkIfEmail, checkIfValidSiret, checkIfPhone } from 'src/utils/custom-validators';
import { AccountService } from 'src/app/api/services/account.service';
import { USER_ROLE } from 'src/enums/users.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IEcolePromesse, IEcoleVersement } from 'src/models/ecole.model';

@Component({
    selector: 'app-blue-sidebar',
    templateUrl: './blue-sidebar.component.html',
    styleUrls: ['./blue-sidebar.component.scss'],
})
export class BlueSidebarComponent implements OnInit {
    entrepriseForm: FormGroup;
    cabinetSiret: string;
    openned = false;

    @HostListener('document:click', ['$event'])
    clickout(event: Event) {
        this.disabled = true;
        this.inModif = false;
        this.openned = false;
        if (
            !this.eRef.nativeElement.contains(event.target) &&
            !(<HTMLElement>event.target).className.includes('name-action') &&
            !(<HTMLElement>event.target).className.includes('blue-side-btn')
        ) {
            this.closeSidebar();
        }
    }

    @Input() set promise(promise: IEcolePromesse) {
        if (promise) {
            this.openned = true;
            this._versement = undefined;
            this.schoolId = promise.ecoleId;
            this.entrepriseForm.reset();
            this.loading = true;
            this.entrepriseService.getEntreprise(promise.entrepriseId, this.schoolId).subscribe((val) => {
                this.entreprise = val;
            });
        }
    }

    @Input() set versement(versement: IEcoleVersement) {
        if (versement) {
            this.openned = true;
            this._versement = versement;
            this.schoolId = versement.ecoleId;
            this.entrepriseForm.reset();
            this.loading = true;
            this.entrepriseService.getEntreprise(versement.entrepriseId, this.schoolId).subscribe((val) => {
                this.entreprise = val;
            });
        }
    }

    @Input() set entreprise(entreprise: EntrepriseInfos) {
        if (entreprise) {
            this.loading = false;
            this.cabinetSiret = `${entreprise.entreprise.gpSiren}${entreprise.entreprise.gpNic}`;
            this.entrepriseForm = new FormGroup({
                entNom1: this.fb.control(entreprise.entreprise.entNom1),
                entSiret: this.fb.control(entreprise.entreprise.entSiret),
                entAdr1: this.fb.control(entreprise.entreprise.entAdr1),
                entCp: this.fb.control(entreprise.entreprise.entCp),
                entVille: this.fb.control(entreprise.entreprise.entVille),
                email: this.fb.control(entreprise.contact ? entreprise.contact.email : '', [checkIfEmail]),
                nom: this.fb.control(entreprise.contact ? entreprise.contact.nom : ''),
                prenom: this.fb.control(entreprise.contact ? entreprise.contact.prenom : ''),
                telephone: this.fb.control(entreprise.contact ? entreprise.contact.telephone : '', checkIfPhone),
                cre: this.fb.control(entreprise.entreprise.cre),
                cdm: this.fb.control(entreprise.entreprise.cdm),
            });
            this._entreprise = entreprise;
        }
    }
    get entreprise(): EntrepriseInfos {
        return this._entreprise;
    }
    get versement(): IEcoleVersement {
        return this._versement;
    }
    private _entreprise: EntrepriseInfos;
    private _versement: IEcoleVersement;
    private _schoolId: number;
    get email() {
        return this.entrepriseForm.get('email');
    }
    get telephone() {
        return this.entrepriseForm.get('telephone');
    }

    @Input() set schoolId(schoolId: number) {
        this._schoolId = schoolId;
    }
    get schoolId(): number {
        return this._schoolId;
    }

    @Output() closed = new EventEmitter();

    disabled = true;
    inModif;
    myEntreprise: EntrepriseInfos;
    loading = true;
    isAdmin: boolean;
    private _unsubscriber$: Subject<void> = new Subject();

    constructor(
        private EntrepriseApi: EntrepriseService,
        private fb: FormBuilder,
        private accountService: AccountService,
        private entrepriseService: EntrepriseService,
        private eRef: ElementRef
    ) {}

    ngOnInit() {
        this.entrepriseForm = new FormGroup({
            entNom1: this.fb.control(''),
            entSiret: this.fb.control('', checkIfValidSiret),
            entAdr1: this.fb.control(''),
            entCp: this.fb.control(''),
            entVille: this.fb.control(''),
            email: this.fb.control('', checkIfEmail),
            nom: this.fb.control(''),
            prenom: this.fb.control(''),
            telephone: this.fb.control('', checkIfPhone),
            cre: this.fb.control(''),
            cdm: this.fb.control(''),
        });

        this.accountService.currentUserRights$.pipe(takeUntil(this._unsubscriber$)).subscribe((data) => {
            if (data.role === USER_ROLE.ADMIN || data.role === USER_ROLE.HOTLINER) {
                this.isAdmin = true;
            } else {
                this.isAdmin = false;
            }
        });
    }

    submit() {
        this.inModif = true;
        this.disabled = false;
        this.createModifiedEntreprise();
        // on modifie l'entreprise selectionnée, car on est sur le mode édition
        this.EntrepriseApi.updateEntreprise(this.myEntreprise).subscribe((data) => {
            this.entreprise = data;
            this.disabled = true;
            this.inModif = false;
        });
    }

    createModifiedEntreprise() {
        this.myEntreprise = {
            entreprise: {
                ...this.entreprise.entreprise,
                entNom1: this.entrepriseForm.value.entNom1,
                entAdr1: this.entrepriseForm.value.entAdr1,
                entCp: this.entrepriseForm.value.entCp,
                entVille: this.entrepriseForm.value.entVille,
                entSiret: this.entrepriseForm.value.entSiret,
            },
            contact: {
                email: this.entrepriseForm.value.email,
                nom: this.entrepriseForm.value.nom,
                prenom: this.entrepriseForm.value.prenom,
                telephone: this.entrepriseForm.value.telephone,
                entrepriseId: this.entreprise.entreprise.entrepriseId,
                contactId: this.entreprise.contact ? this.entreprise.contact.contactId : null,
            },
            schoolId: this.schoolId,
        };
    }

    closeSidebar(forceClose: boolean = false) {
        this.disabled = true;
        this.inModif = false;
        this.closed.emit(forceClose);
    }

    ngOnDestroy() {
        this._unsubscriber$.next();
        this._unsubscriber$.complete();
    }
}
