import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PromesseService } from '../../../api/services/promesse.service';

@Component({
    selector: 'app-tax-year',
    templateUrl: './tax-year.component.html',
    styleUrls: ['./tax-year.component.scss'],
})
export class TaxYearComponent implements OnInit {
    @Output() getTaxYear: EventEmitter<void> = new EventEmitter();
    @Output() switchYearEvent: EventEmitter<number> = new EventEmitter();

    currentYear: string;
    _promesseService;
    years: string[] = [new Date().getFullYear().toString()];

    constructor(private promesseService: PromesseService) {}

    ngOnInit() {
        this.promesseService.referenceYear$.subscribe((val) => {
            var startYear = 2020;
            this.years = [];
            while (startYear <= parseInt(val.toString())) {
                this.years.push(startYear.toString());
                startYear += 1;
            }
            this.currentYear = val.toString();
        });
    }

    setTaxYear(year: number) {
        this.promesseService.setTaxYear(year);
        this.switchYearEvent.emit(year);
    }
}
