import { catchError, shareReplay, tap } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { IEcolePromesse, IAPIPromiseFilters } from 'src/models/ecole.model';
import { Observable, of, throwError, ReplaySubject } from 'rxjs';
import { IPerfLoggingPrefs } from 'selenium-webdriver/chrome';
import { promise } from 'protractor';
import { PaymentPromise, Payment } from 'src/models/ecole.model';
import { IBank } from '../../../models/static.model';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class PromesseService {
    private _maxPaymentsEntry: number;
    taxYear: number;
    referenceYear$: Observable<number> = this.http.get<number>(`/api/Ecole/getReferenceYear`).pipe(shareReplay(1));

    constructor(private http: HttpClient, private router: Router) {
        this.referenceYear$.subscribe((val) => {
            this.taxYear = val;
        });
    }

    setTaxYear(year: number) {
        this.taxYear = parseInt(year.toString(), 10);
        this.router.navigateByUrl('/ecoles');
    }

    getTaxYear() {
        return this.taxYear;
    }

    getReferenceYear() {
        return this.http.get<number>(`/api/Ecole/getReferenceYear`).pipe(shareReplay(1));
    }

    // get promises list
    getPromesses(
        id: number,
        pageNb: number = 1,
        pageSize: number = 50,
        sortColumn?: string,
        sortOrder?: string
    ): Observable<{ promesses: IEcolePromesse[]; totalCount: number }> {
        let params = new HttpParams();
        if (!!pageNb) {
            params = params.append('pageNb', pageNb.toString());
        }
        if (!!pageSize) {
            params = params.append('pageSize', pageSize.toString());
        }
        if (!!sortColumn) {
            params = params.append('sortColumn', sortColumn);
        }
        if (!!sortOrder) {
            params = params.append('sortOrder', sortOrder);
        }
        params = params.append('year', this.taxYear.toString());
        return this.http.get<{ promesses: IEcolePromesse[]; totalCount: number }>(`/api/Ecole/promesses/${id}`, {
            params,
        });
    }

    getFilteredPromesses(id: number, body: IAPIPromiseFilters) {
        return this.http.post<{ promesses: IEcolePromesse[]; totalCount: number }>(
            `/api/Ecole/FilterPromises/${id}`,
            body
        );
    }

    // Deleting a promise
    deletePromesse(promesseId: number): Observable<any> {
        return this.http.delete<any>('/api/Ecole/promesse/' + promesseId).pipe(
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error);
            })
        );
    }

    postPromise(schoolPromise: IEcolePromesse) {
        return this.http.post<IEcolePromesse>('/api/Ecole/promesse/', schoolPromise);
    }

    putPromise(schoolPromise: IEcolePromesse, schoolId: number) {
        return this.http.put<IEcolePromesse>(`/api/Ecole/promesse/${schoolPromise.promesseId}`, schoolPromise);
    }

    sendReminder(promessId: number) {
        return this.http.post<any>(`/api/Entreprise/promesse/SendReminderMail/${promessId}`, {});
    }

    getTemplate(): Observable<any> {
        return this.http.get('/api/Ecole/promesses/downloadTemplate', {
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
        });
    }

    exportPromessList(schoolId: number) {
        let params = new HttpParams();
        params = params.append('year', this.taxYear.toString());
        return this.http.get(`api/Ecole/${schoolId}/promesses/export`, {
            params,
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
        });
    }

    exportMultiPromessList(schoolIds: number[]) {
        let params = new HttpParams();
        params = params.append('year', this.taxYear.toString());
        params = params.append('schoolIds', schoolIds.toString());
        return this.http.get(`api/Ecole/promesses/multiExport`, {
            params,
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
        });
    }

    getPromisesBySiret(siret: string): Observable<PaymentPromise[]> {
        return this.http.get<PaymentPromise[]>(`api/Ecole/getPromessesBySiret/${siret}/${this.taxYear}`);
    }

    getBankList(): Observable<IBank[]> {
        return this.http.get<IBank[]>(`api/referentiel/banques`);
    }

    postPayment(payment: Payment): Observable<{ maxNb: number; actualNb: number; errorMessage: string }> {
        payment.date = new Date(payment.date).getTime().toString();
        return this.http
            .post<{ maxNb: number; actualNb: number; errorMessage: string }>(`api/Ecole/Versement/AddFromModule`, {
                ...payment,
                modePaiement: parseInt(payment.modePaiement),
                montant: parseFloat(payment.montant),
            })
            .pipe(
                tap((val) => {
                    if (val.maxNb && !this._maxPaymentsEntry) {
                        this._maxPaymentsEntry = val.maxNb;
                    }
                })
            );
    }

    closeEntry(): Observable<number> {
        return this.http.post<number>(`api/Ecole/closeEntry`, {});
    }

    getBordereauFile(nbr: number): Observable<any> {
        return this.http.get(`/api/ecole/getBordereauFile/${nbr}`, {
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'arraybuffer',
        });
    }

    getMaxPaymentsEntry() {
        return this._maxPaymentsEntry;
    }
}
