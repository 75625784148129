import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { PopinIbanComponent } from "../../../components/popins/popin-iban/popin-iban.component";
import { IbanService } from "./../../../api/services/iban.service";
import { SchoolApi } from "../../../../app/api/schoolApi";
import { IEcole, IEcoleIban, Signature } from "../../../../models/ecole.model";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { takeUntil, switchMap, debounceTime } from "rxjs/operators";
import { Subject, of } from "rxjs";
import { PopinSignatureComponent } from "../../../components/popins/popin-signature/popin-signature.component";
import { StaticService } from "src/app/services/functional-services/static-service/static-service";
import {
  IHabilitations,
  ITypeSchool,
  IReseauEcole,
} from "../../../../models/static.model";
import {
  checkIfValidUAI,
  checkIfValidSiret,
  checkIfEmail,
  checkIfPhone,
  higherThanPrevious,
} from "src/utils/custom-validators";
import { TaskboardService } from "src/app/api/services/taskboard.service";
import { TYPE_TACHE, TASK_STATUS_ID } from "src/enums/task.enum";
import { POPIN_MODS } from "src/enums/utils.enum";
import { AccountService } from "src/app/api/services/account.service";
import { User, UserRights } from "src/models/user.model";
import { USER_ROLE } from "src/enums/users.enum";
import { GenericPopinComponent } from "src/app/components/popins/generic-popin/generic-popin.component";
import { PopinErrorComponent } from "src/app/components/popins/popin-error/popin-error.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-school-sheet",
  templateUrl: "./school-sheet.component.html",
  styleUrls: ["./school-sheet.component.scss"],
})
export class SchoolSheetComponent implements OnInit {
  @Input() set school(ecole: IEcole) {
    this._school = ecole;
    if (this.schoolForm) {
      this.generateForm();
    }
  }
  @Input() inModif;
  @Input() disabled;
  @Input() createSchool;

  @Output() newTaskWaiting: EventEmitter<void> = new EventEmitter();

  get school(): IEcole {
    return this._school;
  }
  private _school: IEcole;

  @ViewChild("fileInput", { static: false }) logoInput;

  get codePostal() {
    return this.schoolForm.get("codePostal");
  }
  get siret() {
    return this.schoolForm.get("siret");
  }
  get email() {
    return this.schoolForm.get("email");
  }
  get fax() {
    return this.schoolForm.get("fax");
  }
  get telephone() {
    return this.schoolForm.get("telephone");
  }
  get website() {
    return this.schoolForm.get("website");
  }
  get recuNbr() {
    return this.schoolForm.get("recuNbr");
  }

  userRoles = USER_ROLE;
  regex = "regex";
  codePostalregex = "Le code postal doit contenir 5 chiffres.";
  schoolId: number;
  schoolForm: FormGroup;
  orderObj: any;
  modifiedSchool: IEcole;
  originSchool: any;
  newSchool: IEcole;
  mySchool: IEcole;
  inCreateMode = false;
  addressId: number;
  downloadLink: string;
  uploadedFile: File;
  establishements: { id: number; nom: string };
  habilitations: IHabilitations[];
  types: ITypeSchool[];
  reseauxEcoles: IReseauEcole[];
  private _unsubscriber$: Subject<void> = new Subject();
  selectedHabilitationsList: string = "";

  errorLogo = false;

  previewFileEmplacement: HTMLImageElement;
  currentUserRights: UserRights;
  currentUser: User;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private ibanService: IbanService,
    private schoolApi: SchoolApi,
    private fb: FormBuilder,
    private staticService: StaticService,
    private ref: ChangeDetectorRef,
    private taskboardService: TaskboardService,
    private accountService: AccountService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.accountService.currentUserRights$
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((val) => {
        this.currentUserRights = val;
      });
    this.accountService.currentUser$.subscribe((u) => {
      this.currentUser = u;
    });
    this.staticService.schoolReferentials$
      .pipe(
        switchMap((statics) => {
          this.habilitations = statics.habilitations.map((el) => {
            return { ...el, id: el.habilitationId, nom: el.nom };
          });
          this.types = statics.types;
          return this.staticService.reseauEcole$;
        }),
        takeUntil(this._unsubscriber$)
      )
      .subscribe((reseauxEcoles) => {
        this.reseauxEcoles = reseauxEcoles.map((reseau) => {
          return { ...reseau, nom: reseau.nom.trim() };
        });
        this.generateForm();
      });

    this.route.queryParamMap
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((params) => {
        this.orderObj = { ...params.keys, ...params };
        if (this.orderObj[0] === "inModif") {
          this.inModif = true;
          this.disabled = false;
        }
        if (this.orderObj[0] === "createSchool") {
          this.inCreateMode = true;
        }
      });
  }

  generateForm() {
    this.schoolForm = new FormGroup({
      type: this.fb.control(
        this.types.filter((type) => type.id === this.school.ecoleInfo.type),
        Validators.required
      ),
      reseauEcole: this.fb.control(
        this.reseauxEcoles.filter(
          (type) => type.id === this.school.ecoleInfo.reseauEcole
        )
      ),
      habilitations: this.fb.control(
        this.habilitations.filter(
          (hab) => hab.habilitationId & +this.school.ecoleInfo.habilitations
        )
      ),
      sigle: this.fb.control(this.school.ecoleInfo.sigle),
      siret: this.fb.control(this.school.ecoleInfo.siret, [checkIfValidSiret]),
      nom1: this.fb.control(this.school.ecoleInfo.nom1, Validators.required),
      nom2: this.fb.control(this.school.ecoleInfo.nom2),
      codeUai: this.fb.control(this.school.ecoleInfo.codeUai),
      reference: this.fb.control(this.school.ecoleInfo.reference),
      ecoleId: this.fb.control(this.school.ecoleInfo.ecoleId),
      recuNbr: this.fb.control(
        this.school.ecoleInfo.recuNbr ? this.school.ecoleInfo.recuNbr : 1,
        [
          higherThanPrevious(
            this.school.ecoleInfo.recuNbr ? this.school.ecoleInfo.recuNbr : 1
          ),
        ]
      ),

      adresse1: this.fb.control(this.school.adresse.adresse1),
      adresse2: this.fb.control(this.school.adresse.adresse2),
      addressId: this.fb.control(this.school.adresse.adresseId),
      ville: this.fb.control(this.school.adresse.ville),
      codePostal: this.fb.control(
        this.school.adresse.codePostal !== 0
          ? this.school.adresse.codePostal
          : "",
        [Validators.required, Validators.pattern(/^\d{5}$/)]
      ),
      cedex: this.fb.control(this.school.adresse.cedex),
      regionNom: this.fb.control({
        value: this.school.adresse.regionNom,
        disabled: true,
      }),
      contactPrenom: this.fb.control(this.school.adresse.contactPrenom),
      contactNom: this.fb.control(this.school.adresse.contactNom),
      email: this.fb.control(this.school.adresse.email, [checkIfEmail]),
      telephone: this.fb.control(this.school.adresse.telephone, [checkIfPhone]),
      fax: this.fb.control(this.school.adresse.fax, [checkIfPhone]),
      website: this.fb.control(this.school.adresse.webSite, [
        Validators.pattern(
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
        ),
      ]),
    });

    this.schoolId = +this.route.snapshot.paramMap.get("id");
    this.addressId = this.school.adresse.adresseId;

    this.schoolForm
      .get("codeUai")
      .setValidators([
        checkIfValidUAI(this.schoolForm.get("codePostal").value),
      ]);

    this.originSchool = this.schoolForm.getRawValue();

    this.schoolForm
      .get("codePostal")
      .valueChanges.pipe(
        debounceTime(300),
        switchMap((data) => {
          if (data.length === 5) {
            this.schoolForm
              .get("codeUai")
              .setValidators([
                checkIfValidUAI(this.schoolForm.get("codePostal").value),
              ]);
            return this.schoolApi.getRegionByPostalCode(data);
          }
          return of(null);
        })
      )
      .subscribe((val) => {
        if (val) {
          this.schoolForm.get("regionNom").setValue(val.nom);
          this.school.adresse.regionId = val.regionId;
        }
      });

    this.schoolForm.get("habilitations").value.forEach((hab) => {
      this.selectedHabilitationsList = `${this.selectedHabilitationsList} ${hab.nom}`;
    });

    this.schoolForm
      .get("habilitations")
      .valueChanges.pipe(takeUntil(this._unsubscriber$))
      .subscribe((val) => {
        this.selectedHabilitationsList = "";
        val.forEach((hab) => {
          this.selectedHabilitationsList = `${this.selectedHabilitationsList} ${hab.nom}`;
        });
      });

    this.ref.detectChanges();

    // TODO : Sale mais fonctionnel
    setTimeout(() => {
      let dropFileEl = document.getElementById("file-box");
      if (dropFileEl !== null) {
        dropFileEl.addEventListener("dragenter", this.dragenter, false);
        dropFileEl.addEventListener("dragover", this.dragover, false);
        dropFileEl.addEventListener("drop", this.dropFile.bind(this), false);
      }
    }, 1000);
  }

  dragenter(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  dragover(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  dropFile(e) {
    e.stopPropagation();
    e.preventDefault();
    const dt = e.dataTransfer;
    const files = dt.files;
    if (this.checkFileFormat(files[0])) {
      this.uploadedFile = files[0];
      this.displayUploadedFile(files[0]);
    }
  }

  displayUploadedFile(file: File) {
    var reader = new FileReader();
    const school = this.school;
    reader.addEventListener(
      "load",
      function () {
        school.ecoleInfo.logoImage = this.result.toString();
      },
      false
    );

    reader.readAsDataURL(file);
  }

  checkFileFormat(file: File) {
    const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
    return file && acceptedImageTypes.includes(file["type"]);
  }

  checkIfDifferentYear(date: any) {
    if (date != null) {
      return false;
    }
    return true;
  }

  checkFileSize(file: File) {
    return file.size < 2097152;
  }

  keyAscOrder() {
    return 0;
  }

  cancelForm() {
    this.logoInput.nativeElement.value = "";
    this.errorLogo = false;
    if (this.createSchool === true) {
      this.router.navigate(["/ecoles"]);
    } else {
      this.disabled = true;
      this.inModif = false;
      this.schoolForm.setValue(this.originSchool);
    }
  }

  printType(value) {
    if (value === 1) {
      return "Ecole partenaires";
    }
    if (value === 3) {
      return "Autres Ecoles";
    }
    return -1;
  }

  submit() {
    if (this.schoolForm.valid === true && !this.disabled) {
      this.createModifiedSchool();
      this.errorLogo = false;
      if (this.inCreateMode === false) {
        // on modifie l'ecole selectionnée, car on est sur le mode édition
        this.mySchool.adresse.regionId = this.school.adresse.regionId;
        this.mySchool.ecoleInfo.logo = this.school.ecoleInfo.logo;
        this.mySchool.ecoleInfo.logoImage = this.school.ecoleInfo.logoImage;
        if (this.currentUserRights.canCreateUpdateDelete) {
          this.schoolApi
            .editSchool(this.mySchool)
            .pipe(
              switchMap(
                (_) => {
                  if (this.uploadedFile) {
                    return this.schoolApi.uploadLogo(
                      this.uploadedFile,
                      this.schoolId
                    );
                  } else {
                    return of(null);
                  }
                },
                (school, _) => {
                  school.ecoleInfo = {
                    ...school.ecoleInfo,
                    logoImage: this.school.ecoleInfo.logoImage,
                    logo: this.school.ecoleInfo.logo,
                  };
                  this.school = { ...school };
                  this.generateForm();
                  this.uploadedFile = null;
                  this.logoInput.nativeElement.value = "";
                }
              )
            )
            .subscribe();
        } else {
          if (this.uploadedFile) {
            this.schoolApi
              .uploadLogo(this.uploadedFile, this.schoolId)
              .subscribe((_) => {
                this.uploadedFile = null;
                this.logoInput.nativeElement.value = "";
                this.inModif = false;
              });
          }
          if (this.schoolForm.dirty) {
            this.createTask();
          }
        }
      } else {
        // on creer la nouvelle école, car on est sur le mode creation
        this.schoolApi
          .addSchool(this.mySchool)
          .pipe(
            switchMap(
              (data) => {
                if (this.uploadedFile) {
                  return this.schoolApi.uploadLogo(
                    this.uploadedFile,
                    data.ecoleInfo.ecoleId
                  );
                } else {
                  return of(null);
                }
              },
              (school, _) => {
                this.school = school;
                this.uploadedFile = null;
                this.logoInput.nativeElement.value = "";
              }
            )
          )
          .subscribe((_) => {
            this.router.navigate(["/ecoles"]);
          });
      }
      this.disabled = !this.disabled;
      this.inModif = false;
    }
  }

  createModifiedSchool() {
    let type = null;
    let reseauEcole = null;
    let habilitations: number = 0;

    this.schoolForm.value.habilitations.forEach((val) => {
      habilitations += val.habilitationId;
    });

    if (this.schoolForm.value.type[0]) {
      type = this.schoolForm.value.type[0].id;
    }
    if (this.schoolForm.value.reseauEcole[0]) {
      reseauEcole = this.schoolForm.value.reseauEcole[0].id;
    }
    this.mySchool = {
      ecoleInfo: {
        reference: this.schoolForm.value.reference,
        codeUai: this.schoolForm.value.codeUai,
        nom1: this.schoolForm.value.nom1,
        nom2: this.schoolForm.value.nom2,
        siret: this.schoolForm.value.siret
          ? this.schoolForm.value.siret.replace(/\s/g, "")
          : null,
        sigle: this.schoolForm.value.sigle,
        type: type,
        reseauEcole: reseauEcole,
        habilitations: habilitations,
        ecoleId: this.schoolForm.value.ecoleId,
        recuNbr: Number(this.schoolForm.value.recuNbr),
      },
      adresse: {
        adresse1: this.schoolForm.value.adresse1,
        adresse2: this.schoolForm.value.adresse2,
        adresseId: this.addressId,
        ville: this.schoolForm.value.ville,
        codePostal: this.schoolForm.value.codePostal,
        cedex: this.schoolForm.value.cedex,
        regionNom: this.schoolForm.value.regionNom,
        contactPrenom: this.schoolForm.value.contactPrenom,
        contactNom: this.schoolForm.value.contactNom,
        email: this.schoolForm.value.email,
        telephone: this.schoolForm.value.telephone,
        fax: this.schoolForm.value.fax,
        webSite: this.schoolForm.value.website,
        regionId: this.school.adresse.regionId,
      },
      ibanList: this.school.ibanList,
      signatureList: this.school.signatureList,
    };
  }

  createIban(): void {
    const dialogRef = this.dialog.open(PopinIbanComponent, {
      width: "630px",
      data: { mode: POPIN_MODS.CREATION, schoolId: this.schoolId },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((val) => {
        if (val) this.school.ibanList = val;
      });
  }

  editIban(rib): void {
    const dialogRef = this.dialog.open(PopinIbanComponent, {
      width: "630px",
      data: { mode: POPIN_MODS.EDIT, schoolId: this.schoolId, iban: rib },
    });

    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.school.ibanList = val;
      }
    });
  }

  validateIban(rib: IEcoleIban, index: number): void {
    this.ibanService.validateIban(rib.ibanId).subscribe(
      (data) => {
        this.dialog.open(GenericPopinComponent, {
          width: "630px",
          data: {
            mode: POPIN_MODS.MESSAGE,
            title: this.translate.instant("COMMON.POPINS.IMPORTSUCCESS"),
            bodyComponent: PopinErrorComponent,
            data: "Validation réussie",
          },
        });
        this.ibanService
          .getIbans(this.schoolId)
          .subscribe((val: IEcoleIban[]) => {
            if (val) {
              this.school.ibanList = val;
            }
          });
      },
      (err) => {
        if (err.status === 400) {
          this.dialog.open(GenericPopinComponent, {
            width: "630px",
            data: {
              mode: POPIN_MODS.MESSAGE,
              title: this.translate.instant("COMMON.POPINS.ERROR_TITLE"),
              bodyComponent: PopinErrorComponent,
              data:
                "Une erreur est survenue lors de la validation de l'iban : " +
                err.error.message,
            },
          });
        } else {
          this.dialog.open(GenericPopinComponent, {
            width: "630px",
            data: {
              mode: POPIN_MODS.MESSAGE,
              title: this.translate.instant("COMMON.POPINS.ERROR_TITLE"),
              bodyComponent: PopinErrorComponent,
              data: "Une erreur est survenue lors de la validation de l'iban",
            },
          });
        }
      }
    );
  }

  ngOnDestroy() {
    this._unsubscriber$.next();
    this._unsubscriber$.complete();
  }

  downloadIban(rib: IEcoleIban) {
    if (rib.fichierIban) {
      var fileExtension = rib.fichierIban.substring(
        rib.fichierIban.lastIndexOf(".")
      );
      this.ibanService
        .downloadIban(this.schoolId, rib.ibanId)
        .subscribe((data) => {
          var blob = new Blob([data]);
          this.downloadLink = URL.createObjectURL(blob);

          var a = document.createElement("a");
          a.href = this.downloadLink;
          a.download = this.school.ecoleInfo.nom1 + "-iban" + fileExtension;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
    }
  }

  downloadSignature(signature: Signature) {
    if (signature.signature) {
      //var fileExtension = signature.signature.substring(signature.signature.lastIndexOf("."));
      this.schoolApi
        .downloadSignature(this.schoolId, signature.signatureId)
        .subscribe((data) => {
          //verifier si ça ouvre quand meme les png
          var blob = new Blob([data], { type: "image/png" });
          var imageLink = URL.createObjectURL(blob);
          window.open(imageLink);
        });
    }
  }

  newFileInput(files: File[]) {
    if (this.checkFileFormat(files[0]) && this.checkFileSize(files[0])) {
      this.uploadedFile = files[0];
      this.displayUploadedFile(files[0]);
      this.errorLogo = false;
    } else {
      this.errorLogo = true;
    }
  }

  deleteSignature(signature: Signature) {
    this.schoolApi
      .deleteSignature(signature.signatureId)
      .pipe(
        switchMap((_) => {
          return this.schoolApi.getSignatureList(this.schoolId);
        })
      )
      .subscribe((data) => {
        this.school.signatureList = <Signature[]>data;
      });
  }

  deleteFile() {
    this.uploadedFile = undefined;
    this.school.ecoleInfo.logoImage = undefined;
    this.logoInput.nativeElement.value = "";
  }

  private createTask() {
    const taskEcoleData = { after: {} };
    Object.keys(this.schoolForm.controls).forEach((key) => {
      let currentControl = this.schoolForm.controls[key];
      if (currentControl.dirty) {
        taskEcoleData["after"][key] = currentControl.value;
        if (key === "type" || key === "reseauEcole") {
          taskEcoleData["after"][key] = currentControl.value
            .map((t) => t.id)
            .reduce((a, b) => a + b, 0);
        }
        if (key === "recuNbr") {
          taskEcoleData["after"][key] = Number(currentControl.value);
        }
        if (key === "siret") {
          taskEcoleData["after"][key] = currentControl.value.replace(/\s/g, "");
        }
      }
      if (currentControl.touched && key === "habilitations") {
        taskEcoleData["after"][key] = currentControl.value
          .map((h) => h.id)
          .reduce((a, b) => a + b, 0);
      }
    });
    this.taskboardService
      .createTask({
        taskType: TYPE_TACHE.ECOLE,
        statut: TASK_STATUS_ID.WAITING,
        targetId: this.school.ecoleInfo.ecoleId,
        data: JSON.stringify(taskEcoleData),
        ecoleId: this.mySchool.ecoleInfo.ecoleId,
      })
      .subscribe((_) => {
        this.newTaskWaiting.emit();
      });
  }

  createSignature(): void {
    const dialogRef = this.dialog.open(PopinSignatureComponent, {
      width: "630px",
      data: { mode: POPIN_MODS.CREATION, schoolId: this.schoolId },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscriber$))
      .subscribe((val) => {
        if (val) {
          this.school.signatureList = val;
          this.school.signatureList.forEach(
            (sign) => (sign.nomfichier = "Signature_" + sign.nom)
          );
        }
      });
  }

  editSignature(sign: Signature) {
    const dialogRef = this.dialog.open(PopinSignatureComponent, {
      width: "630px",
      data: { mode: POPIN_MODS.EDIT, schoolId: this.schoolId, signature: sign },
    });

    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.school.signatureList = val;
        this.school.signatureList.forEach(
          (sign) => (sign.nomfichier = "Signature_" + sign.nom)
        );
      }
    });
  }

  modifySchoolSheet() {
    this.disabled = !this.disabled;
    this.inModif = true;
  }
}
