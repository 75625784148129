import { Component, OnInit, ComponentFactoryResolver, ViewContainerRef, ViewChild, Inject, HostListener } from '@angular/core';
import { PopinDiplomaComponent } from '../popin-diploma/popin-diploma.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { POPIN_MODS } from 'src/enums/utils.enum';
import { subscribeOn, takeUntil } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { IEcoleDiplome } from 'src/models/ecole.model';

@Component({
	selector: 'app-generic-popin',
	templateUrl: './generic-popin.component.html',
	styleUrls: ['./generic-popin.component.scss']
})
export class GenericPopinComponent implements OnInit {

	@ViewChild('vc', { static: true, read: ViewContainerRef }) vc: ViewContainerRef;

	isFormValid: boolean = false;
	formData: any;
	_unsubscriber$: Subject<void> = new Subject();
	singleButton: boolean = false;

	constructor(private componentFactoryResolver: ComponentFactoryResolver,
		public dialogRef: MatDialogRef<GenericPopinComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { mode: POPIN_MODS, title: string, bodyComponent: any, data: any }) {
	}

	ngOnInit() {
		const factory = this.componentFactoryResolver.resolveComponentFactory(this.data.bodyComponent);
		let ref = this.vc.createComponent(factory);

		if (this.data.data.montantPromis) {
			this.data.data.total = this.data.data.montantPromis;
		}
		ref.instance['data'] = this.data.data;
		ref.instance['mode'] = this.data.mode;

		switch (this.data.mode) {
			case POPIN_MODS.CREATION:
			case POPIN_MODS.EDIT:
				ref.instance['form'].valueChanges.pipe(takeUntil(this._unsubscriber$)).subscribe(val => {
					this.formData = ref.instance['form'].getRawValue();
					this.isFormValid = (<FormGroup>ref.instance['form']).valid;
				});

				break;
			case POPIN_MODS.CONSULT:
				ref.instance['form'].disable();
				break;
			case POPIN_MODS.MESSAGE:
				this.singleButton = true;
		}
	}

	onSubmit() {
		let data;
		if (this.formData.banque && this.formData.banque[0].id) {
			data = { ...this.formData, banque: this.formData.banque[0].id }
		} else {
			data = this.formData;
		}
		this.dialogRef.close(data);
	}


	ngOnDestroy() {
		this._unsubscriber$.next();
		this._unsubscriber$.complete();
	}

}
