import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SCHOOL_FILTER_TYPE } from '../../../../enums/ecole.enum';


@Component({
	selector: 'app-filter-tag',
	templateUrl: './filter-tag.component.html',
	styleUrls: ['./filter-tag.component.scss']
})
export class FilterTagComponent implements OnInit {

	@Input() filter: any;

	@Output() delete: EventEmitter<{ type: SCHOOL_FILTER_TYPE, filter: { id: number, name: string } }> = new EventEmitter();

	constructor() { }

	ngOnInit() {
	}

	deleteFilter() {
		this.delete.emit({ ...this.filter, type: this.filter.type })
	}

}
