import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { PopinAddEcolesComponent } from '../popin-add-ecoles/popin-add-ecoles.component';


@Component({
	selector: 'app-popin-confirmation',
	templateUrl: './popin-confirmation.component.html',
	styleUrls: ['./popin-confirmation.component.scss']
})
export class PopinConfirmationComponent implements OnInit {

	
	constructor(
		public dialogRef: MatDialogRef<PopinAddEcolesComponent>,
	) {
	}

	ngOnInit() {
	
	}

	onSubmit() {
		this.dialogRef.close(true);
	}

}
