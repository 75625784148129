import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentPromise, Payment } from 'src/models/ecole.model';
import { Subject } from 'rxjs';
import { IBank } from 'src/models/static.model';
import { checkIfCheckNbr } from 'src/utils/custom-validators';
import { takeUntil } from 'rxjs/operators';



@Component({
	selector: 'app-payments-promise-item',
	templateUrl: './payments-promise-item.component.html',
	styleUrls: ['./payments-promise-item.component.scss']
})
export class PaymentsPromiseItemComponent implements OnInit {

	@Input() set promise(promise: PaymentPromise) {
		if (promise) {
			this._promise = promise;
			const paymentRemaing = this.promise.montant - this.promise.montantVerse;
			this.paymentForm = this.formBuilder.group({
				date: ['', Validators.required],
				modePaiement: [1, [Validators.required]],
				banque: ['', [Validators.required]],
				complementInfos: ['', [Validators.required, checkIfCheckNbr]],
				montant: ['', [Validators.max(paymentRemaing), Validators.min(0), Validators.required, Validators.pattern(/^[0-9]+([\.,][0-9]{1,2})?$/)]],
			});

			this.modePaiementField.valueChanges.pipe(takeUntil(this._unsubscriber$)).subscribe(val => {
				this.checkIfInfoComplMandatory(val);

				if (parseInt(val) !== this.modePaiementId) {
					this.modePaiementId = parseInt(val);
				};
				if (val === '1') {
					this.banqueField.setValidators([Validators.required]);
				} else {
					this.banqueField.setValidators([]);
				}
				this.banqueField.updateValueAndValidity();
			});

			this.montantField.valueChanges.pipe(takeUntil(this._unsubscriber$)).subscribe(val => {
				if (val) {
					this.isPaymentLowerThanExpectedAmount = val < paymentRemaing;
				}
			})
		}

	};
	@Input() bankList: IBank[] = [];
	@Input() set modePaiement(val: number) {
		if (val) {
			this.modePaiementId = val;
			this.modePaiementField.setValue(val);
			this.checkIfInfoComplMandatory(val);
			this.modePaiementField.disable();
		} else {
			this.modePaiementId = 1;
		}
	};
	@Input() set date(val: string) {
		if (val) {
			this._date = val;
			this.dateField.setValue(val);
			this.dateField.disable();
		}
	};
	get date() {
		return this._date;
	}

	@Input() isSendingPayment: boolean;
	@Input() errorMessage: string;

	@Output() paymentValidated: EventEmitter<Payment> = new EventEmitter();
	@Output() selectedPayementMethod: EventEmitter<number> = new EventEmitter();
	@Output() selectedDate: EventEmitter<string> = new EventEmitter();

	get modePaiementField() { return this.paymentForm.get('modePaiement') }
	get infoComplField() { return this.paymentForm.get('complementInfos') }
	get banqueField() { return this.paymentForm.get('banque') }
	get dateField() { return this.paymentForm.get('date') }
	get montantField() { return this.paymentForm.get('montant') }
	get promise(): PaymentPromise { return this._promise };
	modePaiementId: number;

	isPaymentLowerThanExpectedAmount: boolean;

	todayDate: Date = new Date();
	paymentForm: FormGroup;
	private _promise: PaymentPromise;
	private _date: string;

	private _unsubscriber$: Subject<void> = new Subject();

	constructor(private formBuilder: FormBuilder) { }

	ngOnInit() {
	}

	validatePayment() {
		this.paymentValidated.emit({ ...this.paymentForm.getRawValue(), banque: parseInt(this.paymentForm.getRawValue().banque), promesseId: this._promise.promesseId });
		this.selectedPayementMethod.emit(this.modePaiementId);
		this.selectedDate.emit(this.dateField.value);
	}

	ngOnDestroy() {
		this._unsubscriber$.next();
		this._unsubscriber$.complete();

	}

	checkIfInfoComplMandatory(val) {
		if (`${val}` === '1') {
			this.infoComplField.setValidators([Validators.required, checkIfCheckNbr])
		} else {
			this.infoComplField.clearValidators();
			this.infoComplField.setValidators([checkIfCheckNbr]);
		}
		this.infoComplField.updateValueAndValidity();
	}

}
