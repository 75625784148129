import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { IVMSchoolFilters } from '../../../../models/ecole.model';
import { SchoolFunctService } from '../../../services/functional-services/school-service/school-funct.service';
import { TASKBOARDTODO_FILTER_TYPE } from '../../../../enums/ecole.enum';
import { StaticService } from '../../../services/functional-services/static-service/static-service';

@Component({
	selector: 'app-taskboard-filter-todo',
	templateUrl: './taskboard-filter-todo.component.html',
	styleUrls: ['./taskboard-filter-todo.component.scss']
})
export class TaskboardFilterTodoComponent implements OnInit, OnDestroy {

	@Output() getFilteredResult: EventEmitter<void> = new EventEmitter();

	taskboardFilterTodoForm: FormGroup;
	currentFilters: IVMSchoolFilters;

	type = [
		{ id: 1, nom: "Compte" },
		{ id: 3, nom: "Iban" },
		{ id: 4, nom: "Ecole" },
		{ id: 5, nom: "Formule" },
	]

	typeControl = new FormControl();

	private _unsubscriber$ = new Subject();

	constructor(private fb: FormBuilder, private schoolFunctService: SchoolFunctService, private staticService: StaticService) {
		this.taskboardFilterTodoForm = fb.group({
			type: this.typeControl,
		})

	}

	ngOnInit() {
		this.updateFilterList();

		this.schoolFunctService.deleteEvent$.subscribe(_ => {
			this.currentFilters = this.schoolFunctService.getTaskboardFiltersTodoAsArray();
		})
	}

	submit(event) {
		event.preventDefault();
		this.schoolFunctService.addTaskboardFiltersTodo(this.taskboardFilterTodoForm.value);
		this.updateFilterList();
		this.getFilteredResult.emit()
	}

	reset(event) {
		event.preventDefault();
		this.schoolFunctService.deleteAllTaskboardFilterTodo()
		this.taskboardFilterTodoForm.get('type').reset();
	}

	deleteFilter(filterTodelete: { id: number, name: string, type: TASKBOARDTODO_FILTER_TYPE }) {
		this.schoolFunctService.deleteTaskboardFilterTodo(filterTodelete);
		this.updateFilterList();
	}

	updateFilterList() {
		//update Tag list si on a deja des filtres selectionnés
		this.currentFilters = this.schoolFunctService.getTaskboardFiltersTodoAsArray();


		//update 
		this.typeControl.setValue(this.schoolFunctService.taskboardCurrentFiltersTodo[TASKBOARDTODO_FILTER_TYPE.TYPE]);
	}

	ngOnDestroy() {
		this._unsubscriber$.next();
		this._unsubscriber$.complete();
	}
}

