import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-refused-task-confirmation",
  templateUrl: "./refused-task-confirmation.component.html",
  styleUrls: ["./refused-task-confirmation.component.scss"],
})
export class RefusedTaskConfirmationComponent implements OnInit {
  message: FormControl = new FormControl("");

  constructor(
    private dialogRef: MatDialogRef<RefusedTaskConfirmationComponent>
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.dialogRef.close(this.message.value);
  }
}
