import { Component, OnInit } from '@angular/core';
import { IEcole } from '../../../../models/ecole.model';

const schoolInit: IEcole = {
	ecoleInfo: {
		reference: '',
		codeUai: '',
		sigle: '',
		nom1: '',
		nom2: '',
		siret: '',
		ecoleId: 0,
		logo: '',
		recuNbr: 1
	},
	adresse: {
		adresse1: '',
		adresse2: '',
		adresseId: 0,
		ville: '',
		codePostal: 0,
		cedex: '',
		contactNom: '',
		contactPrenom: '',
		email: '',
		telephone: '',
		fax: '',
		regionNom: '',
		regionId: ''
	},
	ibanList: [],
	signatureList: []
};

@Component({
	selector: 'app-school-add',
	templateUrl: './school-add.component.html',
	styleUrls: ['./school-add.component.scss']
})

export class SchoolAddComponent implements OnInit {

	school: IEcole = schoolInit;

	constructor() { }

	ngOnInit() {
	}

}
