import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { IVMSchoolFilters } from "../../../../models/ecole.model";
import { SchoolFunctService } from "../../../services/functional-services/school-service/school-funct.service";
import { USER_FILTER_TYPE } from "../../../../enums/ecole.enum";
import { StaticService } from "../../../services/functional-services/static-service/static-service";
import { USER_PROFILE } from "src/enums/users.enum";

@Component({
  selector: "app-user-filter-box",
  templateUrl: "./user-filter-box.component.html",
  styleUrls: ["./user-filter-box.component.scss"],
})
export class UserFilterBoxComponent implements OnInit, OnDestroy {
  @Output() getFilteredResult: EventEmitter<void> = new EventEmitter();

  userFilterForm: FormGroup;
  currentFilters: IVMSchoolFilters;
  subsc;
  profil = [
    { id: USER_PROFILE.ADMIN, nom: "Admin" },
    { id: USER_PROFILE.N1, nom: "N1" },
    { id: USER_PROFILE.N2, nom: "N2" },
    { id: USER_PROFILE.N3, nom: "N3" },
    { id: USER_PROFILE.N4, nom: "N4" },
    { id: USER_PROFILE.SUPERVISEUR, nom: "Superviseur" },
    { id: USER_PROFILE.SUPERVISEUR_PLUS, nom: "SuperviseurPlus" },
    { id: USER_PROFILE.HOTLINER, nom: "Hotliner" },
    { id: USER_PROFILE.OPERATOR, nom: "Opérateur" },
  ];

  uaiControl = new FormControl("");
  referenceControl = new FormControl("");
  profilControl = new FormControl();

  private _unsubscriber$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private schoolFunctService: SchoolFunctService,
    private staticService: StaticService
  ) {
    this.userFilterForm = fb.group({
      code_uai: this.uaiControl,
      reference: this.referenceControl,
      profil: this.profilControl,
    });
  }

  ngOnInit() {
    this.updateFilterList();

    this.schoolFunctService.deleteEvent$.subscribe((_) => {
      this.currentFilters = this.schoolFunctService.getUserFiltersAsArray();
    });
  }

  submit(event) {
    event.preventDefault();
    this.schoolFunctService.addUserFilters(this.userFilterForm.value);
    this.updateFilterList();
    this.getFilteredResult.emit();
    this.userFilterForm.get("code_uai").reset("");
    this.userFilterForm.get("reference").reset("");
  }

  reset(event) {
    event.preventDefault();
    this.schoolFunctService.deleteAllUserFilter();
    this.userFilterForm.get("code_uai").reset("");
    this.userFilterForm.get("reference").reset("");
    this.userFilterForm.get("profil").reset();
  }

  deleteFilter(filterTodelete: {
    id: number;
    name: string;
    type: USER_FILTER_TYPE;
  }) {
    this.schoolFunctService.deleteUserFilter(filterTodelete);
    this.updateFilterList();
  }

  updateFilterList() {
    //update Tag list si on a deja des filtres selectionnés
    this.currentFilters = this.schoolFunctService.getUserFiltersAsArray();

    //update
    this.uaiControl.setValue(
      this.schoolFunctService.userCurrentFilters[USER_FILTER_TYPE.CODE_UAI]
    );
    this.referenceControl.setValue(
      this.schoolFunctService.userCurrentFilters[USER_FILTER_TYPE.REFERENCE]
    );
    this.profilControl.setValue(
      this.schoolFunctService.userCurrentFilters[USER_FILTER_TYPE.PROFIL]
    );
  }

  ngOnDestroy() {
    this._unsubscriber$.next();
    this._unsubscriber$.complete();
  }
}
