import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    ComponentFactoryResolver,
    NgZone,
    ChangeDetectorRef,
    ApplicationRef,
    ElementRef,
    Renderer,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { SORTENUM, ETAT, ETAT_VERSEMENT } from '../../../../enums/utils.enum';
import { IEcoleListDisplay, IEcoleVersement } from '../../../../models/ecole.model';
import { takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { LIST_TYPE } from '../../../../enums/lists.type.enum';
import { TableService } from '../../../../app/services/functional-services/table-service/table-service';
import { GroupApi } from 'src/app/api/groupApi';
import { UserApi } from 'src/app/api/userApi';
import { PopinConfirmationComponent } from '../../popins/popin-confirmation/popin-confirmation.component';
import { Subject, Subscription, BehaviorSubject, ReplaySubject } from 'rxjs';
import { AccountService } from 'src/app/api/services/account.service';
import { USER_ROLE } from 'src/enums/users.enum';
import { tick, fakeAsync } from '@angular/core/testing';
import { detectIE } from 'src/utils/custom-functions';
import { UserRights } from 'src/models/user.model';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
    tableEcole: any;

    @Input() userId?: any;
    @Input() groupId?: any;
    @Input() totalCount: number;
    @Input() usePagination: boolean;
    @Input() parentComponent: LIST_TYPE;
    @Input() dataClicked?: any;
    @Input() resetPaginator: boolean;

    @Input() set versementsClickedResult(versements: IEcoleVersement[][]) {
        if (versements && versements.length === 0) {
            this._versementsClickedResult = versements;
        }
    }

    get versementsClickedResult() {
        return this._versementsClickedResult;
    }

    private _versementsClickedResult: IEcoleVersement[][] = [];

    @ViewChild(MatPaginator, { static: false }) paginatorTop: MatPaginator;
    @ViewChild(MatPaginator, { static: false }) paginatorBottom: MatPaginator;
    @ViewChild('tableFun', { static: false }) tableFun: ElementRef;
    @ViewChild(MatSort, { static: false }) set sort(sort: MatSort) {
        if (sort) {
            if (this.listType.VERSEMENT_RECU_LIST === this.parentComponent) {
                this.VersementsClearAllRow();
            }
            if (this.sortChangeSubs && !this.sortChangeSubs.closed) {
                this.sortChangeSubs.unsubscribe();
            }
            this.sortChangeSubs = sort.sortChange.subscribe((val) => {
                this.sortChange.emit({
                    column: val.active,
                    sortDirection: val.direction,
                });
            });
        }
    }
    get sort(): MatSort {
        return this._sort;
    }
    private _sort;

    @Input() set dataSource(data: MatTableDataSource<IEcoleListDisplay>) {
        if (data) {
            //only for edge
            if (this.isBrowserEdge) {
                this.fixMatTableForEdge('99%');
            }

            this._dataSource = data;
            if (this.resetPaginator && this.paginatorTop && this.paginatorBottom) {
                this.paginatorBottom.firstPage();
                this.paginatorTop.firstPage();
            }
            this.dataSource.sort = this.sort;

            if (this.parentComponent === this.listType.VERSEMENT_RECU_LIST) {
                const result = [];
                this.versementsClicked = [];
                // const mailArray: string[] = [];
                (this._dataSource as any).forEach((el) => {
                    if (!this.versementsClicked.includes(el.mail)) {
                        this.versementsClicked.push(el);
                    }
                });
                this.selectOurVersements();
            }

            const tempArray = [];
            if (this.parentComponent !== this.listType.USER_LIST && this.parentComponent !== this.listType.GROUP_LIST) {
                this.tableEcole = data;
                if (this.dataClicked !== undefined) {
                    this.selectOurSchool();
                }
            } else {
                for (let i = 0; i < data['length']; i++) {
                    tempArray.push(data[i]);
                }
            }
            if (this.parentComponent === this.listType.GROUP_LIST) {
                this.tableEcole = tempArray.map((group) => {
                    const tempUser = {
                        ...group,
                        ecoles: group.ecoles.ecoles.map((ecole) => ecole.name).join(', '),
                    };
                    return tempUser;
                });
            }
            if (this.parentComponent === this.listType.USER_LIST) {
                this.tableEcole = tempArray.map((user) => {
                    const tempUser = {
                        ...user,
                        ecoles: user.ecoles.map((ecole) => ecole.name).join(', '),
                        equipe: user.equipe.map((equipeId) => equipeId.groupName).join(', '),
                    };
                    return tempUser;
                });
            }

            this.cdr.markForCheck();
            this.cdr.detectChanges();
            setTimeout(() => {
                if (this.isBrowserEdge) {
                    this.fixMatTableForEdge('100%');
                }
            }, 1000);
        }
    }
    get dataSource(): MatTableDataSource<IEcoleListDisplay> {
        return this._dataSource;
    }

    @Input() tableProperties: string[];
    @Input() selectable: boolean;
    @Input() downloadable: boolean;

    @Output() newSchoolClicked? = new EventEmitter();
    @Output() sortChange: EventEmitter<{
        column: string;
        sortDirection: SORTENUM;
    }> = new EventEmitter();
    @Output() pageChange: EventEmitter<{
        pageIndex: number;
        pageSize: number;
    }> = new EventEmitter();
    @Output() dataClickedResultChange? = new EventEmitter();

    @Output() clickOnEntreprise = new EventEmitter();
    allClickedRow: any;

    private _unsubscriber$: Subject<void> = new Subject();

    userRoles = USER_ROLE;

    versementsClicked = [];

    inGroup: number;
    selection = new SelectionModel<any>(true, []);
    displayedColumns: string[];
    blueSidebar = false;
    elemSelectBlueSidebar: any;
    private _dataSource: MatTableDataSource<IEcoleListDisplay>;
    schoolId: number;
    etat = ETAT;
    etatVersement = ETAT_VERSEMENT;

    paginatorOptions: number[] = [5, 10, 50, 100];
    paginatorDefaultSize: number = 50;
    sortChangeSubs: Subscription;
    currentPageIndex: number = 0;
    currentPageSize: number = this.paginatorDefaultSize;

    isAdmin: boolean;
    currentUserRights: UserRights;
    isBrowserEdge: boolean;

    listType = LIST_TYPE;

    toolTipPosition: string = 'above';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private tableService: TableService,
        private groupApi: GroupApi,
        private userApi: UserApi,
        private accountService: AccountService,
        private cdr: ChangeDetectorRef
    ) {
        this.schoolId = +this.route.snapshot.paramMap.get('id');
    }

    ngOnInit() {
        this.setDisplayedColumns();

        this.isBrowserEdge = detectIE() >= 12;

        if (this.listType.ADD_SCHOOL_LIST === this.parentComponent) {
            this.paginatorDefaultSize = 5;
            this.paginatorOptions = [5];
        }
        if (
            this.listType.VERSEMENT_RECU_LIST === this.parentComponent ||
            this.listType.VERSEMENT_LIST === this.parentComponent
        ) {
            this.paginatorOptions = [10, 50, 100, 500, 1000];
        }

        this.accountService.currentUserRights$.pipe(takeUntil(this._unsubscriber$)).subscribe((data) => {
            this.currentUserRights = data;
            if (data.role === USER_ROLE.ADMIN || data.role === USER_ROLE.HOTLINER) {
                this.isAdmin = true;
            } else {
                this.isAdmin = false;
            }
        });
    }

    // set the displayed columns for the table
    setDisplayedColumns() {
        // add properties and options
        if (
            this.parentComponent === this.listType.VERSEMENT_LIST ||
            this.parentComponent === this.listType.BUSINESS_LIST
        ) {
            this.displayedColumns = [...this.tableProperties, 'download', 'options'];
        } else if (this.parentComponent === this.listType.ADD_SCHOOL_LIST) {
            this.displayedColumns = [...this.tableProperties];
        } else if (this.parentComponent === this.listType.DUPLICATE_SCHOOL_LIST) {
            this.displayedColumns = [...this.tableProperties, 'delete'];
        } else {
            this.displayedColumns = [...this.tableProperties, 'edit', 'options'];
        }
        // add the checkbox column if the rows are selectable
        if (this.selectable) {
            this.displayedColumns.unshift('select');
        }
        // add the download option if exists
        if (this.downloadable) {
            this.displayedColumns.push('download');
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.tableEcole.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.clearSelectedRow()
            : this.tableEcole.data.forEach((element) => {
                  this.selection.select(element);
                  let here = -1;
                  for (let i = 0; i < this.dataClicked.data.data.length; i++) {
                      if (element.schoolId === this.dataClicked.data.data[i].schoolId) {
                          here = 1;
                      }
                  }
                  if (here !== 1) {
                      this.dataClicked.data.data.unshift(element);
                  }
              });
    }

    clearSelectedRow() {
        this.selection.clear();
        this.tableEcole.data.forEach((element) => {
            let index = -1;
            for (let i = 0; i < this.dataClicked.data.data.length; i++) {
                if (this.dataClicked.data.data[i].schoolId === element.schoolId) {
                    index = i;
                }
            }
            if (index !== -1) {
                this.dataClicked.data.data.splice(index, 1);
            }
        });
    }

    selectOurSchool() {
        this.tableEcole.data.map((element) => {
            for (let i = 0; i < this.dataClicked.data.data.length; i++) {
                if (element.schoolId === this.dataClicked.data.data[i].schoolId) {
                    this.selection.select(element);
                }
            }
        });
    }

    checkDataClicked(event, element) {
        if (event.checked === true) {
            this.dataClicked.data.data.unshift(element);
        } else {
            let index = -1;
            for (let i = 0; i < this.dataClicked.data.data.length; i++) {
                if (this.dataClicked.data.data[i].schoolId === element.schoolId) {
                    index = i;
                }
            }
            if (index !== -1) {
                this.dataClicked.data.data.splice(index, 1);
            }
        }
        this.newSchoolClicked.emit(this.dataClicked.data);
    }

    ligneClicked(event, element) {
        let index = -1;
        for (let i = 0; i < this.dataClicked.data.data.length; i++) {
            if (this.dataClicked.data.data[i].schoolId === element.schoolId) {
                index = i;
            }
        }
        if (index !== -1) {
            this.selection.deselect(element);
            this.dataClicked.data.data.splice(index, 1);
        }
        if (index === -1) {
            this.selection.select(element);
            this.dataClicked.data.data.unshift(element);
        }
        this.newSchoolClicked.emit(this.dataClicked.data);
    }

    // versement recu select case

    selectOurVersements() {
        (this._dataSource as any).forEach((element) => {
            this.versementsClicked.forEach((mail, index) => {
                if (mail.mail === element.mail && mail.versementId === element.versementId) {
                    if (this.versementsClickedResult[index] !== undefined) {
                        this.versementsClickedResult[index].forEach((elem, i) => {
                            if (elem.versementId === element.versementId) {
                                this.selection.select(element);
                            }
                        });
                    }
                }
            });
        });
        this.dataClickedResultChange.emit(this.versementsClickedResult);
    }

    masterToggleVersements() {
        this.VersementsIsAllSelected() ? this.VersementsClearAllRow() : this.VersementsSelectAllRow();
    }

    VersementsSelectAllRow() {
        (this._dataSource as any).forEach((element) => {
            this.selection.select(element);
            let here = -1;
            this.versementsClicked.forEach((mail, index) => {
                if (mail.mail === element.mail && mail.versementId === element.versementId) {
                    if (this.versementsClickedResult[index] !== undefined) {
                        this.versementsClickedResult[index].forEach((elem, i) => {
                            if (elem === element) {
                                here = 1;
                            }
                        });
                    }
                }
            });
            if (here !== 1) {
                this.versementsClicked.forEach((mail, index) => {
                    if (mail.mail === element.mail && mail.versementId === element.versementId) {
                        const newClicked = (this._dataSource as any)
                            .map((v) => {
                                if (v === element) {
                                    return element;
                                }
                            })
                            .filter((x) => x !== undefined)[0];
                        if (this.versementsClickedResult[index]) {
                            this.versementsClickedResult[index].push(newClicked);
                        } else {
                            this.versementsClickedResult[index] = [];
                            this.versementsClickedResult[index].push(newClicked);
                        }
                    }
                });
                this.dataClickedResultChange.emit(this.versementsClickedResult);
            }
        });
    }

    VersementsClearAllRow() {
        this.versementsClickedResult = [];
        this.selection.clear();
        this.dataClickedResultChange.emit(this.versementsClickedResult);
    }

    VersementsclearSelectedRow() {
        (this._dataSource as any).forEach((element) => {
            let indexElem = -1;
            let iElem = -1;
            this.versementsClicked.forEach((mail, index) => {
                if (mail.mail === element.mail && mail.versementId === element.versementId) {
                    indexElem = index;
                    if (this.versementsClickedResult[indexElem] !== undefined) {
                        this.versementsClickedResult[indexElem].forEach((elem, i) => {
                            if (elem.versementId === element.versementId) {
                                iElem = i;
                            }
                        });
                    }
                }
            });
            if (indexElem !== -1 && iElem !== -1) {
                this.versementsClickedResult[indexElem].splice(iElem, 1);
            }
        });
        this.selection.clear();
        this.dataClickedResultChange.emit(this.versementsClickedResult);
    }

    VersementsIsAllSelected() {
        let numberTotal = 0;
        (this._dataSource as any).forEach((element) => {
            let here = -1;
            this.versementsClicked.forEach((mail, index) => {
                if (mail.mail === element.mail && mail.versementId === element.versementId) {
                    if (this.versementsClickedResult[index] !== undefined) {
                        this.versementsClickedResult[index].forEach((elem, i) => {
                            if (elem === element) {
                                numberTotal++;
                            }
                        });
                    }
                }
            });
        });

        const numRows = (this._dataSource as any).length;
        return numberTotal >= numRows;
    }

    VersementsCheckDataClicked(event, element) {
        if (event.checked === true) {
            this.versementsClicked.forEach((mail, index) => {
                if (mail.mail === element.mail && mail.versementId === element.versementId) {
                    const newClicked = (this._dataSource as any)
                        .map((v) => {
                            if (v.versementId === element.versementId) {
                                return element;
                            }
                        })
                        .filter((x) => x !== undefined)[0];
                    if (this.versementsClickedResult[index]) {
                        this.versementsClickedResult[index].push(newClicked);
                    } else {
                        this.versementsClickedResult[index] = [];
                        this.versementsClickedResult[index].push(newClicked);
                    }
                }
            });
        } else {
            let indexElem = -1;
            let iElem = -1;
            this.versementsClicked.forEach((mail, index) => {
                if (mail.mail === element.mail && mail.versementId === element.versementId) {
                    indexElem = index;
                    this.versementsClickedResult[indexElem].forEach((elem, i) => {
                        if (elem === element) {
                            iElem = i;
                        }
                    });
                }
            });
            if (indexElem !== -1 && iElem !== -1) {
                this.versementsClickedResult[indexElem].splice(iElem, 1);
            }
        }
        this.dataClickedResultChange.emit(this.versementsClickedResult);
    }

    updatePagination(event) {
        if (this.parentComponent === LIST_TYPE.ADD_SCHOOL_LIST) {
            this.pageChange.emit({ pageIndex: event.pageIndex, pageSize: 5 });
            this.currentPageIndex = event.pageIndex;
            this.currentPageSize = 5;
        } else {
            this.pageChange.emit({
                pageIndex: event.pageIndex,
                pageSize: event.pageSize,
            });
            this.currentPageIndex = event.pageIndex;
            this.currentPageSize = event.pageSize;
        }
    }

    editDialog(item, itemType: LIST_TYPE): void {
        switch (itemType) {
            case LIST_TYPE.DIPLOMA_LIST:
                this.tableService.editDiploma(item);
                break;
        }
    }

    actionSidebar(elem) {
        //if (this.currentUserRights.role === this.userRoles.N3 && elem.utilisateurCreation !== this.currentUserRights.userId) {
        //	//do nothing
        //} else {
        this.clickOnEntreprise.emit(elem);
        //}
    }

    goEditFicheSchool(elem) {
        this.router.navigate(['/ecole/' + elem.schoolId], {
            queryParams: { inModif: true, disabled: false },
        });
    }

    goEditFicheUser(elem) {
        this.router.navigate(['/utilisateur/' + elem.userId], {
            queryParams: { inModif: true, disabled: false },
        });
    }

    goEditFicheGroup(elem) {
        this.router.navigate(['/groupe/' + elem.groupId], {
            queryParams: { inModif: true, disabled: false },
        });
    }

    showElemD(elem) {}

    downloadRecu(versement) {
        this.tableService.downloadRecu(versement);
    }

    downloadRecuFromBusinessList(versement) {
        this.tableService.downloadRecu(versement);
    }

    deleteSchoolFromList(elem) {
        const dialogRef = this.dialog.open(PopinConfirmationComponent, {
            width: '430px',
            data: {
                // mode: POPIN_MODS.EDIT,
                // title: this.translate.instant('SCHOOL.GROUPES.ADD_SCHOOL'),
                bodyComponent: PopinConfirmationComponent,
            },
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this._unsubscriber$))
            .subscribe((val) => {
                if (val === true) {
                    let index = -1;
                    for (let i = 0; i < this.tableEcole.data.length; i++) {
                        if (this.tableEcole.data[i].schoolId === elem.schoolId) {
                            index = i;
                        }
                    }
                    if (index !== -1) {
                        this.tableEcole.data.splice(index, 1);
                    }

                    //
                    const sendNewValues = this.tableEcole.data.map((element) => element.schoolId);
                    if (this.groupId !== undefined) {
                        this.inGroup = 1;
                    } else {
                        this.inGroup = -1;
                    }
                    if (this.inGroup !== -1) {
                        this.groupApi.addSchools(this.groupId, sendNewValues).subscribe();
                    } else {
                        this.userApi.addSchools(this.userId, sendNewValues).subscribe();
                    }
                    this.newSchoolClicked.emit(this.tableEcole);
                }
            });
    }

    deleteUser(user) {
        let index = -1;
        for (let i = 0; i < this.tableEcole.length; i++) {
            if (this.tableEcole[i].userId === user.userId) {
                index = i;
            }
        }
        if (index !== -1) {
            this.tableEcole.splice(index, 1);
            this.tableEcole = [...this.tableEcole];
        }
        this.userApi.deleteUser(user.userId).subscribe();
    }

    deleteGroup(group) {
        let index = -1;
        for (let i = 0; i < this.tableEcole.length; i++) {
            if (this.tableEcole[i].groupId === group.groupId) {
                index = i;
            }
        }
        if (index !== -1) {
            this.tableEcole.splice(index, 1);
            this.tableEcole = [...this.tableEcole];
        }
        this.groupApi.deleteGroup(group.groupId).subscribe();
    }

    private fixMatTableForEdge(resize: string) {
        if (document.getElementById('dtable')) {
            document.getElementById('dtable').style.width = resize;
        }
        if (document.getElementsByTagName('thead').item(0)) {
            document.getElementsByTagName('thead').item(0).style.width = resize;
        }
        if (document.getElementsByClassName('mat-header-row').item(0)) {
            (document.getElementsByClassName('mat-header-row').item(0) as HTMLTableRowElement).style.width = resize;
        }
        for (let i = 0; i < document.getElementsByClassName('mat-header-cell').length; i++) {
            (document.getElementsByClassName('mat-header-cell').item(i) as HTMLTableRowElement).style.height = resize;
        }
        for (let i = 0; i < document.getElementsByClassName('mat-sort-header-button').length; i++) {
            (document
                .getElementsByClassName('mat-sort-header-button')
                .item(i) as HTMLTableRowElement).style.height = resize;
        }
        for (let i = 0; i < document.getElementsByClassName('mat-sort-header-sorted').length; i++) {
            (document
                .getElementsByClassName('mat-sort-header-sorted')
                .item(i) as HTMLTableRowElement).style.height = resize;
        }
    }

    buildTooltipString(input: string) {
        if (input) {
            var d = new Date(input);
            return 'Relancé le ' + d.toLocaleDateString();
        }
        return;
    }

    printDate(date) {
        if (date) {
            return formatDate(date, 'dd MMMM yyyy', 'fr');
        }
        return null;
    }

    printRelanceDate(date) {
        if (date) {
            return formatDate(date, 'dd MMMM yyyy', 'fr');
        }
        return null;
    }
}
