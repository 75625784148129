import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IEcoleVersement, IEcolePromesse } from "src/models/ecole.model";
import { EntrepriseInfos } from "src/models/entreprise.model";

@Component({
  selector: "app-fiche-entrerpise-versement",
  templateUrl: "./fiche-entrerpise-versement.component.html",
  styleUrls: ["./fiche-entrerpise-versement.component.scss"],
})
export class FicheEntrerpiseVersementComponent implements OnInit {
  @Input() set entrepriseVersementForm(form: FormGroup) {
    if (form) {
      this.telephone = form.get("telephone");
      this.email = form.get("email");
      this._entrepriseForm = form;
    }
  }

  @Input() loading;

  @Input() versement: IEcoleVersement;

  @Input() entreprise: EntrepriseInfos;

  @Output() submitForm: EventEmitter<void> = new EventEmitter();

  @Input() disabled;

  @Input() isAdmin;

  @Input() set inModif(val) {
    this._inModif = val;
    this.disabled = !val;
  }

  @Input() set open(val) {
    if (!val) {
      this._inModif = false;
      this.disabled = true;
    }
  }
  get entrepriseForm(): FormGroup {
    return this._entrepriseForm;
  }

  get inModif(): boolean {
    return this._inModif;
  }

  paymentMethod = [
    "Chèque",
    "Virement",
    "Espèce",
    "Prlvt Sepa",
    "Vrmt Sepa",
    "CB",
  ];

  telephone;
  email;

  private _entrepriseForm: FormGroup;
  private _inModif: boolean;

  constructor() {}

  ngOnInit() {}

  displayMontantAsString(montant: number) {
    return `${montant} €`;
  }
}
