export enum SORTENUM {
  ASC = "asc",
  DESC = "desc",
}

export enum IMPORT_TYPE {
  PROMESS,
  ECOLES,
  PREFECTURES,
}

export enum POPIN_MODS {
  CREATION,
  CONSULT,
  EDIT,
  MESSAGE,
}

export enum ETAT {
  NOTSTARTED = 1,
  ONGOING,
  DONE,
}

export enum ETAT_VERSEMENT {
  ONGOING = 1,
  DONE,
}

export enum RouteEnum {
  BASEURL = "",
  SCHOOLS = "ecoles",
  BUSINESS = "entreprises",
  TASKBOARD = "taskboard",
  GROUPS = "groupes",
  USERS = "utilisateurs",
  RECUS = "recus",
  SCHOOL_BY_ID = "ecole/:id",
  CREATE_ECOLE = "creation-ecole",
  CREATE_USER = "creation-user",
  TASK_BY_ID = "task/:id",
  USER_BY_ID = "utilisateur/:id",
  GROUP_BY_ID = "groupe/:id",
  MY_ACCOUNT = "mon-compte",
  OFFRES = "offres",
  CREATE_GROUP = "creation-groupe",
  FORBIDEN = "forbiden",
  PAYMENTS = "versements",
  STRIPE_SUBSCRIBE = "abonnements/:id",
}
