import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  EntrepriseInfos,
  EntreprisePromesseCount,
} from "../../../models/entreprise.model";
import { PromesseService } from "./promesse.service";

@Injectable({
  providedIn: "root",
})
export class EntrepriseService {
  constructor(
    private http: HttpClient,
    private promesseService: PromesseService
  ) {}

  GetPromessesBySiret(
    search: string,
    pageNb: number,
    pageSize: number,
    orderBy: string,
    sortOrder: string
  ): Observable<EntreprisePromesseCount> {
    let params = new HttpParams();
    if (pageNb) params = params.append("pageNb", pageNb.toString());
    if (pageSize) params = params.append("pageSize", pageSize.toString());
    if (orderBy) params = params.append("orderBy", orderBy);
    if (sortOrder) params = params.append("sortOrder", sortOrder);
    params = params.append(
      "year",
      this.promesseService.getTaxYear().toString()
    );
    return this.http.get<EntreprisePromesseCount>(
      `/api/Entreprise/getPromessesBySiret/${search}`,
      {
        params,
      }
    );
  }

  getEntreprise(
    entrepriseId: number,
    schoolId: number
  ): Observable<EntrepriseInfos> {
    let params = new HttpParams();
    if (!!entrepriseId)
      params = params.append("entrepriseId", entrepriseId.toString());

    if (!!schoolId) params = params.append("schoolId", schoolId.toString());

    return this.http.get<EntrepriseInfos>(`/api/Entreprise/`, {
      params: params,
    });
  }

  updateEntreprise(contact: EntrepriseInfos): Observable<EntrepriseInfos> {
    return this.http.post<EntrepriseInfos>(`/api/Entreprise`, contact);
  }

  getCompanyFromSiret(siretNb: string) {
    return this.http.get<any>(`/api/Entreprise/siret/${siretNb}`);
  }
}
