import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable, ReplaySubject, of, empty } from "rxjs";
import { AccountService } from "../api/services/account.service";
import { UserRights } from "src/models/user.model";
import { map, catchError, tap } from "rxjs/operators";
import { RouteEnum } from "src/enums/utils.enum";
import { USER_ROLE } from "../../enums/users.enum";

@Injectable({
  providedIn: "root",
})
export class CanActivateRouteGuard implements CanActivate {
  userRights$: ReplaySubject<UserRights> = new ReplaySubject(1);

  constructor(private accountService: AccountService, private router: Router) {
    this.accountService
      .me()
      .pipe(
        tap((val) => {
          this.userRights$.next(val);
        })
      )
      .subscribe();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userRights$.pipe(
      map((rights) => {
        const tempUrl = state.url.substring(1, state.url.length);
        let url;
        const indexOfId = tempUrl.match(/\d/) ? tempUrl.match(/\d/).index : -1;
        if (indexOfId > -1) {
          url = tempUrl.substring(0, indexOfId) + ":id";
        } else if (tempUrl.indexOf("?") > -1) {
          url = tempUrl.substring(0, tempUrl.indexOf("?"));
        } else {
          url = tempUrl;
        }
        let isForbidden = false;
        switch (url) {
          case RouteEnum.BASEURL:
            if (rights.canAccessTaskboard) {
              return this.router.parseUrl("/taskboard");
            } else if (rights.role == USER_ROLE.OPERATOR) {
              return this.router.parseUrl("/versements");
            } else {
              return this.router.parseUrl("ecoles");
            }
          case RouteEnum.CREATE_ECOLE:
            isForbidden = !rights.canCreateUpdateDelete;
            break;
          case RouteEnum.CREATE_USER:
            isForbidden = !rights.canCreateUpdateDelete;
            break;
          case RouteEnum.CREATE_GROUP:
            isForbidden = !rights.canCreateUpdateDelete;
            break;
          case RouteEnum.GROUPS:
            isForbidden = !rights.canAccessGroupsAndUsers;
            break;
          case RouteEnum.GROUP_BY_ID:
            isForbidden = !rights.canAccessGroupsAndUsers;
            break;
          case RouteEnum.MY_ACCOUNT:
          case RouteEnum.OFFRES:
          case RouteEnum.SCHOOLS:
          case RouteEnum.BUSINESS:
          case RouteEnum.SCHOOL_BY_ID:
            isForbidden = false;
            break;
          case RouteEnum.TASKBOARD:
            isForbidden = !rights.canAccessTaskboard;
            break;
          case RouteEnum.RECUS:
            isForbidden = !rights.canAccessTaskboard;
            break;
          case RouteEnum.TASK_BY_ID:
            isForbidden = !rights.canAccessTaskboard;
            break;
          case RouteEnum.USERS:
            isForbidden = !rights.canAccessGroupsAndUsers;
            break;
          case RouteEnum.USER_BY_ID:
            isForbidden = !rights.canAccessGroupsAndUsers;
            break;
          case RouteEnum.PAYMENTS:
            isForbidden = !rights.canCreateVersements;
            break;
          default:
            isForbidden = true;
        }
        if (isForbidden) {
          return this.router.parseUrl("/forbidden");
        }

        return !isForbidden;
      })
    );
  }
}
