import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IEcoleIban } from "src/models/ecole.model";
import { Observable } from "rxjs";
import { Stream } from "stream";

@Injectable({
  providedIn: "root",
})
export class IbanService {
  constructor(private http: HttpClient) {}

  // get iban list
  getIbans(id: number): Observable<IEcoleIban[]> {
    return this.http.get<IEcoleIban[]>("/api/Ecole/iban/" + id);
  }

  // Adding a new iban
  addIban(newIban: any): Observable<IEcoleIban> {
    return this.http.post<any>("/api/Ecole/iban/", newIban);
  }

  // Edit an iban
  editIban(ibanId: number, newIban: any): Observable<IEcoleIban> {
    return this.http.put<any>("/api/Ecole/iban/" + ibanId, newIban);
  }

  uploadIban(
    file: File,
    schoolId: string,
    ibanId: number = 0
  ): Observable<boolean> {
    const formData = new FormData();
    formData.append("file", file);

    const headers = new HttpHeaders({ enctype: "multipart/form-data" });

    return this.http.post<boolean>(
      `/api/Ecole/iban/upload/${schoolId}/${ibanId}`,
      formData,
      { headers: headers }
    );
  }

  validateIban(ibanId: number): Observable<boolean> {
    return this.http.put<boolean>(`/api/Ecole/iban/validate/${ibanId}`, {});
  }

  downloadIban(ecoleId: number, ibanId: number): Observable<any> {
    return this.http.get(`/api/Ecole/iban/download/${ecoleId}/${ibanId}`, {
      responseType: "arraybuffer",
    });
  }
}
