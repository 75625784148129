import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource, MatSort } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { LIST_TYPE } from 'src/enums/lists.type.enum';
import { ReceiptSendingDetails } from 'src/models/ecole.model';

export interface DialogData {
    data: any;
    mode: string;
}

@Component({
    selector: 'app-popin-versements-ready-to-send',
    templateUrl: './popin-versements-ready-to-send.component.html',
    styleUrls: ['./popin-versements-ready-to-send.component.scss'],
})
export class PopinVersementsReadyToSendComponent implements OnInit {
    _unsubscriber$: Subject<void> = new Subject();

    informations: ReceiptSendingDetails[];

    currentPage: number;

    sortedBy: string = 'gpCodeUnique';
    sortDir: 'asc' | 'desc';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialogRef: MatDialogRef<PopinVersementsReadyToSendComponent>
    ) {}

    ngOnInit() {
        this.informations = (<ReceiptSendingDetails[]>this.data.data).sort((a, b) => {
            const mailA = a.mailTo ? a.mailTo.toLocaleLowerCase() : '';
            const mailB = b.mailTo ? b.mailTo.toLocaleLowerCase() : '';

            let comparison = 0;
            if (mailA > mailB) {
                comparison = 1;
            } else if (mailA < mailB) {
                comparison = -1;
            }
            return comparison;
        });
    }

    sortInfo(col: string) {
        this.informations.sort((a, b) => {
            let A;
            let B;
            if (col !== 'receiptPerMail') {
                A = a[col] ? a[col].toLocaleLowerCase() : '';
                B = b[col] ? b[col].toLocaleLowerCase() : '';
            } else {
                A = a[col][0] ? a[col][0] : 0;
                B = b[col][0] ? b[col][0] : 0;
            }

            let comparison = 0;
            if (A > B) {
                comparison = 1;
            } else if (A < B) {
                comparison = -1;
            }
            return this.sortDir === 'desc' ? comparison : -comparison;
        });
    }

    onColClick(col: string) {
        if (col != this.sortedBy) {
            this.sortedBy = col;
            this.sortDir = 'desc';
        } else if (this.sortDir === 'desc') {
            this.sortDir = 'asc';
        } else {
            this.sortDir = 'desc';
        }
        this.sortInfo(col);
    }

    onSubmit() {
        this.dialogRef.close(true);
    }
}
