import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { GroupApi } from '../../api/groupApi';
import { MatTableDataSource } from '@angular/material/table';
import { GroupListDisplay } from '../../../models/group.model';
import { StaticService } from '../../services/functional-services/static-service/static-service';
import { Router } from '@angular/router';
import { LIST_TYPE } from 'src/enums/lists.type.enum';

@Component({
	selector: 'app-groups-list',
	templateUrl: './groups-list.component.html',
	styleUrls: ['./groups-list.component.scss']
})
export class GroupsListComponent implements OnInit {

	tableProperties: string[] = ['nom', 'ecoles', 'utilisateurs'];
	dataSource: any;

	toggleFilters = true;
	activeFiltersNb: number = 0;

	pageIndex: number = 1;
	pageSize: number = 50;
	sortColumn = 'nom';
	sortOrder = 'asc';
	groupNb: number;
	resetPaginator = true;
	listType = LIST_TYPE.GROUP_LIST;

	isLoadingUsers = true;
	isSearching = false;
	searchValue = '';
	searchFormControl = new FormControl();
	formCtrlSub: Subscription;

	private _unsubscriber$: Subject<void> = new Subject();

	constructor(private groupApi: GroupApi, private staticService: StaticService, private router: Router) { }

	ngOnInit() {
		this.getGroupList();
	}

	getGroupList() {
		this.groupApi.getGroupList(this.pageIndex, this.pageSize, this.sortColumn, this.sortOrder)
			.subscribe(data => {
				this.isLoadingUsers = false;
				this.groupNb = data.totalCount;
				this.dataSource = data.groups;
			});
	}

	goCreateGroupForm() {
		this.router.navigate(['/creation-groupe'], { queryParams: { createGroup: true } });
	}

	updatePageParam(event) {
		this.resetPaginator = false;
		this.pageIndex = event.pageIndex + 1;
		this.pageSize = event.pageSize;
		this.getProcessedGroupList();
	}

	updateSortParam(event) {
		this.toggleFilters = !this.toggleFilters;
		this.sortColumn = event.column;
		this.sortOrder = event.sortDirection;
		this.getProcessedGroupList();
	}

	getProcessedGroupList() {
		this.toggleFilters = true;
		this.isLoadingUsers = true;
		this.getGroupList();
	}

}
