import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/api/services/account.service';
import { User } from 'src/models/user.model';
import { StaticDataService } from 'src/app/api/static-data.service';


@Component({
	selector: 'app-my-account',
	templateUrl: './my-account.component.html',
	styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {


	currentUser: User;
	formulesTabDisplay: boolean;

	constructor(private accountService: AccountService,
		private staticDataService: StaticDataService) { }

	ngOnInit() {
		this.accountService.currentUser$.subscribe(val => this.currentUser = val);
		this.staticDataService.getOffersDisplayState().subscribe(val => this.formulesTabDisplay = val);
	}

}
