import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { VersementService } from 'src/app/api/services/versement.service';
import { FormControl } from '@angular/forms';
import { LIST_TYPE } from 'src/enums/lists.type.enum';
import { SchoolFunctService } from 'src/app/services/functional-services/school-service/school-funct.service';
import { takeUntil, switchMap, debounceTime, finalize, catchError, filter } from 'rxjs/operators';
import { Subject, empty, Observable, Subscription } from 'rxjs';
import { IAPINotSentPaymentsFilters } from 'src/models/ecole.model';
import { VERSEMENTS_RECU_FILTER_TYPE } from 'src/enums/ecole.enum';
import { POPIN_MODS } from 'src/enums/utils.enum';
import { MatDialog } from '@angular/material';
import { PopinVersementsWithoutMailComponent } from 'src/app/components/popins/popin-versements-without-mail/popin-versements-without-mail.component';
import { PopinVersementsReadyToSendComponent } from 'src/app/components/popins/popin-versements-ready-to-send/popin-versements-ready-to-send.component';
import {
    IEcoleVersement,
    RecuLiberatoire,
    IAPIVersementFilters,
    Payment,
    ReceiptSendingDetails,
} from 'src/models/ecole.model';

@Component({
    selector: 'app-recus',
    templateUrl: './recus.component.html',
    styleUrls: ['./recus.component.scss'],
})
export class RecusComponent implements OnInit {
    constructor(
        private versementService: VersementService,
        private schoolFunctService: SchoolFunctService,
        public dialog: MatDialog
    ) {}
    tableProperties: string[] = [
        'gpCodeUnique',
        'nbRecu',
        'codeUai',
        'typeEcole',
        'siret',
        'nomEntreprise',
        'numeroRecu',
        'mail',
    ];
    dataSource: any;
    toggleFilters = true;
    activeFiltersNb = 0;

    pageIndex = 1;
    pageSize = 50;
    sortColumn = 'gpcodeunique';
    sortOrder = 'asc';
    resetPaginator = true;

    loading = false;
    isLoadingVersements = true;
    formCtrlSub: Subscription;
    listType = LIST_TYPE.VERSEMENT_RECU_LIST;
    checkNull: ReceiptSendingDetails[];

    private _unsubscriber$: Subject<void> = new Subject();

    dataClickedResult = [];
    dataResultLength = 0;

    versementsNb = null;
    versements = null;

    showVersements: IEcoleVersement[] = [];
    body = {
        typeEcole: [],
        origin: [],
        cre: [],
        siret: [],
        codeGroupeur: [],
        pageSize: 50,
        pageNumber: this.pageIndex,
        sortOrder: this.sortOrder,
        sortColumn: this.sortColumn,
        year: 0,
    };

    ngOnInit() {
        this.versementService.getNotSentPayments(this.body).subscribe((data) => {
            this.isLoadingVersements = false;
            this.regroupGpCodeAndMail(data.versements);
            this.versements = data.versements;
            this.versementsNb = data.totalCount;
        });

        this.schoolFunctService.totalVersementsRecuFilters$.pipe(takeUntil(this._unsubscriber$)).subscribe((val) => {
            // On vérifie d'appeller la fonction seulement quand on delete des filtres
            if (val < this.activeFiltersNb) {
                this.activeFiltersNb = val;
                this.resetPaginator = true;
                this.toggleFilters = !this.toggleFilters;
                this.getVersements();
            } else {
                this.activeFiltersNb = val;
                this.resetPaginator = true;
            }
        });
    }

    regroupGpCodeAndMail(versement: IEcoleVersement[]) {
        let groupVersements = this.groupBy(versement, 'gpCodeUnique');
        this.showVersements = [];
        for (const item in groupVersements) {
            if (Object.prototype.hasOwnProperty.call(groupVersements, item)) {
                const element = groupVersements[item];
                let mailGroup = this.groupBy(element, 'mail');
                for (const item in mailGroup) {
                    if (Object.prototype.hasOwnProperty.call(mailGroup, item)) {
                        const mailElm = mailGroup[item];
                        if (mailElm[0].gpCodeUnique) {
                            this.showVersements.push(mailElm[0]);
                            this.showVersements[this.showVersements.length - 1].nbRecu = mailElm.length;
                            this.showVersements[this.showVersements.length - 1].nbEntreprise += 1;
                        } else {
                            mailElm.forEach((elm) => {
                                this.showVersements.push(elm);
                                this.showVersements[this.showVersements.length - 1].nbEntreprise += 1;
                            });
                        }
                    }
                }
            }
        }
        this.showVersements = [...this.showVersements];
    }

    groupBy = (array, key) => {
        // Return the end result
        return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
        }, {}); // empty object is the initial value for result object
    };

    getVersements() {
        this.isLoadingVersements = true;
        this.showVersements = [];
        this.versementService.getNotSentPayments(this.transformFiltersToBody()).subscribe((data) => {
            this.isLoadingVersements = false;
            this.regroupGpCodeAndMail(data.versements);
            this.versements = data.versements;
            this.versementsNb = data.totalCount;
        });
        this.isLoadingVersements = false;
    }

    private transformFiltersToBody(): IAPINotSentPaymentsFilters {
        const body: IAPINotSentPaymentsFilters = {
            typeEcole: [],
            origin: [],
            CRE: [],
            siret: [],
            codeGroupeur: [],
            PageNumber: this.pageIndex,
            PageSize: this.pageSize,
            sortColumn: this.sortColumn,
            sortOrder: this.sortOrder,
            year: 0,
        };
        if (this.activeFiltersNb >= 1) {
            body.siret = this.schoolFunctService.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.SIRET];
            body.CRE = this.schoolFunctService.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.CRE];
            body.codeGroupeur =
                this.schoolFunctService.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.CODEGROUPEUR];
            body.typeEcole = this.schoolFunctService.VersementsRecuCurrentFilters[
                VERSEMENTS_RECU_FILTER_TYPE.TYPEECOLE
            ].map((el) => el.id);
            body.origin = this.schoolFunctService.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.ORIGINE].map(
                (el) => el.nom
            );
        }
        return body;
    }

    updatePageParam(event) {
        this.resetPaginator = false;
        this.pageIndex = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.getVersements();
    }

    updateSortParam(event) {
        this.toggleFilters = !this.toggleFilters;
        this.sortColumn = event.column;
        this.sortOrder = event.sortDirection;
        this.getVersements();
    }

    onClickedOutside(e) {
        if (e.target.title !== 'openVersementsFilterDiv') {
            if (e.target.offsetParent !== null) {
                if (e.target.offsetParent.title !== 'openVersementsFilterDiv') {
                    if (!this.toggleFilters) {
                        this.toggleFilters = true;
                    }
                }
            } else {
                if (!this.toggleFilters) {
                    this.toggleFilters = true;
                }
            }
        }
    }

    sendAllReceipts() {
        this.loading = true;
        this.dataClickedResult = this.dataClickedResult.filter((item) => {
            return item && item.length > 0;
        });
        let versementList = [];
        this.dataClickedResult.forEach((element) => {
            if (element[0].mail) {
                element[0].mail = element[0].mail.toLocaleLowerCase();
            }
            if (versementList.findIndex((x) => x[0].versementId === element[0].versementId) === -1) {
                versementList.push(element);
            }
        });
        let regroupData = [];
        let data = [];
        versementList.forEach((element) => {
            data = [];
            this.versements.forEach((v) => {
                if (
                    v.mail &&
                    element[0].mail &&
                    v.mail.toLocaleLowerCase() === element[0].mail.toLocaleLowerCase() &&
                    v.gpCodeUnique &&
                    v.gpCodeUnique === element[0].gpCodeUnique
                ) {
                    data.push(v);
                } else if (
                    v.mail &&
                    element[0].mail &&
                    v.mail.toLocaleLowerCase() === element[0].mail.toLocaleLowerCase() &&
                    !v.gpCodeUnique &&
                    !element[0].gpCodeUnique &&
                    v.versementId === element[0].versementId
                ) {
                    data.push(v);
                } else if (!element[0].mail && !v.mail) {
                    if (element[0].gpCodeUnique && v.gpCodeUnique && v.gpCodeUnique === element[0].gpCodeUnique) {
                        data.push(v);
                    } else if (
                        !element[0].gpCodeUnique &&
                        !v.gpCodeUnique &&
                        v.versementId === element[0].versementId
                    ) {
                        data.push(v);
                    }
                }
            });
            regroupData.push(data);
        });
        let alreadyCountedEntreprise = [];
        regroupData.forEach((element) => {
            element[0].nbEntreprise = 1;
            this.versements
                .filter((x) => x.gpCodeUnique === element[0].gpCodeUnique)
                .forEach((v) => {
                    if (
                        v.entrepriseId !== element[0].entrepriseId &&
                        alreadyCountedEntreprise.findIndex((x) => x === v.entrepriseId) === -1
                    ) {
                        element[0].nbEntreprise += 1;
                        alreadyCountedEntreprise.push(v.entrepriseId);
                    }
                });
        });
        this.versementService.receiptSendingDetails(regroupData).subscribe((data) => {
            data = data.filter((i) => i !== null);
            this.loading = false;
            const dialogRef = this.dialog.open(PopinVersementsReadyToSendComponent, {
                width: '800px',
                height: '850px',
                data: {
                    mode: POPIN_MODS.MESSAGE,
                    title: 'Important',
                    bodyComponent: PopinVersementsReadyToSendComponent,
                    data,
                },
            });
            dialogRef
                .afterClosed()
                .pipe(takeUntil(this._unsubscriber$))
                .subscribe((val) => {
                    if (val === true) {
                        this.isLoadingVersements = true;
                        this.versementService.sendMultipleReceiptsMail(regroupData, true).subscribe((data) => {
                            if (data.length > 0) {
                                this.dialog.open(PopinVersementsWithoutMailComponent, {
                                    width: '800px',
                                    height: '635px',
                                    data: {
                                        mode: POPIN_MODS.MESSAGE,
                                        title: 'Important',
                                        bodyComponent: PopinVersementsWithoutMailComponent,
                                        data,
                                    },
                                });
                            }
                            this.getVersements();
                        });
                        this.dataClickedResult = [];
                        this.dataResultLength = 0;
                    }
                });
        });
    }

    updateDataClickedResult(data) {
        this.dataClickedResult = data;
        this.dataResultLength = this.dataClickedResult.reduce((count, row) => count + row.length, 0);
    }
}
