import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/api/services/account.service';
import { User, UserRights } from 'src/models/user.model';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { USER_ROLE } from '../../../../enums/users.enum';
import { StaticDataService } from 'src/app/api/static-data.service';
import { IMPORT_TYPE } from 'src/enums/utils.enum';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { POPIN_MODS } from '../../../../enums/utils.enum';
import { IEcoleListDisplay } from '../../../../models/ecole.model';
import { SchoolFunctService } from '../../../services/functional-services/school-service/school-funct.service';
import { TranslateService } from '@ngx-translate/core';
import { GenericPopinComponent } from 'src/app/components/popins/generic-popin/generic-popin.component';
import { PopinErrorComponent } from 'src/app/components/popins/popin-error/popin-error.component';
import { FormControl } from '@angular/forms';
import { PromesseService } from '../../../api/services/promesse.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    userRoles = USER_ROLE;

    isUserDropdownActive: boolean = false;
    currentUser: User;
    currentUserRights: UserRights;
    isOperator: boolean;
    offersButtonDisplayState: boolean;
    actualTaxYear: number;
    taxYear: number;

    private _unsusbcriber: Subject<void> = new Subject();

    constructor(
        private accountService: AccountService,
        private promesseService: PromesseService,
        private staticDataService: StaticDataService,
        private schoolFunctService: SchoolFunctService,
        private dialog: MatDialog,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.accountService.currentUser$.pipe(takeUntil(this._unsusbcriber)).subscribe((val) => {
            this.currentUser = val;
        });

        this.promesseService.referenceYear$.subscribe((val) => {
            this.actualTaxYear = val;
            this.taxYear = val;
        });

        this.staticDataService.getOffersDisplayState().subscribe((val) => (this.offersButtonDisplayState = val));

        this.accountService.currentUserRights$.pipe(takeUntil(this._unsusbcriber)).subscribe((val) => {
            this.currentUserRights = val;
            this.isOperator = this.currentUserRights.role === USER_ROLE.OPERATOR;
        });
    }

    toggleDropdown() {
        this.isUserDropdownActive = !this.isUserDropdownActive;
    }

    logout() {
        this.accountService.logout().subscribe((_) => {
            location.href = '/identity/account/login';
        });
    }

    switchYear(year: number) {
        this.taxYear = year;
    }

    ngOnDestroy() {
        this._unsusbcriber.next();
        this._unsusbcriber.complete();
    }

    importCSV(file: File, importType = IMPORT_TYPE.PREFECTURES) {
        const dialogTmp = this.dialog.open(GenericPopinComponent, {
            width: '630px',
            data: {
                mode: POPIN_MODS.MESSAGE,
                title: this.translate.instant('COMMON.POPINS.IMPORT'),
                bodyComponent: PopinErrorComponent,
                data: 'Import en cours',
            },
        });
        this.schoolFunctService.importCSVFile(file[0], 0, importType).subscribe(
            (data) => {
                dialogTmp.close();
                this.dialog.open(GenericPopinComponent, {
                    width: '630px',
                    data: {
                        mode: POPIN_MODS.MESSAGE,
                        title: this.translate.instant('COMMON.POPINS.IMPORTSUCCESS'),
                        bodyComponent: PopinErrorComponent,
                        data: "L'import est terminé",
                    },
                });
            },
            (err) => {
                dialogTmp.close();
                if (err.status === 400) {
                    this.dialog.open(GenericPopinComponent, {
                        width: '630px',
                        data: {
                            mode: POPIN_MODS.MESSAGE,
                            title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
                            bodyComponent: PopinErrorComponent,
                            data: "Une erreur est survenue lors de l'import : " + err.error.message,
                        },
                    });
                } else {
                    this.dialog.open(GenericPopinComponent, {
                        width: '630px',
                        data: {
                            mode: POPIN_MODS.MESSAGE,
                            title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
                            bodyComponent: PopinErrorComponent,
                            data: "Une erreur est survenue lors de l'import",
                        },
                    });
                }
            }
        );
    }
}
