import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { LIST_TYPE } from 'src/enums/lists.type.enum';

export interface DialogData {
    data: any;
    mode: string;
}

@Component({
    selector: 'app-popin-versements-without-mail',
    templateUrl: './popin-versements-without-mail.component.html',
    styleUrls: ['./popin-versements-without-mail.component.scss'],
})
export class PopinVersementsWithoutMailComponent implements OnInit {
    tableProperties: string[] = ['siren', 'nomEntreprise', 'nbEntreprise', 'gpCodeUnique'];
    versementsWithoutMail: any;

    listType = LIST_TYPE.VERSEMENTS_WITHOUT_MAIL;

    _unsubscriber$: Subject<void> = new Subject();

    constructor(
        public dialogRef: MatDialogRef<PopinVersementsWithoutMailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    ngOnInit() {
        this.versementsWithoutMail = this.data.data;
    }

    onSubmit() {}
}
