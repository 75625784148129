import { Component, OnInit } from '@angular/core';
import { OffresDescription, offres } from 'src/assets/offres/offres';
import { User, FormuleTask } from 'src/models/user.model';
import { AccountService } from 'src/app/api/services/account.service';
import { TaskboardService } from 'src/app/api/services/taskboard.service';
import { TYPE_TACHE, TASK_STATUS_ID } from 'src/enums/task.enum';
import { USER_FORMULE } from 'src/enums/users.enum';
import { POPIN_MODS } from 'src/enums/utils.enum';
import { PopinNewOffreComponent } from 'src/app/components/popins/popin-new-offre/popin-new-offre.component';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { StaticDataService } from 'src/app/api/static-data.service';
import { PaiementApi } from 'src/app/api/paiementApi';

@Component({
	selector: 'app-offres',
	templateUrl: './offres.component.html',
	styleUrls: ['./offres.component.scss']
})
export class OffresComponent implements OnInit {


  offresDescription: OffresDescription = offres;

  offers: boolean = true;
  //offerOne: boolean = true;
	currentUser: User;
	userFormule = USER_FORMULE;

	constructor(
		private accountService: AccountService,
		private taskboardService: TaskboardService,
		private staticDataService: StaticDataService,
    public dialog: MatDialog,
    private paiementApi: PaiementApi,
	) { }

  ngOnInit() {
    this.accountService.currentUser$
      .subscribe(
        val => { this.currentUser = val }
      );

    //this.staticDataService.getOffersPrice()
    //  .subscribe(
    //    vals => {
    //      //console.log(vals[0]);
    //      //console.log(vals[1]);
    //      //this.offresDescription.subscriber.prix = `${vals[0]}€`; this.offresDescription.extSubscriber.prix = `${vals[1]}€`
    //    });

    this.paiementApi.getFormules()
      .subscribe((result) => {
        //this.currentUser.formule = -1;
        //this.selectedFormule = window.location.pathname[13];
        //this.currentPlan = result[parseInt(this.selectedFormule) - 1];
        //this.offers = true;
        

        if (result === null || result === undefined) {
          //this.offers = false;
          return;
        }

        if (result.length === 1) {
          //this.offerOne = false;
          var highPrice = parseFloat(result[0].tarif) / 100;
            this.offresDescription.extSubscriber.prix = `${highPrice}€`;
            this.offresDescription.extSubscriber.offerName = result[0].nom;

          return;
        }

        var lowPrice = parseFloat(result[0].tarif) / 100;
        var highPrice = parseFloat(result[1].tarif) / 100;

        this.offresDescription.subscriber.prix = `${lowPrice}€`;
        this.offresDescription.extSubscriber.prix = `${highPrice}€`;

          this.offresDescription.subscriber.offerName = result[0].nom;
          this.offresDescription.extSubscriber.offerName = result[1].nom;


        //this.accountService.currentUser$.subscribe(val => { this.currentUser = val });
        //this.staticDataService.getOffersPrice()
        //  .subscribe
        //  (val =>
        //  {
            
            
        //  });

      });
	}

	subscribeToOffer(offreId: USER_FORMULE) {
		// const myFormule: FormuleTask = {
		// 	userId: this.currentUser.userId,
		// 	nom: this.currentUser.nom,
		// 	prenom: this.currentUser.prenom,
		// 	formuleId: offreId
		// }
		// if (offreId !== this.currentUser.formule) {
		// 	this.createTask({ after: myFormule });

		// 	const dialogRef = this.dialog.open(PopinNewOffreComponent, {
		// 		width: '600px',
		// 		data: {
		// 			mode: POPIN_MODS.EDIT,
		// 			title: this.translate.instant('COMMON.POPIN.NEW_OFFRE_TITLE'),
		// 			bodyComponent: PopinNewOffreComponent
		// 		}
		// 	});
		// }
	}

	private createTask(formule: { after: FormuleTask }) {
		this.taskboardService.createTask({
			taskType: TYPE_TACHE.FORMULE,
			statut: TASK_STATUS_ID.WAITING,
			targetId: this.currentUser.userId,
			data: JSON.stringify(formule),
			ecoleId: 0 // 0 by default
		}).subscribe();
	}
}
