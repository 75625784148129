export enum LIST_TYPE {
  ADD_SCHOOL_LIST = "add-schools-list",
  SCHOOL_LIST = "schools-list",
  DIPLOMA_LIST = "diplomas-list",
  PROMESS_LIST = "promesses-list",
  VERSEMENT_LIST = "versements-list",
  USER_LIST = "users-list",
  GROUP_LIST = "groups-list",
  DUPLICATE_SCHOOL_LIST = "duplicate-schools-list",
  VERSEMENT_RECU_LIST = "versements-recu-list",
  VERSEMENTS_WITHOUT_MAIL = "versements-without-mail",
  BUSINESS_LIST = "business",
}
