import { Component, OnInit } from '@angular/core';
import { SchoolApi } from '../../../app/api/schoolApi';
import { IEcole } from '../../../models/ecole.model';
import { ActivatedRoute } from '@angular/router';
import { StaticService } from '../../services/functional-services/static-service/static-service';
import { UserRights } from 'src/models/user.model';
import { AccountService } from 'src/app/api/services/account.service';
import { Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

@Component({
	selector: 'app-school-info',
	templateUrl: './school-info.component.html',
	styleUrls: ['./school-info.component.scss']
})

export class SchoolInfoComponent implements OnInit {

	school: IEcole;
	schoolId: string;
	currentUserRights: UserRights;
	areTasksAwaiting: boolean
	nbDiploma: any;
	nbPromise: any;
	nbPayements: any;
	forceUpdatePromessesList: boolean;

	private _unsubscriber$: Subject<void> = new Subject();

	constructor(private schoolApi: SchoolApi, private route: ActivatedRoute, private accountService: AccountService) { }

	ngOnInit() {
		this.route.params.subscribe(data => {
			this.schoolId = data.id;
		});

		this.accountService.currentUserRights$.pipe(takeUntil(this._unsubscriber$)).subscribe(val => {
			this.currentUserRights = val;
		})

		this.schoolApi.getSchoolDetails(this.schoolId).pipe(switchMap(school => {
			return this.schoolApi.getSchoolTasksStatus(school.ecoleInfo.ecoleId);
		}, (data: IEcole, areTasksAwaiting: boolean) => {
			this.areTasksAwaiting = areTasksAwaiting;
			if (data.ecoleInfo.logoImage) {
				var image = "data:image/png;base64," + data.ecoleInfo.logoImage;
			}

			this.school = {
				ecoleInfo: {
					reference: data.ecoleInfo.reference,
					codeUai: data.ecoleInfo.codeUai,
					nom1: data.ecoleInfo.nom1,
					nom2: data.ecoleInfo.nom2,
					siret: data.ecoleInfo.siret,
					sigle: data.ecoleInfo.sigle,
					type: data.ecoleInfo.type,
					reseauEcole: data.ecoleInfo.reseauEcole,
					habilitations: data.ecoleInfo.habilitations,
					ecoleId: data.ecoleInfo.ecoleId,
					logo: data.ecoleInfo.logo,
					logoImage: image,
					recuNbr: data.ecoleInfo.recuNbr
				},
				adresse: {
					adresse1: data.adresse.adresse1,
					adresse2: data.adresse.adresse2,
					ville: data.adresse.ville,
					codePostal: data.adresse.codePostal,
					cedex: data.adresse.cedex,
					regionNom: data.adresse.regionNom,
					contactPrenom: data.adresse.contactPrenom,
					contactNom: data.adresse.contactNom,
					email: data.adresse.email,
					telephone: data.adresse.telephone,
					fax: data.adresse.fax,
					webSite: data.adresse.webSite,
					adresseId: data.adresse.adresseId,
					regionId: data.adresse.regionId
				},
				ibanList: data.ibanList,
				signatureList: data.signatureList
			}
			this.school.signatureList.forEach(sign => sign.nomfichier = "Signature_" + sign.nom);
		})).subscribe()

	}

	setNbDiploma(value) {
		this.nbDiploma = value;
	}

	setNbPromise(value) {
		this.nbPromise = value;
	}

	setNbPayements(value) {
		this.nbPayements = value;
	}

	updateGetPromessesList() {
		this.forceUpdatePromessesList = true;
	}

	resetForceUpdateValue() {
		this.forceUpdatePromessesList = false;
	}

	updateSchoolInfo() {
		this.schoolApi.getSchoolDetails(this.schoolId).pipe(switchMap(school => {
			return this.schoolApi.getSchoolTasksStatus(school.ecoleInfo.ecoleId);
		}, (data: IEcole, areTasksAwaiting: boolean) => {
			this.areTasksAwaiting = areTasksAwaiting;
			if (data.ecoleInfo.logoImage) {
				var image = "data:image/png;base64," + data.ecoleInfo.logoImage;
			}

			this.school = {
				ecoleInfo: {
					reference: data.ecoleInfo.reference,
					codeUai: data.ecoleInfo.codeUai,
					nom1: data.ecoleInfo.nom1,
					nom2: data.ecoleInfo.nom2,
					siret: data.ecoleInfo.siret,
					sigle: data.ecoleInfo.sigle,
					type: data.ecoleInfo.type,
					reseauEcole: data.ecoleInfo.reseauEcole,
					habilitations: data.ecoleInfo.habilitations,
					ecoleId: data.ecoleInfo.ecoleId,
					logo: data.ecoleInfo.logo,
					logoImage: image,
					recuNbr: data.ecoleInfo.recuNbr
				},
				adresse: {
					adresse1: data.adresse.adresse1,
					adresse2: data.adresse.adresse2,
					ville: data.adresse.ville,
					codePostal: data.adresse.codePostal,
					cedex: data.adresse.cedex,
					regionNom: data.adresse.regionNom,
					contactPrenom: data.adresse.contactPrenom,
					contactNom: data.adresse.contactNom,
					email: data.adresse.email,
					telephone: data.adresse.telephone,
					fax: data.adresse.fax,
					webSite: data.adresse.webSite,
					adresseId: data.adresse.adresseId,
					regionId: data.adresse.regionId
				},
				ibanList: data.ibanList,
				signatureList: data.signatureList
			}
			this.school.signatureList.forEach(sign => sign.nomfichier = "Signature_" + sign.nom);
		})).subscribe()
	}
}
