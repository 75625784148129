import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { catchError, debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
    EntreprisePromesse,
    EntreprisePromesseCount,
    EntreprisePromesseDisplay,
} from '../../../models/entreprise.model';
import { EntrepriseService } from '../../api/services/entreprise.service';
import { TranslateService } from '@ngx-translate/core';
import { LIST_TYPE } from 'src/enums/lists.type.enum';
import { FormControl } from '@angular/forms';
import { RecuToSend, TableService } from '../../services/functional-services/table-service/table-service';
import { empty, from, Observable, Subject } from 'rxjs';
import { IEcoleVersement, RecuLiberatoire } from '../../../models/ecole.model';
import { VersementService } from 'src/app/api/services/versement.service';
import { POPIN_MODS } from '../../../enums/utils.enum';
import { GenericPopinComponent } from 'src/app/components/popins/generic-popin/generic-popin.component';
import { PopinErrorComponent } from 'src/app/components/popins/popin-error/popin-error.component';
import { AccountService } from 'src/app/api/services/account.service';
import { USER_ROLE } from 'src/enums/users.enum';
import { UserRights } from 'src/models/user.model';

@Component({
    selector: 'app-business',
    templateUrl: './business.component.html',
    styleUrls: ['./business.component.scss'],
})
export class BusinessComponent implements OnInit {
    isSearching: boolean;
    searchValue: string = '';

    pageNb: number = 1;
    pageSize: number = 50;
    sort: string = 'asc';
    orderBy: string = 'business';

    entreprises: MatTableDataSource<EntreprisePromesseDisplay>;
    loading: boolean;

    searchFormControl: FormControl = new FormControl();

    resetPaginator = true;
    listType = LIST_TYPE.BUSINESS_LIST;
    totalCount: number = 0;
    isGeneratingReceipt: boolean;
    tableProperties: string[] = [
        'etat',
        'codeuai',
        'reference',
        'ecoles',
        'gpcodeunique',
        'siret',
        'business',
        'montantpromis',
        'montantverse',
        'numerorecu',
        'dateenvoirecu',
    ];

    downloadLink: string;

    private unsubscriber$: Subject<void> = new Subject();
    private destroy$: Subject<boolean> = new Subject<boolean>();
    currentUserRights: UserRights;
    currentUserRole: USER_ROLE;

    constructor(
        private dialog: MatDialog,
        private promiseService: EntrepriseService,
        private tableService: TableService,
        private versementService: VersementService,
        private accountService: AccountService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe((val) => {
            this.searchValue = val;
            if (this.searchValue.length >= 3) {
                this.getEntrepriseList();
            }
        });

        this.tableService.startGeneratingReceipt$.pipe(takeUntil(this.unsubscriber$)).subscribe((versement) => {
            if (!this.isGeneratingReceipt) {
                this.isGeneratingReceipt = true;
                this.generateSingleReceipt(versement);
            }
        });

        this.tableService.downloadRecu$.pipe(takeUntil(this.unsubscriber$)).subscribe((versement) => {
            this.downloadRecu(versement);
        });

        this.tableService.sendReceiptMail$.pipe(takeUntil(this.unsubscriber$)).subscribe((recuToSend) => {
            this.sendOneReceipt(recuToSend);
        });

        this.accountService.currentUserRights$.pipe(takeUntil(this.unsubscriber$)).subscribe((val) => {
            this.currentUserRights = val;
            this.currentUserRole = this.currentUserRights.role;
        });
    }

    getEntrepriseListObs(): Observable<any> {
        this.loading = true;
        return this.promiseService
            .GetPromessesBySiret(this.searchValue, this.pageNb, this.pageSize, this.orderBy, this.sort)
            .pipe(
                debounceTime(500),
                tap((entreprisesResult: EntreprisePromesseCount) => {
                    const entreprises = entreprisesResult ? entreprisesResult.entreprisePromesses : null;
                    this.totalCount = entreprisesResult ? entreprisesResult.totalCount : null;
                    this.loading = false;
                    if (entreprises) {
                        let versements = [];
                        const ents = entreprises.map((e) => {
                            return {
                                etat: e.etat,
                                codeuai: e.codeUai,
                                reference: e.codeEcole,
                                ecoles: e.nomEcole,
                                gpcodeunique: e.codeGroupeur,
                                siret: e.siret,
                                schoolId: e.ecoleId,
                                business: e.nomEntreprise,
                                montantpromis: e.montantPromis,
                                versements: e.versements,
                            };
                        });
                        ents.forEach((e) => {
                            if (e.versements) {
                                e.versements.forEach((v) => {
                                    versements.push({
                                        ...e,
                                        versementId: v.versementId,
                                        montantverse: v.montantVerse,
                                        numerorecu: v.numeroRecu,
                                        dateenvoirecu: v.dateEnvoiRecu,
                                        recuLiberatoire: v.recuLiberatoire,
                                        dateEnvoiRecuAdmin: v.dateEnvoiRecuAdmin,
                                    });
                                });
                            } else {
                                versements.push({
                                    ...e,
                                });
                            }
                        });

                        this.entreprises = new MatTableDataSource<EntreprisePromesseDisplay>(versements);
                    } else {
                        this.entreprises = new MatTableDataSource<EntreprisePromesseDisplay>([]);
                    }
                })
            );
    }

    getEntrepriseList() {
        this.getEntrepriseListObs().subscribe(
            () => {},
            (err) => {
                this.entreprises = null;
                this.loading = false;
            }
        );
    }

    updateSortParam(event) {
        this.orderBy = event.column;
        this.sort = event.sortDirection;
        this.loading = true;
        this.getEntrepriseList();
    }

    updatePageParam(event) {
        this.resetPaginator = false;
        this.pageNb = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.loading = true;
        this.getEntrepriseList();
    }

    GetLastRecuNbr(ecoleId: number): Observable<number> {
        return this.versementService.getLastRecuNbr(ecoleId);
    }

    generateSingleReceipt(entreprise: any) {
        this.GetLastRecuNbr(entreprise.schoolId)
            .pipe(
                switchMap((lastRecuNbr) => {
                    if (entreprise.numeroRecu) {
                        lastRecuNbr = entreprise.numeroRecu;
                    }
                    return this.generationProcess(entreprise, lastRecuNbr, 0);
                }),
                switchMap(
                    (_) => {
                        this.versementService.uploadLastReceiptDateInEcole(entreprise.schoolId).subscribe();
                        return this.getEntrepriseListObs();
                    },
                    (_, newList) => {
                        this.entreprises = new MatTableDataSource<EntreprisePromesseDisplay>(newList.versements);
                        this.isGeneratingReceipt = false;
                    }
                ),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    generationProcess(entreprise: any, lastRecuNbr: number, index = 0): Observable<any> {
        const today: Date = new Date();
        today.setHours(0, 0, 0, 0);
        return this.getPdfData(entreprise).pipe(
            switchMap((data) => {
                if (!data) {
                    // display an error popin if the school sheet is not complete
                    this.displayPopinError('RECEIPT.ERROR_CONTENT');
                    // -> then cancel subscription
                    this.destroy$.next(true);
                } else if (data.dateRecuGenerable === null || new Date(data.dateRecuGenerable) > today) {
                    this.displayPopinError('RECEIPT.ERROR_DATES');
                    return empty;
                } else {
                    // standard case: generate and upload pdf
                    return this.createAndUploadReceipt(data, index, lastRecuNbr);
                }
            })
        );
    }

    private getPdfData(versement: IEcoleVersement): Observable<any> {
        return this.versementService.getRecuData(versement.versementId);
    }

    private displayPopinError(translation: string, additionalData?: any) {
        this.loading = false;
        this.dialog.open(GenericPopinComponent, {
            width: '630px',
            disableClose: true,
            data: {
                mode: POPIN_MODS.MESSAGE,
                title: this.translate.instant('RECEIPT.ERROR_TITLE'),
                bodyComponent: PopinErrorComponent,
                data: this.translate.instant(translation) + (additionalData ? ` ${additionalData}` : ''),
            },
        });
    }

    private createAndUploadReceipt(data, index: number, lastRecuNbr: number): Observable<any> {
        return this.generatePdfFromData(data, index, lastRecuNbr).pipe(
            switchMap((file) => {
                // upload file after generation
                return this.versementService.uploadRecu(
                    file,
                    data.ecole.ecoleId,
                    data.ecole.reference,
                    data.versementId,
                    lastRecuNbr + index,
                    data.entreprise.siret
                );
            })
        );
    }

    private generatePdfFromData(recu: RecuLiberatoire, index: number, lastRecuNbr: number): Observable<any> {
        return from(this.versementService.generateRecu(recu, index, lastRecuNbr));
    }

    downloadRecu(versement: IEcoleVersement) {
        if (versement.recuLiberatoire) {
            const fileExtension = versement.recuLiberatoire.substring(versement.recuLiberatoire.lastIndexOf('.'));
            this.versementService.downloadRecu(versement.versementId).subscribe((data) => {
                const blob = new Blob([data]);
                this.downloadLink = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = this.downloadLink;
                a.download = versement.siret
                    ? versement.siret + '_' + versement.numeroRecu + fileExtension
                    : versement.recuLiberatoire.substring(versement.recuLiberatoire.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
        }
    }

    private sendOneReceipt(recuToSend: RecuToSend) {
        this.versementService
            .sendReceiptMail(recuToSend)
            .pipe(
                catchError((_) => {
                    this.displayPopinError('RECEIPT.ERROR_MAIL');
                    return empty();
                })
            )
            .subscribe((lightPayment) => {
                if (Object.keys(lightPayment).length === 3) {
                    this.displayPopinError('RECEIPT.ERROR_MAIL');
                }
                const data = this.entreprises.data;
                const editedEntryIndex = data.map((el) => el.versementId).indexOf(lightPayment.versementId);
                data[editedEntryIndex] = {
                    ...data[editedEntryIndex],
                    dateenvoirecu: lightPayment.dateEnvoiRecu,
                };
                this.entreprises.data = data;
            });
    }

    ngOnDestroy() {
        this.unsubscriber$.next();
        this.unsubscriber$.complete();
    }
}
