import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { IVMSchoolFilters } from '../../../../models/ecole.model';
import { SchoolFunctService } from '../../../services/functional-services/school-service/school-funct.service';
import { VERSEMENTS_RECU_FILTER_TYPE } from '../../../../enums/ecole.enum';
import { StaticService } from '../../../services/functional-services/static-service/static-service';
import { USER_PROFILE } from 'src/enums/users.enum';

@Component({
    selector: 'app-versements-recu-filter-box',
    templateUrl: './versements-recu-filter-box.component.html',
    styleUrls: ['./versements-recu-filter-box.component.scss'],
})
export class VersementsRecusFilterBoxComponent implements OnInit, OnDestroy {
    @Output() getFilteredResult: EventEmitter<void> = new EventEmitter();

    VersementsRecuFilterForm: FormGroup;
    currentFilters: IVMSchoolFilters;

    typeEcole = [
        { id: 1, nom: 'École membre' },
        { id: 2, nom: 'École partenaire' },
        { id: 3, nom: 'Autres écoles' },
    ];

    origine = [
        { id: 1, nom: 'MyEcole' },
        { id: 2, nom: 'MyTaxe' },
        { id: 3, nom: 'Import' },
        { id: 4, nom: 'Stripe' },
    ];

    siretControl = new FormControl('');
    codeGroupeurControl = new FormControl('');
    CREControl = new FormControl('');
    origineControl = new FormControl();
    typeEcoleControl = new FormControl();

    private _unsubscriber$ = new Subject();

    constructor(
        private fb: FormBuilder,
        private schoolFunctService: SchoolFunctService,
        private staticService: StaticService
    ) {
        this.VersementsRecuFilterForm = fb.group({
            siret: this.siretControl,
            codeGroupeur: this.codeGroupeurControl,
            CRE: this.CREControl,
            origine: this.origineControl,
            typeEcole: this.typeEcoleControl,
        });
    }

    ngOnInit() {
        this.updateFilterList();

        this.schoolFunctService.deleteEvent$.subscribe((_) => {
            this.currentFilters = this.schoolFunctService.getVersementsRecuFiltersAsArray();
        });
    }

    submit(event) {
        event.preventDefault();
        this.schoolFunctService.addVersementsRecuFilters(this.VersementsRecuFilterForm.value);
        this.updateFilterList();
        this.getFilteredResult.emit();
        this.siretControl.reset('');
        this.codeGroupeurControl.reset('');
        this.CREControl.reset('');
    }

    reset(event) {
        event.preventDefault();
        this.schoolFunctService.deleteAllVersementsRecuFilter();
        this.siretControl.reset('');
        this.codeGroupeurControl.reset('');
        this.CREControl.reset('');
        this.typeEcoleControl.reset('');
        this.origineControl.reset('');
    }

    deleteFilter(filterTodelete: { id: number; name: string; type: VERSEMENTS_RECU_FILTER_TYPE }) {
        this.schoolFunctService.deleteVersementsRecuFilter(filterTodelete);
        this.updateFilterList();
    }

    updateFilterList() {
        //update Tag list si on a deja des filtres selectionnés
        this.currentFilters = this.schoolFunctService.getVersementsRecuFiltersAsArray();

        //update
        this.siretControl.setValue(
            this.schoolFunctService.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.SIRET]
        );
        this.codeGroupeurControl.setValue(
            this.schoolFunctService.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.CODEGROUPEUR]
        );
        this.codeGroupeurControl.setValue(
            this.schoolFunctService.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.CRE]
        );
        this.typeEcoleControl.setValue(
            this.schoolFunctService.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.TYPEECOLE]
        );
        this.origineControl.setValue(
            this.schoolFunctService.VersementsRecuCurrentFilters[VERSEMENTS_RECU_FILTER_TYPE.ORIGINE]
        );
    }

    ngOnDestroy() {
        this._unsubscriber$.next();
        this._unsubscriber$.complete();
    }
}
