import { IEcoleVersement, IEcolePromesse, RecuLiberatoire, IAPIVersementFilters, IEcole } from 'src/models/ecole.model';
import { Component, OnInit, Output, EventEmitter, ResolvedReflectiveFactory } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { VersementService } from 'src/app/api/services/versement.service';
import { ActivatedRoute } from '@angular/router';
import {
    TableService,
    ObjectToDelete,
    RecuToSend,
} from 'src/app/services/functional-services/table-service/table-service';
import {
    takeUntil,
    switchMap,
    debounceTime,
    finalize,
    catchError,
    concatMap,
    toArray,
    concatAll,
} from 'rxjs/operators';
import {
    Subject,
    throwError,
    BehaviorSubject,
    forkJoin,
    Observable,
    of,
    from,
    Subscription,
    empty,
    concat,
} from 'rxjs';
import { LIST_TYPE } from 'src/enums/lists.type.enum';
import { GenericPopinComponent } from 'src/app/components/popins/generic-popin/generic-popin.component';
import { MatDialog } from '@angular/material';
import { ETAT_VERSEMENT, POPIN_MODS } from 'src/enums/utils.enum';
import { TranslateService } from '@ngx-translate/core';
import { PopinErrorComponent } from 'src/app/components/popins/popin-error/popin-error.component';
import { FormControl } from '@angular/forms';
import { USER_ROLE } from 'src/enums/users.enum';
import { UserRights } from 'src/models/user.model';
import { AccountService } from 'src/app/api/services/account.service';
import { SchoolFunctService } from 'src/app/services/functional-services/school-service/school-funct.service';
import { PAYMENT_FILTER_TYPE } from 'src/enums/ecole.enum';

import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { EntrepriseService } from 'src/app/api/services/entreprise.service';
import * as moment from 'moment';
import { PopinVersementsWithoutMailComponent } from 'src/app/components/popins/popin-versements-without-mail/popin-versements-without-mail.component';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { promise } from 'protractor';
import { PromesseService } from '../../../api/services/promesse.service';

@Component({
    selector: 'app-school-payments',
    templateUrl: './school-payments.component.html',
    styleUrls: ['./school-payments.component.scss'],
})
export class SchoolPaymentsComponent implements OnInit {
    @Output() refreshPromessesList = new EventEmitter();
    @Output() refreshSchoolInfo = new EventEmitter();

    isSearching = false;
    toggleFilters = true;
    searchValue = null;
    searchFormControl = new FormControl();
    activeFiltersNb: number = 0;
    currentUserRights: UserRights;
    currentUserRole: USER_ROLE;
    formCtrlSub: Subscription;
    isLoadingVersements: boolean;
    isGeneratingReceipt: boolean;

    tableProperties: string[] = [
        'etat',
        'gpCodeUnique',
        'siret',
        'nomEntreprise',
        'montantVerse',
        'numeroRecu',
        'dateEnvoiRecu',
    ];
    dataSource: MatTableDataSource<IEcoleVersement>;
    schoolId: number;
    downloadLink: string;
    loading = false;
    listType = LIST_TYPE.VERSEMENT_LIST;
    totalCount: number = 0;

    actualTaxYear: number;
    taxYear: number;

    pageIndex: number = 1;
    pageSize: number = 50;
    sortColumn = 'etat';
    sortOrder = 'asc';
    resetPaginator = true;

    private unsubscriber$: Subject<void> = new Subject();
    private destroy$: Subject<boolean> = new Subject<boolean>();

    @Output() nbPayements = new EventEmitter();

    constructor(
        private route: ActivatedRoute,
        private schoolFunctService: SchoolFunctService,
        private versementService: VersementService,
        private promesseService: PromesseService,
        private tableService: TableService,
        private translate: TranslateService,
        private accountService: AccountService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.route.params.subscribe((data) => {
            this.schoolId = +data.id;
        });
        this.getVersements();

        this.tableService.itemToDelete$.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
            if (data.listType === LIST_TYPE.VERSEMENT_LIST) {
                this.deletePayment(data);
            }
        });

        this.promesseService.referenceYear$.subscribe((val) => {
            this.actualTaxYear = val;
        });

        this.taxYear = this.promesseService.getTaxYear();

        this.tableService.addPayment$.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
            this.addPayment(data);
        });

        this.tableService.startGeneratingReceipt$.pipe(takeUntil(this.unsubscriber$)).subscribe((versement) => {
            if (!this.isGeneratingReceipt) {
                this.isGeneratingReceipt = true;
                this.generateSingleReceipt(versement);
            }
        });

        this.tableService.downloadRecu$.pipe(takeUntil(this.unsubscriber$)).subscribe((versement) => {
            this.downloadRecu(versement);
        });

        this.tableService.sendReceiptMail$.pipe(takeUntil(this.unsubscriber$)).subscribe((recuToSend) => {
            this.sendOneReceipt(recuToSend);
        });

        this.accountService.currentUserRights$.pipe(takeUntil(this.unsubscriber$)).subscribe((val) => {
            this.currentUserRights = val;
            this.currentUserRole = this.currentUserRights.role;
        });

        this.formCtrlSub = this.searchFormControl.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
            if (value !== this.searchValue) {
                this.isLoadingVersements = true;
                this.searchValue = value;
                this.resetPaginator = true;
                this.getProcessedPaymentList();
            }
        });

        this.schoolFunctService.totalPaymentFilters$.pipe(takeUntil(this.unsubscriber$)).subscribe((val) => {
            // On vérifie d'appeller la fonction seulement quand on delete des filtres
            if (val < this.activeFiltersNb) {
                this.activeFiltersNb = val;
                this.resetPaginator = true;
                this.getProcessedPaymentList();
            } else {
                this.activeFiltersNb = val;
                this.resetPaginator = true;
            }
        });
    }

    resetSearchCriteria() {
        this.searchValue = '';
        this.isSearching = !this.isSearching;
        this.resetPaginator = true;
        this.getProcessedPaymentList();
    }

    getProcessedPaymentList() {
        this.isLoadingVersements = true;
        if (this.activeFiltersNb >= 1 || this.isSearching) {
            this.getFilteredPaymentList(this.transformFiltersToBody());
        } else {
            this.getVersements();
        }
    }

    private getFilteredPaymentList(body: IAPIVersementFilters) {
        this.versementService
            .getFilteredVersements(this.schoolId, body)
            .pipe(finalize(() => (this.isLoadingVersements = false)))
            .subscribe((data) => {
                this.dataSource = new MatTableDataSource<IEcoleVersement>(data.versements);
                this.nbPayements.emit(data.totalCount);
                this.totalCount = data.totalCount;
                this.isLoadingVersements = false;
            });
    }

    deletePayment(item: ObjectToDelete) {
        this.versementService
            .deleteVersement(item.itemId)
            .pipe(
                switchMap((_) => {
                    return this.versementService.getVersements(this.schoolId, this.pageIndex, this.pageSize);
                })
            )
            .subscribe((data) => {
                this.totalCount = data.totalCount;
                this.dataSource = new MatTableDataSource<IEcoleVersement>(data.versements);
                this.nbPayements.emit(data.totalCount);
                this.refreshPromessesList.emit();
            });
    }

    addPayment(versement: IEcoleVersement) {
        const payment: IEcoleVersement = {
            ...versement,
            etat: ETAT_VERSEMENT.ONGOING,
        };
        const data = this.dataSource.data;
        if (data.some((el) => el.versementId === payment.versementId)) {
            const editedEntryIndex = data.map((el) => el.versementId).indexOf(payment.versementId);
            data[editedEntryIndex] = payment;
        } else {
            data.push(payment);
        }
        this.totalCount++;
        this.dataSource.data = data;
        this.nbPayements.emit(this.totalCount);
    }

    private getPdfData(versement: IEcoleVersement): Observable<any> {
        return this.versementService.getRecuData(versement.versementId);
    }

    private generatePdfFromData(recu: RecuLiberatoire, index: number, lastRecuNbr: number): Observable<any> {
        return from(this.versementService.generateRecu(recu, index, lastRecuNbr));
    }

    private createAndUploadReceipt(data, index: number, lastRecuNbr: number): Observable<any> {
        return this.generatePdfFromData(data, index, lastRecuNbr).pipe(
            switchMap((file) => {
                // upload file after generation
                return this.versementService.uploadRecu(
                    file,
                    data.ecole.ecoleId,
                    data.ecole.reference,
                    data.versementId,
                    lastRecuNbr + index,
                    data.entreprise.siret
                );
            })
        );
    }

    generationProcess(versement: IEcoleVersement, lastRecuNbr: number, index = 0): Observable<IEcoleVersement> {
        const today: Date = new Date();
        today.setHours(0, 0, 0, 0);
        return this.getPdfData(versement).pipe(
            switchMap((data) => {
                if (!data) {
                    // display an error popin if the school sheet is not complete
                    this.displayPopinError('RECEIPT.ERROR_CONTENT');
                    // -> then cancel subscription
                    this.destroy$.next(true);
                } else if (data.dateRecuGenerable === null) {
                    this.displayPopinError('RECEIPT.ERROR_DATES');
                    return empty();
                } else {
                    // standard case: generate and upload pdf
                    return this.createAndUploadReceipt(data, index, lastRecuNbr);
                }
            })
        );
    }

    GetLastRecuNbr(ecoleId: number): Observable<number> {
        return this.versementService.getLastRecuNbr(ecoleId);
    }

    generateAllReceipts() {
        this.loading = true;
        const filteredReceipts = this.dataSource.data.filter((versement) => !versement.recuLiberatoire);
        if (filteredReceipts.length < 1) {
            this.loading = false;
            return;
        }
        this.GetLastRecuNbr(this.schoolId)
            .pipe(
                switchMap((lastRecuNbr) => {
                    let regenerateNumber = 0;
                    const toGenerate = filteredReceipts.map((elem, index) => {
                        // Manip pour permettre de regénéré un reçu avec le même numéro
                        let receipNumber = lastRecuNbr;
                        if (elem.numeroRecu) {
                            receipNumber = elem.numeroRecu;
                            index = 0;
                            regenerateNumber++;
                            return this.generationProcess(elem, receipNumber, index);
                        } else {
                            return this.generationProcess(elem, receipNumber, index - regenerateNumber);
                        }
                    });
                    if (toGenerate.length === 0) {
                        this.loading = false;
                    }
                    return forkJoin(toGenerate);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((versementsArray) => {
                this.versementService.uploadLastReceiptDateInEcole(this.schoolId).subscribe();
                const versementsToSend = [];
                const mailArray: string[] = [];
                versementsArray.forEach((el) => {
                    if (!mailArray.includes(el.mail) && !el.gpCodeUnique) {
                        mailArray.push(el.mail);
                    }
                });
                mailArray.forEach((mail) => {
                    versementsToSend.push(
                        versementsArray
                            .map((v) => {
                                if (mail === v.mail && !v.gpCodeUnique) {
                                    return v;
                                }
                            })
                            .filter((x) => x !== undefined)
                    );
                });

                const mailReceipt = this.versementService.sendMultipleReceiptsMail(versementsToSend, false).pipe(
                    catchError((_) => {
                        this.displayPopinError('RECEIPT.ERROR_MAIL');
                        return empty();
                    })
                );
                const versementsList = this.versementService.getVersements(
                    this.schoolId,
                    this.pageIndex,
                    this.pageSize,
					this.sortColumn,
					this.sortOrder
                );
                mailReceipt
                    .pipe(
                        switchMap(
                            (_) => {
                                return versementsList;
                            },
                            (failedMail, newList) => {
                                if (failedMail.length === 1 && failedMail[0].siren === 'EmailNotSent') {
                                    this.dialog.open(GenericPopinComponent, {
                                        width: '630px',
                                        disableClose: true,
                                        data: {
                                            mode: POPIN_MODS.MESSAGE,
                                            title: 'Information',
                                            bodyComponent: PopinErrorComponent,
                                            data: "Les reçus sont générés et l'envoi par mail va se faire par l'administrateur pour les entreprises rattachées à un groupeur.",
                                        },
                                    });
                                } else {
                                    failedMail = failedMail.filter((item) => item.siren !== 'EmailNotSent');
                                    failedMail = Array.from(new Set(failedMail.map((s) => s.siren))).map((sirenNum) => {
                                        return {
                                            siren: failedMail.find((s) => s.siren === sirenNum).siren,
                                            nomEntreprise: failedMail.find((s) => s.siren === sirenNum).nomEntreprise,
                                            gpCodeUnique: failedMail.find((s) => s.siren === sirenNum).gpCodeUnique,
                                        };
                                    });

                                    if (failedMail.length > 0) {
                                        const dialogRef = this.dialog.open(PopinVersementsWithoutMailComponent, {
                                            width: '800px',
                                            height: '635px',
                                            data: {
                                                mode: POPIN_MODS.MESSAGE,
                                                title: 'Important',
                                                bodyComponent: PopinVersementsWithoutMailComponent,
                                                data: failedMail,
                                            },
                                        });
                                    }
                                }
                                this.dataSource = new MatTableDataSource<IEcoleVersement>(newList.versements);
                                this.loading = false;
                                this.refreshSchoolInfo.emit();
                            }
                        )
                    )
                    .subscribe();
            });
    }

    generateSingleReceipt(versement: IEcoleVersement) {
        this.GetLastRecuNbr(this.schoolId)
            .pipe(
                switchMap((lastRecuNbr) => {
                    if (versement.numeroRecu) {
                        lastRecuNbr = versement.numeroRecu;
                    }
                    return this.generationProcess(versement, lastRecuNbr, 0);
                }),
                switchMap(
                    (_) => {
                        this.versementService.uploadLastReceiptDateInEcole(this.schoolId).subscribe();
                        return this.versementService.getVersements(this.schoolId, this.pageIndex, this.pageSize, this.sortColumn, this.sortOrder);
                    },
                    (_, newList) => {
                        this.dataSource = new MatTableDataSource<IEcoleVersement>(newList.versements);
                        this.refreshSchoolInfo.emit();
                        this.isGeneratingReceipt = false;
                    }
                ),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    downloadRecu(versement: IEcoleVersement) {
        if (versement.recuLiberatoire) {
            const fileExtension = versement.recuLiberatoire.substring(versement.recuLiberatoire.lastIndexOf('.'));
            this.versementService.downloadRecu(versement.versementId).subscribe((data) => {
                const blob = new Blob([data]);
                this.downloadLink = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = this.downloadLink;
                a.download = versement.siret + '_' + versement.numeroRecu + fileExtension;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
        }
    }

    private displayPopinError(translation: string, additionalData?: any) {
        this.loading = false;
        this.dialog.open(GenericPopinComponent, {
            width: '630px',
            disableClose: true,
            data: {
                mode: POPIN_MODS.MESSAGE,
                title: this.translate.instant('RECEIPT.ERROR_TITLE'),
                bodyComponent: PopinErrorComponent,
                data: this.translate.instant(translation) + (additionalData ? ` ${additionalData}` : ''),
            },
        });
    }

    updateSortParam(event) {
        this.sortColumn = event.column;
        this.sortOrder = event.sortDirection;
        this.isLoadingVersements = true;
        this.getProcessedPaymentList();
    }

    updatePageParam(event) {
        this.resetPaginator = false;
        this.pageIndex = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.isLoadingVersements = true;
        this.getProcessedPaymentList();
    }

    getVersements() {
        this.versementService
            .getVersements(this.schoolId, this.pageIndex, this.pageSize, this.sortColumn, this.sortOrder)
            .subscribe((data) => {
                this.totalCount = data.totalCount;
                this.dataSource = new MatTableDataSource<IEcoleVersement>(data.versements);
                this.nbPayements.emit(data.totalCount);
                this.isLoadingVersements = false;
                this.totalCount = data.totalCount;
            });
    }

    onClickedOutside(e) {
        if (e.target.title !== 'openPaymentFilterDiv') {
            if (e.target.offsetParent !== null) {
                if (e.target.offsetParent.title !== 'openPaymentFilterDiv') {
                    if (!this.toggleFilters) {
                        this.toggleFilters = true;
                    }
                }
            } else {
                if (e.target.title !== 'deleteFilter' && e.target.title !== 'selectOrder') {
                    if (!this.toggleFilters) {
                        this.toggleFilters = true;
                    }
                }
            }
        }
    }

    exportPayments() {
        this.isLoadingVersements = true;
        this.versementService
            .exportVersementList(this.schoolId)
            .pipe(
                finalize(() => {
                    this.isLoadingVersements = false;
                })
            )
            .subscribe(
                (data) => {
                    const fileExtension = '.xlsx';
                    const blob = new Blob([data]);
                    const downloadLink = URL.createObjectURL(blob);

                    const a = document.createElement('a');
                    a.href = downloadLink;
                    a.download = `export-versements-${new Date()
                        .toLocaleDateString()
                        .replace(/\//g, '-')}${fileExtension}`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                },
                (_) =>
                    this.dialog.open(GenericPopinComponent, {
                        width: '630px',
                        data: {
                            mode: POPIN_MODS.MESSAGE,
                            title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
                            bodyComponent: PopinErrorComponent,
                            data: "Une erreur s'est produite lors de l'export des données",
                        },
                    })
            );
    }

    private transformFiltersToBody(): IAPIVersementFilters {
        const body: IAPIVersementFilters = {
            searchValue: this.searchValue,
            montantVerse: null,
            order: '=',
            PageNumber: this.pageIndex,
            PageSize: this.pageSize,
            sortColumn: this.sortColumn,
            sortOrder: this.sortOrder,
            year: null,
        };
        if (this.activeFiltersNb >= 1) {
            body.montantVerse = parseFloat(
                this.schoolFunctService.paymentCurrentFilters[PAYMENT_FILTER_TYPE.MONTANT_VERSE][0]
            );
            if (this.schoolFunctService.paymentCurrentFilters[PAYMENT_FILTER_TYPE.ORDER][0]) {
                body.order = this.schoolFunctService.paymentCurrentFilters[PAYMENT_FILTER_TYPE.ORDER][0].nom;
            }
        }

        return body;
    }

    private sendOneReceipt(recuToSend: RecuToSend) {
        this.versementService
            .sendReceiptMail(recuToSend)
            .pipe(
                catchError((_) => {
                    this.displayPopinError('RECEIPT.ERROR_MAIL');
                    return empty();
                })
            )
            .subscribe((lightPayment) => {
                if (Object.keys(lightPayment).length === 3) {
                    this.displayPopinError('RECEIPT.ERROR_MAIL');
                }
                const data = this.dataSource.data;
                const editedEntryIndex = data.map((el) => el.versementId).indexOf(lightPayment.versementId);
                data[editedEntryIndex] = {
                    ...data[editedEntryIndex],
                    dateEnvoiRecu: lightPayment.dateEnvoiRecu,
                };
                this.dataSource.data = data;
            });
    }

    ngOnDestroy() {
        this.unsubscriber$.next();
        this.unsubscriber$.complete();
    }
}
