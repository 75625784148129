import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { IEcoleList, IEcoleListDisplay, IAPIFilters } from 'src/models/ecole.model';
import { SchoolApi } from 'src/app/api/schoolApi';
import { GroupApi } from 'src/app/api/groupApi';
import { StaticService } from 'src/app/services/functional-services/static-service/static-service';
import { SchoolFunctService } from 'src/app/services/functional-services/school-service/school-funct.service';
import { SCHOOL_FILTER_TYPE } from 'src/enums/ecole.enum';
import { FormControl } from '@angular/forms';
import { takeUntil, debounceTime, switchMap } from 'rxjs/operators';
import { UserApi } from 'src/app/api/userApi';


export interface DialogData {
	user: any;
	group: any;
	mode: string;
	schoolId: number;
	element: any;
}

@Component({
	selector: 'app-popin-add-ecoles',
	templateUrl: './popin-new-offre.component.html',
	styleUrls: ['./popin-new-offre.component.scss']
})
export class PopinNewOffreComponent implements OnInit {


	constructor(public dialogRef: MatDialogRef<PopinNewOffreComponent>) {
	}

	ngOnInit() {
    }
    

	onSubmit() {
		this.dialogRef.close();
	}

}
