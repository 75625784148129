import { Component, OnInit, Input } from '@angular/core';
import { } from '@angular/material';
import { USER_ROLE } from '../../../../enums/users.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddPromesseModel } from 'src/models/ecole.model';
import { Subject, throwError, BehaviorSubject, forkJoin, Observable, of, from, Subscription, empty, concat } from 'rxjs';
import { takeUntil, switchMap, debounceTime, finalize, catchError, concatMap, toArray, concatAll } from 'rxjs/operators';
import { SchoolFunctService } from 'src/app/services/functional-services/school-service/school-funct.service';
import { checkIfValidSiret, checkIfEmail, checkIfPhone } from 'src/utils/custom-validators';
import { POPIN_MODS } from 'src/enums/utils.enum';
import { AccountService } from 'src/app/api/services/account.service';
export interface DialogData {
	mode: string;
	schoolId: number;
	element: any;
}

@Component({
	selector: 'app-popin-promesse',
	templateUrl: './popin-promesse.component.html',
	styleUrls: ['./popin-promesse.component.scss']
})
export class PopinPromesseComponent implements OnInit {
  isAdmin: boolean = false;
	isCompanyNameLoading: boolean;
	form: FormGroup;
	private _data: AddPromesseModel;
	_unsubscriber$: Subject<void> = new Subject();

	@Input() mode;
	@Input() set data(data: AddPromesseModel) {
		this._data = data;
		this.form = this.formBuilder.group({
			siret: this.formBuilder.control(this.data.siret, checkIfValidSiret),
			nomEntreprise: this.formBuilder.control({ value: this.data.nomEntreprise, disabled: true }, Validators.required),
			nomEntreprise2: this.formBuilder.control(this.data.nomEntreprise2),
			entEmail: this.formBuilder.control(this.data.entEmail, [checkIfEmail, Validators.required]),
			entAdr1: this.formBuilder.control(this.data.entAdr1, Validators.required),
			entAdr2: this.formBuilder.control(this.data.entAdr2),
			entCp: this.formBuilder.control(this.data.entCp, Validators.required),
			entVille: this.formBuilder.control(this.data.entVille, Validators.required),
			nomContact: this.formBuilder.control(this.data.nomContact),
			prenomContact: this.formBuilder.control(this.data.prenomContact),
			email: this.formBuilder.control(this.data.email, checkIfEmail),
			telephone: this.formBuilder.control(this.data.telephone, checkIfPhone),
			total: this.formBuilder.control(this.data.total, Validators.required),
			deLaPartDe: this.formBuilder.control(this.data.deLaPartDe),
			pour: this.formBuilder.control(this.data.pour)
		});
	};


	get data(): AddPromesseModel {
		return this._data;
	}

	get telephoneControl() { return this.form.get('telephone') };
	get siret() { return this.form.get('siret') };
	get email() { return this.form.get('email') };
	get entEmail() { return this.form.get('entEmail') };


    constructor(private formBuilder: FormBuilder,
        private schoolFunctService: SchoolFunctService,
        private accountService: AccountService) {
	}

	ngOnInit() {
		this.siret.valueChanges.pipe(switchMap(val => {
			if (this.siret.valid) {
				this.isCompanyNameLoading = true;
				return this.schoolFunctService.getCompanyFromSiret(val.replace(/\s/g, '')).pipe(catchError(err => {
					if (err.status === 404) {
						this.form.get('nomEntreprise').enable();
					} else {
						console.error(err);
					}
					this.isCompanyNameLoading = false;
					return empty();
				}))
			} else {
				return empty();
			}
		})).subscribe(val => {
			this.isCompanyNameLoading = false;
			if (this.siret.value.includes(' ')) {
				this.siret.setValue(this.siret.value.contains(' ').replace(/\s/g, ''));
			}
			this.form.get('nomEntreprise').disable();
			this.form.get('nomEntreprise').setValue(val.entNom1);
			this.form.get('nomEntreprise2').disable();
			this.form.get('nomEntreprise2').setValue(val.entNom2);
			this.form.get('entAdr1').disable();
			this.form.get('entAdr1').setValue(val.entAdr1);
			this.form.get('entAdr2').disable();
			this.form.get('entAdr2').setValue(val.entAdr2);
			this.form.get('entCp').disable();
			this.form.get('entCp').setValue(val.entCp);
			this.form.get('entVille').disable();
			this.form.get('entVille').setValue(val.entVille);
			if (val.entEmail) {
				this.form.get('entEmail').disable();
				this.form.get('entEmail').setValue(val.entEmail);
			}

      this.accountService.currentUserRights$.pipe(takeUntil(this._unsubscriber$)).subscribe(data => {
          if (data.role === USER_ROLE.ADMIN || data.role === USER_ROLE.HOTLINER) {
              this.isAdmin = true;
          } else {
              this.isAdmin = false;
          }
      });
		});

		if (this.mode === POPIN_MODS.EDIT) {
			this.siret.disable();
		}
	}

	ngOnDestroy() {
		this._unsubscriber$.next();
		this._unsubscriber$.complete();
	}

}
