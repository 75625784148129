import { TaskEditData, Task } from "./../../../../models/task.model";
import { MatDialog, MatDialogRef } from "@angular/material";
import { RefusedTaskConfirmationComponent } from "src/app/components/popins/refused-task-confirmation/refused-task-confirmation.component";
import { TaskboardService } from "./../../../api/services/taskboard.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  TASK_STATUS_TEXT,
  TASK_ACTIONS,
  TYPE_TACHE,
  TASK_STATUS_ID,
} from "src/enums/task.enum";
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";
import { Subject } from "rxjs";
import { AccountService } from "src/app/api/services/account.service";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "app-task-details",
  templateUrl: "./task-details.component.html",
  styleUrls: ["./task-details.component.scss"],
})
export class TaskDetailsComponent implements OnInit {
  TASKTYPE = TYPE_TACHE;
  TASKSTATUSTEXT = TASK_STATUS_TEXT;
  TASKSTATUSID = TASK_STATUS_ID;

  task: Task;
  currentTaskStatus: string;
  stringTaskType: string;

  statusListDisplayed: boolean = false;

  isBeforeAfterTypeOfTask: boolean;

  private _taskId: number;

  afterForm: FormGroup;
  beforeForm: FormGroup;

  afterArrayItems: {
    value: string;
    label: string;
  }[] = [];
  beforeArrayItems: {
    value: string;
    label: string;
  }[] = [];

  get afterControlArray() {
    return this.afterForm.get("afterControlArray") as FormArray;
  }
  get beforeControlArray() {
    return this.beforeForm.get("beforeControlArray") as FormArray;
  }

  private _unsubscriber$: Subject<void> = new Subject();

  constructor(
    private dialog: MatDialog,
    private taskService: TaskboardService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this._taskId = +data.id;
    });

    this.taskService
      .getTask(this._taskId)
      .pipe(
        switchMap(
          (data) => {
            return this.accountService.currentUser$;
          },
          (data, user) => {
            this.task = data;
            this.task.userValidation = user.userId;
            this.setTitle(data);
            this.afterForm = this.fb.group({
              afterControlArray: this.fb.array([]),
            });
            this.beforeForm = this.fb.group({
              beforeControlArray: this.fb.array([]),
            });
            this.beforeControlArray.disable();
            if (this.task.statut !== TASK_STATUS_ID.WAITING) {
              this.afterControlArray.disable();
            }

            this.parseData(JSON.parse(this.task.data));
            this.currentTaskStatus = this.taskService.getTaskStatusTable()[
              this.task.statut
            ];
          }
        )
      )
      .subscribe();
  }

  addFormItems(item, controlArray, itemArray) {
    const control = this.fb.control(item.value);
    if (this.task.statut !== TASK_STATUS_ID.WAITING) {
      control.disable();
    }
    itemArray.push(item);
    controlArray.push(control);
  }

  private parseData(data: Object) {
    const before = data["Before"] || data["before"];
    let after = data["After"] || data["after"];
    if (!after) {
      after = data;
    }
    if (before) {
      this.isBeforeAfterTypeOfTask = true;
    } else {
      this.isBeforeAfterTypeOfTask = false;
    }
    if (!!after) {
      Object.keys(after).forEach((key) => {
        if (
          after[key] &&
          key.toLowerCase() !== "estactif" &&
          key.toLowerCase() !== "fichieriban" &&
          key.toLowerCase() !== "signature"
        ) {
          this.addFormItems(
            { label: `${key}`.toUpperCase(), value: after[key] },
            this.afterControlArray,
            this.afterArrayItems
          );
          if (!!before) {
            this.addFormItems(
              { label: `${key}`.toUpperCase(), value: before[key] },
              this.beforeControlArray,
              this.beforeArrayItems
            );
          }
        }
      });
    }
  }

  private updateDisplayedAndTaskStatus(status) {
    this.task.statut = status;
    this.currentTaskStatus = this.taskService.getTaskStatusTable()[
      this.task.statut
    ];
  }

  statusToRefused(status: number) {
    this.updateDisplayedAndTaskStatus(status);
    if (this.TASKSTATUSID.RESOLVED) {
      switch (this.task.taskType) {
        case TYPE_TACHE.COMPTE:
          const dialogRef = this.dialog.open(RefusedTaskConfirmationComponent, {
            width: "550px",
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              const temp = JSON.parse(this.task.data);
              temp.motifDeRefus = result;
              this.taskService
                .postDenyAccount(JSON.stringify(temp), this.task.id)
                .subscribe(
                  (val) => {
                    this.router.navigateByUrl(`taskboard`);
                  },
                  (err) =>
                    this.updateDisplayedAndTaskStatus(TASK_STATUS_ID.WAITING)
                );
            } else {
              this.updateDisplayedAndTaskStatus(TASK_STATUS_ID.WAITING);
            }
          });

          break;
        default:
          this.taskService.postDenyGenericTask(this.task).subscribe(
            (val) => {
              this.router.navigateByUrl(`taskboard`);
            },
            (err) => this.updateDisplayedAndTaskStatus(TASK_STATUS_ID.WAITING)
          );
          break;
      }
    }
  }

  statusToResolved(status: number) {
    this.updateDisplayedAndTaskStatus(status);
    if (this.TASKSTATUSID.RESOLVED === status) {
      switch (this.task.taskType) {
        case TYPE_TACHE.COMPTE:
          this.taskService
            .postAccountValidation(this.task.data, this.task.id)
            .subscribe(
              (val) => {
                this.router.navigateByUrl(`utilisateur/${val.id}`);
              },
              (err) => this.updateDisplayedAndTaskStatus(TASK_STATUS_ID.WAITING)
            );
          break;
        case TYPE_TACHE.FORMULE:
          this.taskService.postValidateGenericTask(this.task).subscribe(
            (val) => {
              this.router.navigateByUrl(`taskboard`);
            },
            (err) => this.updateDisplayedAndTaskStatus(TASK_STATUS_ID.WAITING)
          );
          break;
        default:
          let taskdataJSON =
            JSON.parse(this.task.data).After ||
            JSON.parse(this.task.data).after;
          this.afterControlArray.controls.forEach((control, index) => {
            taskdataJSON[this.afterArrayItems[index].label] = control.value;
          });
          taskdataJSON = {
            after: taskdataJSON,
          };
          this.task.data = JSON.stringify(taskdataJSON);
          this.taskService.postValidateGenericTask(this.task).subscribe(
            (val) => {
              this.router.navigateByUrl(`taskboard`);
            },
            (err) => this.updateDisplayedAndTaskStatus(TASK_STATUS_ID.WAITING)
          );
          break;
      }
    }
  }

  statusToTraitee(status: number) {
    this.updateDisplayedAndTaskStatus(status);
    this.taskService.postTaskTraited(this.task.id).subscribe(
      (_) => this.router.navigateByUrl(`taskboard`),
      (err) => this.updateDisplayedAndTaskStatus(TASK_STATUS_ID.WAITING)
    );
  }

  ngOnDestroy() {
    this._unsubscriber$.next();
    this._unsubscriber$.complete();
  }

  private setTitle(task: Task) {
    const obj = JSON.parse(task.data);
    if (task.targetId === null) {
      switch (task.taskType) {
        case TYPE_TACHE.COMPTE:
          this.stringTaskType =
            obj.Nom && obj.CodeUAI
              ? `${obj.Nom} | ${obj.CodeUAI}`
              : `${obj.Nom}`;
          break;
        case TYPE_TACHE.DIPLOME:
          this.stringTaskType = `${task.ecoleNom} | ${task.ecoleReference}`;
          break;
        case TYPE_TACHE.ECOLE:
          this.stringTaskType = `${task.ecoleNom} | ${task.ecoleReference}`;
          break;
        case TYPE_TACHE.IBAN:
          this.stringTaskType = `${task.ecoleNom} | ${task.ecoleReference}`;
          break;
        case TYPE_TACHE.SIGNATURE:
          this.stringTaskType = `${task.ecoleNom} | ${task.ecoleReference}`;
          break;
      }
    } else if (task.taskType === TYPE_TACHE.FORMULE) {
      this.stringTaskType = `Demande de souscription`;
    } else {
      this.stringTaskType = `${task.ecoleNom} | ${task.ecoleReference}`;
    }
  }

  downloadFile() {
    if (this.task.file != null) {
      let byteCharacters = atob(this.task.file);
      let byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let blob = null;
      if (this.task.file.charAt(0) === "/") {
        blob = new Blob([byteArray], { type: "image/jpeg" });
        if (blob) {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "IBAN.jpeg");
          } else {
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
          }
        }
      } else if (this.task.file.charAt(0) === "i") {
        blob = new Blob([byteArray], { type: "image/png" });
        if (blob) {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "IBAN.png");
          } else {
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
          }
        }
      } else if (this.task.file.charAt(0) === "J") {
        blob = new Blob([byteArray], { type: "application/pdf" });
        if (blob) {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "IBAN.pdf");
          } else {
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
          }
        }
      }
    }
  }
}
