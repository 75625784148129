import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SchoolApi } from '../../../../app/api/schoolApi';
import { ActivatedRoute } from '@angular/router';
import { IEcoleDiplome } from '../../../../models/ecole.model';
import { TableService, ObjectToDelete } from '../../../../app/services/functional-services/table-service/table-service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { LIST_TYPE } from 'src/enums/lists.type.enum';
import { MatDialog } from '@angular/material';
import { GenericPopinComponent } from 'src/app/components/popins/generic-popin/generic-popin.component';
import { PopinDiplomaComponent } from 'src/app/components/popins/popin-diploma/popin-diploma.component';
import { POPIN_MODS } from 'src/enums/utils.enum';
import { SchoolFunctService } from 'src/app/services/functional-services/school-service/school-funct.service';
import { switchMap } from 'rxjs/operators';
import { PopinErrorComponent } from 'src/app/components/popins/popin-error/popin-error.component';
import { TranslateService } from '@ngx-translate/core';
import { empty } from 'rxjs';
import { StaticService } from 'src/app/services/functional-services/static-service/static-service';
import { TaskboardService } from 'src/app/api/services/taskboard.service';
import { TYPE_TACHE, TASK_STATUS_ID } from 'src/enums/task.enum';
import { AccountService } from 'src/app/api/services/account.service';
import { UserRights } from 'src/models/user.model';
import { IHabilitations } from 'src/models/static.model';

@Component({
	selector: 'app-school-diploma',
	templateUrl: './school-diploma.component.html',
	styleUrls: ['./school-diploma.component.scss']
})
export class SchoolDiplomaComponent implements OnInit {

	tableProperties: string[] = ['codeDiplome', 'nom', 'nomDiplome', 'habilitations', 'duree'];
	dataSource: MatTableDataSource<IEcoleDiplome>;
	data: IEcoleDiplome[];
	schoolId: number;
	currentUserRights: UserRights;
	listType = LIST_TYPE.DIPLOMA_LIST;

	@Output() nbDiploma = new EventEmitter();

	private _unsubscriber$: Subject<void> = new Subject();

	constructor(private schoolApi: SchoolApi,
		private route: ActivatedRoute,
		private tableService: TableService,
		public dialog: MatDialog,
		private translate: TranslateService,
		public schoolFunctService: SchoolFunctService,
		private staticService: StaticService,
		private taskboardService: TaskboardService,
		private accountService: AccountService) { }

	ngOnInit() {
		this.route.params.subscribe(data => {
			this.schoolId = +data.id;
		});

		this.schoolApi.getSchoolDiplomas(this.schoolId).subscribe(data => {
			this.data = data;
			this.dataSource = new MatTableDataSource<IEcoleDiplome>(this.setDataViewModel(data))
			this.nbDiploma.emit(this.dataSource.data.length);
		});

		this.tableService.itemToDelete$.pipe(takeUntil(this._unsubscriber$)).subscribe(data => {
			if (data.listType === LIST_TYPE.DIPLOMA_LIST) {
				this.deleteDiploma(data);
			}
		})

		this.tableService.editDiploma$.pipe(takeUntil(this._unsubscriber$)).subscribe(diploma => {
			this.editDiploma(diploma)
		})

		this.accountService.currentUserRights$.pipe(takeUntil(this._unsubscriber$)).subscribe(va => {
			this.currentUserRights = va;
		})
	}

	setDataViewModel(data: IEcoleDiplome[]) {
		return data.map(diplome => {
			return { ...diplome, habilitations: this.staticService.habilitationsDictionnary[<number>diplome.habilitations] };
		});
	}

	deleteDiploma(item: ObjectToDelete) {
		this.schoolApi.deleteDiploma(item.itemId).pipe(switchMap(_ => {
			return this.schoolApi.getSchoolDiplomas(this.schoolId)
		})).subscribe(data => {
			this.data = data;
			this.dataSource = new MatTableDataSource<IEcoleDiplome>(this.setDataViewModel(data));
			this.nbDiploma.emit(this.dataSource.data.length);
		});
	}

	newDiploma() {
		const dialogRef = this.dialog.open(GenericPopinComponent, {
			width: '630px',
			data: {
				mode: POPIN_MODS.CREATION,
				title: this.translate.instant('SCHOOL.DIPLOMA.ADD_DIPLOMA'),
				bodyComponent: PopinDiplomaComponent,
				data: {}
			}
		});
		dialogRef.beforeClosed().pipe(switchMap(val => {
			if (val) {
				if (this.currentUserRights.canCreateUpdateDelete) {
					return this.schoolFunctService.addDiploma(val, this.schoolId);
				} else {
					return this.createTask(val, undefined, POPIN_MODS.CREATION);
				}
			} else return empty();

		}), takeUntil(this._unsubscriber$)).subscribe((val: IEcoleDiplome) => {
			if (val.diplomeId) {
				this.data.push(val);
				this.dataSource = new MatTableDataSource<IEcoleDiplome>(this.setDataViewModel(this.data));
				this.nbDiploma.emit(this.dataSource.data.length);
			}
		}, err => {
			this.dialog.open(GenericPopinComponent, {
				width: '630px',
				data: {
					mode: POPIN_MODS.MESSAGE,
					title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
					bodyComponent: PopinErrorComponent,
					data: "Une erreur est survenue. Veuillez réessayer. Si le problème persiste veuillez contacter un administrateur"
				}
			});
		});
	}

	private editDiploma(diploma: IEcoleDiplome) {
		const dialogRef = this.dialog.open(GenericPopinComponent, {
			width: '630px',
			data: {
				mode: POPIN_MODS.EDIT,
				title: this.translate.instant('SCHOOL.DIPLOMA.EDIT_DIPLOMA'),
				bodyComponent: PopinDiplomaComponent,
				data: diploma
			}
		});
		dialogRef.beforeClosed().pipe(switchMap(val => {
			if (val) {
				if (this.currentUserRights.canCreateUpdateDelete) {
					return this.schoolFunctService.editDiploma(val, this.schoolId, diploma.diplomeId);
				} else {
					return this.createTask(val, diploma, POPIN_MODS.EDIT);
				}
			} else return empty();
		}), takeUntil(this._unsubscriber$)).subscribe((val: IEcoleDiplome) => {
			if (val.diplomeId) {
				const indexOfDiplomeItemFromData = this.data.map(dip => dip.diplomeId).indexOf(val.diplomeId);
				this.data[indexOfDiplomeItemFromData] = val;
				this.dataSource = new MatTableDataSource<IEcoleDiplome>(this.setDataViewModel(this.data));
			}
		}, err => {
			this.dialog.open(GenericPopinComponent, {
				width: '630px',
				data: {
					mode: POPIN_MODS.MESSAGE,
					title: this.translate.instant('COMMON.POPINS.ERROR_TITLE'),
					bodyComponent: PopinErrorComponent,
					data: "Une erreur est survenue. Veuillez réessayer. Si le problème persiste veuillez contacter un administrateur"
				}
			});
		});
	}

	ngOnDestroy() {
		this._unsubscriber$.next();
		this._unsubscriber$.complete();
	}


	private createTask(val: any, diploma: IEcoleDiplome, mod: POPIN_MODS) {

		const taskEcoleData = { after: {} };
		Object.keys(val).forEach(key => {
			let currentPropValue = val[key];
			if (key === "habilitations" && diploma) {
				if (currentPropValue[0].nom !== diploma.habilitations) {
					taskEcoleData['after'][key] = currentPropValue[0].id;
				}
			} else if (key === "duree") {
				taskEcoleData['after'][key] = +currentPropValue;
			} else {
				if (!diploma) {
					if (key === "habilitations") {
						taskEcoleData['after'][key] = currentPropValue[0].id;
					} else {
						taskEcoleData['after'][key] = currentPropValue;
					}
				}
				else if (currentPropValue !== diploma[key]) {
					taskEcoleData['after'][key] = currentPropValue;
				}
			}
		})
		return this.taskboardService.createTask({
			taskType: TYPE_TACHE.DIPLOME,
			statut: TASK_STATUS_ID.WAITING,
			targetId: mod !== POPIN_MODS.CREATION ? diploma.diplomeId : null,
			data: JSON.stringify(taskEcoleData),
			ecoleId: this.schoolId
		});
	}

}
