import { Component, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StaticService } from './services/functional-services/static-service/static-service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AccountService } from './api/services/account.service';
import { TableService } from './services/functional-services/table-service/table-service';
import { IEcolePromesse, IEcoleVersement } from 'src/models/ecole.model';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = this.translate.instant('TABNAME');

	isUserConnected: boolean;
	blueSidebar = false;
	promise: IEcolePromesse;
	versement: IEcoleVersement;

	private _unsubscriber$: Subject<void> = new Subject();
	private _nextClickWillCLose = false;

	constructor(private translate: TranslateService,
		private staticService: StaticService,
		private accountService: AccountService,
		private tableService: TableService
	) {

		this.translate.setDefaultLang('en');
		this.translate.use('fr');
		this.staticService.getStatics();
		this.accountService.loadUserContext().pipe(switchMap(_ => {
			return this.accountService.currentUserRights$
		})).subscribe(_ => {
			this.isUserConnected = true;
		});

		this.tableService.openEntrepriseDetails$.pipe(takeUntil(this._unsubscriber$)).subscribe(promise => {
			this._nextClickWillCLose = promise.nextClickWillClose;
			this.promise = { ...promise };
			this.blueSidebar = true;
		})
		this.tableService.openEntrepriseVersementDetails$.pipe(takeUntil(this._unsubscriber$)).subscribe(versement => {
			this._nextClickWillCLose = versement.nextClickWillClose;
			this.versement = { ...versement };
			this.blueSidebar = true;
		})
	}

	closedBlueSidebar(forceClose: boolean = false) {
		if (forceClose) {
			this.blueSidebar = false;
		}
		if (this._nextClickWillCLose) {
			this.blueSidebar = false;
		} else if (this.blueSidebar) {
			this._nextClickWillCLose = true;
		}
	}

	ngOnDestroy() {
		this._unsubscriber$.next();
		this._unsubscriber$.complete();
	}

}
