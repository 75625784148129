import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { IVMSchoolFilters } from "../../../../models/ecole.model";
import { SchoolFunctService } from "../../../services/functional-services/school-service/school-funct.service";
import { PROMISE_FILTER_TYPE } from "../../../../enums/ecole.enum";
import { StaticService } from "../../../services/functional-services/static-service/static-service";

@Component({
  selector: "app-school-filter-promesse",
  templateUrl: "./school-filter-promesse.component.html",
  styleUrls: ["./school-filter-promesse.component.scss"],
})
export class SchoolFilterPromesseComponent implements OnInit, OnDestroy {
  @Output() getFilteredResult: EventEmitter<void> = new EventEmitter();

  promiseFilterForm: FormGroup;
  currentFilters: IVMSchoolFilters;

  order = [
    { id: 1, nom: "<" },
    { id: 2, nom: ">" },
    { id: 3, nom: "=" },
  ];

  etat = [
    { id: 1, nom: "Nouveau" },
    { id: 2, nom: "En cours" },
    { id: 3, nom: "Fait" },
  ];
  origine = [
    { id: 1, nom: "MyEcole" },
    { id: 2, nom: "MyTaxe" },
    { id: 3, nom: "Import" },
  ];

  orderControl = new FormControl();
  etatControl = new FormControl();
  origineControl = new FormControl();
  montantpromisControl = new FormControl();

  private _unsubscriber$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private schoolFunctService: SchoolFunctService,
    private staticService: StaticService
  ) {
    this.promiseFilterForm = fb.group({
      etat: this.etatControl,
      order: this.orderControl,
      origine: this.origineControl,
      montant_promis: this.montantpromisControl,
    });
  }

  ngOnInit() {
    this.updateFilterList();

    this.schoolFunctService.deleteEvent$.subscribe((_) => {
      this.currentFilters = this.schoolFunctService.getPromiseFiltersAsArray();
    });
  }

  submit(event) {
    event.preventDefault();
    this.schoolFunctService.addPromiseFilters(this.promiseFilterForm.value);
    this.updateFilterList();
    this.getFilteredResult.emit();
    this.promiseFilterForm.get("montant_promis").reset("");
  }

  reset(event) {
    event.preventDefault();
    this.schoolFunctService.deleteAllPromiseFilter();
    this.promiseFilterForm.get("montant_promis").reset("");
    this.promiseFilterForm.get("order").reset();
    this.promiseFilterForm.get("etat").reset();
    this.promiseFilterForm.get("origine").reset();
  }

  deleteFilter(filterTodelete: {
    id: number;
    name: string;
    type: PROMISE_FILTER_TYPE;
  }) {
    this.schoolFunctService.deletePromiseFilter(filterTodelete);
    this.updateFilterList();
  }

  updateFilterList() {
    //update Tag list si on a deja des filtres selectionnés
    this.currentFilters = this.schoolFunctService.getPromiseFiltersAsArray();

    //update
    this.etatControl.setValue(
      this.schoolFunctService.promiseCurrentFilters[PROMISE_FILTER_TYPE.ETAT]
    );
    this.orderControl.setValue(
      this.schoolFunctService.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORDER]
    );
    this.montantpromisControl.setValue(
      this.schoolFunctService.promiseCurrentFilters[
        PROMISE_FILTER_TYPE.MONTANT_PROMIS
      ]
    );
    this.origineControl.setValue(
      this.schoolFunctService.promiseCurrentFilters[PROMISE_FILTER_TYPE.ORIGINE]
    );
  }

  ngOnDestroy() {
    this._unsubscriber$.next();
    this._unsubscriber$.complete();
  }
}
