import { STATUT_TACHE, TYPE_TACHE } from 'src/enums/task.enum';
import { TaskboardService } from './../../api/services/taskboard.service';
import { Component, OnInit } from '@angular/core';
import { Task } from 'src/models/task.model';
import { SchoolFunctService } from 'src/app/services/functional-services/school-service/school-funct.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TASKBOARDTODO_FILTER_TYPE, TASKBOARDOLD_FILTER_TYPE } from 'src/enums/ecole.enum';
import { timingSafeEqual } from 'crypto';

@Component({
	selector: 'app-taskboard',
	templateUrl: './taskboard.component.html',
	styleUrls: ['./taskboard.component.scss']
})

export class TaskboardComponent implements OnInit {

	// public tasks = tasksExample;
	public tasks: Task[];
	public newTasks: Task[];
	public oldTasks: Task[];
	taskStatus = STATUT_TACHE;
	taskType = TYPE_TACHE;
	isLoading = false;
	isNewTasksLoading = false;
	isOldTasksLoading = false;

	toggleFiltersOldTasks = true;
	toggleFiltersNewTasks = true;
	activeFiltersNewTasksNb: number = 0;
	activeFiltersOldTasksNb: number = 0;

	private _unsubscriber$: Subject<void> = new Subject();

	constructor(
		private taskService: TaskboardService,
		private schoolFunctService: SchoolFunctService,
	) { }

	ngOnInit() {
		this.schoolFunctService.totalTaskboardFiltersTodo$.pipe(takeUntil(this._unsubscriber$)).subscribe(val => {
			// On vérifie d'appeller la fonction seulement quand on delete des filtres
			if (val < this.activeFiltersNewTasksNb) {
				this.activeFiltersNewTasksNb = val;
				this.getTaskboardNewTasksTodoList();
			} else {
				this.activeFiltersNewTasksNb = val;
			}
		});
		this.schoolFunctService.totalTaskboardFiltersOld$.pipe(takeUntil(this._unsubscriber$)).subscribe(val => {
			// On vérifie d'appeller la fonction seulement quand on delete des filtres
			if (val < this.activeFiltersOldTasksNb) {
				this.activeFiltersOldTasksNb = val;
				this.getTaskboardNewTasksOldList();
			} else {
				this.activeFiltersOldTasksNb = val;
			}
		});
		this.isLoading = true;
		this.getTaskboardNewTasksTodoList();
		this.getTaskboardNewTasksOldList();
	}

	getCount(status: number) {
		if (status === STATUT_TACHE.NON_TRAITEE) {
			return this.tasks.filter(task => task.statut === STATUT_TACHE.NON_TRAITEE || task.statut === STATUT_TACHE.TRAITEE).length;
		} else {
			return this.tasks.filter(task => task.statut !== STATUT_TACHE.NON_TRAITEE).length;
		}
	}

	refreshTaskboard() {
		this.isLoading = true;
		this.getTaskboardNewTasksTodoList();
		this.getTaskboardNewTasksOldList();
	}

	getTaskboardNewTasksTodoList() {
		this.toggleFiltersNewTasks = true;
		this.isNewTasksLoading = true;
		if (this.activeFiltersNewTasksNb >= 1) {
			const ids = this.schoolFunctService.taskboardCurrentFiltersTodo[TASKBOARDTODO_FILTER_TYPE.TYPE].map(v => v.id);
			this.taskService.getTasks().subscribe(data => {
				const goodOnes = [];
				data.forEach(v => {
					for (let i = 0; i < ids.length; i++) {
						if (v.taskType === ids[i] && v.dateValidation === null) {
							goodOnes.push(v);
						}
					}
				})
				this.newTasks = goodOnes;
				this.isNewTasksLoading = false;
				this.isLoading = false;
			});
		} else {
			this.taskService.getTasks().subscribe(data => {
				this.newTasks = [];
				data.forEach(element => {
					if (element.dateValidation === null) {
						this.newTasks.push(element);
					}
					this.isNewTasksLoading = false;
					this.isLoading = false;
				});
			});
		}
	}

	getTaskboardNewTasksOldList() {
		this.toggleFiltersOldTasks = true;
		this.isOldTasksLoading = true;
		if (this.activeFiltersOldTasksNb >= 1) {
			const idsType = this.schoolFunctService.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.TYPE].map(v => v.id);
			const idEtat = this.schoolFunctService.taskboardCurrentFiltersOld[TASKBOARDOLD_FILTER_TYPE.ETAT].map(v => v.id);
			this.taskService.getTasks().subscribe(data => {
				const goodOnes = [];
				data.forEach(v => {
					for (let i = 0; i < idsType.length || i < idEtat.length; i++) {
						if (idsType[i] && idEtat[0]) {
							if ((v.taskType === idsType[i] && v.statut === idEtat[0]) && v.dateValidation !== null) {
								goodOnes.push(v);
							}
						}
						else if (idsType[i]) {
							if (v.taskType === idsType[i] && v.dateValidation !== null) {
								goodOnes.push(v);
							}
						}
						else if (idEtat[0]) {
							if (v.statut === idEtat[0] && v.dateValidation !== null) {
								goodOnes.push(v);
							}
						}
					}
				});
				this.oldTasks = goodOnes.sort(function (a, b) {
					return (new Date(b.dateValidation) as any) - (new Date(a.dateValidation) as any);
				});
				this.isOldTasksLoading = false;
				this.isLoading = false;
			});
		} else {
			this.taskService.getTasks().subscribe(data => {
				this.oldTasks = [];
				data.forEach(element => {
					if (element.dateValidation !== null) {
						this.oldTasks.push(element);
					}
				});
				this.oldTasks = this.oldTasks.sort(function (a, b) {
					return (new Date(b.dateValidation) as any) - (new Date(a.dateValidation) as any);
				});
				this.isOldTasksLoading = false;
				this.isLoading = false;
			});
		}
	}

	onClickedOutsideNewTasks(e) {
		if (e.target.title !== 'openFilterNewTasks') {
			if (e.target.offsetParent !== null) {
				if (e.target.offsetParent.title !== 'openFilterNewTasks') {
					if (!this.toggleFiltersNewTasks) {
						this.toggleFiltersNewTasks = true;
					}
				}
			} else {
				if (e.target.title !== 'openFilterNewTasks') {
					if (!this.toggleFiltersNewTasks) {
						this.toggleFiltersNewTasks = true;
					}
				}
			}
		}
	}


	onClickedOutsideOldTasks(e) {
		if (e.target.title !== 'openFilterOldTasks') {
			if (e.target.offsetParent !== null) {
				if (e.target.offsetParent.title !== 'openFilterOldTasks') {
					if (!this.toggleFiltersOldTasks) {
						this.toggleFiltersOldTasks = true;
					}
				}
			} else {
				if (e.target.title !== 'openFilterOldTasks' && e.target.title !== 'selectOrder') {
					if (!this.toggleFiltersOldTasks) {
						this.toggleFiltersOldTasks = true;
					}
				}
			}
		}
	}

}
