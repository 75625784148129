import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Task, TaskModif } from 'src/models/task.model';
import { TASK_STATUS_TEXT } from 'src/enums/task.enum';

@Injectable({
	providedIn: 'root'
})
export class TaskboardService {

	private _taskStatusTable: string[] = [];

	constructor(private http: HttpClient) {
		this._taskStatusTable[1] = TASK_STATUS_TEXT.WAITING;
		this._taskStatusTable[2] = TASK_STATUS_TEXT.RESOLVED;
		this._taskStatusTable[3] = TASK_STATUS_TEXT.REFUSED;
		this._taskStatusTable[4] = TASK_STATUS_TEXT.DELETED;
		this._taskStatusTable[5] = TASK_STATUS_TEXT.TRAITEE;

	}

	createTask(task: Task): Observable<boolean> {
		return this.http.post<boolean>(`/api/Taskboard/Create`, task);
	}

	// get tasks list
	getTasks(): Observable<Task[]> {
		return this.http.get<Task[]>('/api/Taskboard');
	}

	// get specific task
	getTask(id: number): Observable<Task> {
		return this.http.get<Task>('/api/Taskboard/' + id);
	}

	getTaskStatusTable() {
		return this._taskStatusTable;
	}

	getSchoolListByNameOrCodeUai(searchString: string) {
		return this.http.get<any>(`/api/Taskboard/task/schools/${searchString}`, {});
	}

	postAccountValidation(user: any, taskId: number) {
		return this.http.post<any>(`/api/Taskboard/${taskId}/validateAccount`, JSON.parse(user));
	}

	postDenyAccount(user: any, taskId: number) {
		return this.http.post(`/api/Taskboard/${taskId}/denyAccount`, JSON.parse(user));
	}

	postDenyGenericTask(task: Task) {
		return this.http.post(`/api/Taskboard/${task.id}/deny`, task);
	}

	postValidateGenericTask(task: Task) {
		return this.http.post(`/api/Taskboard/${task.id}/validate`, task);
	}

	postTaskTraited(id: number) {
		return this.http.post(`/api/Taskboard/${id}/setTraitee`, {});
	}
}
