import { Component, OnInit } from '@angular/core';
import { element } from 'protractor';
import { PaiementApi } from 'src/app/api/paiementApi';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';


// declare var StripeCheckout: StripeCheckoutStatic;
declare var Stripe: any;

@Component({
    selector: 'app-stripe-subscribe',
    templateUrl: './stripe-subscribe.component.html',
    styleUrls: ['./stripe-subscribe.component.scss']
})
export class StripeSubscribeComponent implements OnInit {

    paymentCard = true;
    succes = false;
    echec = false;
    CLIENT_SECRET: any;
    clientSecretIban: any;
    clientSecretCard: any;
    selectedFormule: any;
    currentPlan: any;
    stripeInstance = null;
    elements = null;
    iban = null;
    cardElement = null;

    constructor(private paiementApi: PaiementApi, private router: Router) { }

    ngOnInit() {

        //on observe la formule selectionné via l'url
        this.selectedFormule = window.location.pathname[13];
        this.paiementApi.getApiKey()
            .subscribe(data => {
                this.stripeInstance = Stripe(data);
                this.elements = this.stripeInstance.elements();
                // Create an instance of the IBAN Element
                this.iban = this.elements.create('iban', options);
                // Add an instance of the IBAN Element into the `iban-element` <div>
                this.iban.mount('#iban-element');

                this.iban.on('change', function (event) {
                    const displayError = document.getElementById('error-message');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }
                });

                this.cardElement = this.elements.create("card", { hidePostalCode: true, style: styleCard });
                this.cardElement.mount("#card-element");
                this.cardElement.addEventListener('change', ({ error }) => {
                    const displayError = document.getElementById('card-errors');
                    if (error) {
                        displayError.textContent = error.message;
                    } else {
                        displayError.textContent = '';
                    }
                });
            });


        //on récupère la clé api stripe pour creer l'instance
        //const stripeInstance = Stripe(environment.stripe.apiKey);
        //const elements = stripeInstance.elements();


        this.paiementApi.getFormules()
            .subscribe((result) => {
                this.selectedFormule = window.location.pathname[13];
                this.currentPlan = result[parseInt(this.selectedFormule) - 1];

            });

        // payement par iban
        const styleIban = {
            base: {
                color: '#32325d',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                },
                ':-webkit-autofill': {
                    color: '#32325d',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
                ':-webkit-autofill': {
                    color: '#fa755a',
                },
            },
        };

        const options = {
            style: styleIban,
            supportedCountries: ['SEPA'],
            // Elements can use a placeholder as an example IBAN that reflects
            // the IBAN format of your customer's country. If you know your
            // customer's country, we recommend that you pass it to the Element as the
            // placeholderCountry.
            placeholderCountry: 'FR',
        };



        const formIban = document.getElementById('payment-iban');
        const accountholderName = (<HTMLInputElement>document.getElementById('accountholder-name'));
        const email = (<HTMLInputElement>document.getElementById('email'));
        const emailabonne = (<HTMLInputElement>document.getElementById('emailuserabo'));

        // prélévement avec iban
        formIban.addEventListener('submit', (event) => {
          event.preventDefault();
          this.paiementApi.getInitPayment(this.currentPlan.paiementPlanId, emailabonne.value, "iban")
            .subscribe(result => { 
            this.stripeInstance.confirmSepaDebitPayment(
              result[0],
                {
                    payment_method: {
                        sepa_debit: this.iban,
                        billing_details: {
                            name: accountholderName.value,
                            email: email.value,
                        },
                    },
                }
            ).then(
                (result) => {
                    this.paiementApi.postUpdatePaymentIntent(result)
                        .subscribe(
                            (result) => {
                                var eabonne = (<HTMLInputElement>document.getElementById('emailuserabo'));
                                this.paiementApi.postCreateSouscription(eabonne.value)
                                    .subscribe((result) => {
                                        this.succes = true;
                                    },
                                        (err) => {
                                            this.echec = true;
                                        });
                            },
                            (err) => {
                                this.echec = true;
                            }
                        );
                },
                (err) => {
                    this.echec = true;
                }
              );
            });
        });


        const styleCard = {
            base: {
                color: "#32325d",
            }
        };

        const formCard = document.getElementById('payment-card');
        const nameCard = (<HTMLInputElement>document.getElementById('name-card'));
        const emailCard = (<HTMLInputElement>document.getElementById('email-card'));

        // prélévement avec CB - carte bleue
        formCard.addEventListener('submit', (ev) => {
            ev.preventDefault();
            var eabonne = (<HTMLInputElement>document.getElementById('emailuserabocard'));
            //on récupère le Payementintent qui contient le client_secret puis on stocke notre client_secret
            //dont on aura besoin par la suite
            this.paiementApi.getInitPayment(this.currentPlan.paiementPlanId, eabonne.value, "card")
                .subscribe((result) => {

                    this.stripeInstance.confirmCardPayment(result[0],
                        {
                            payment_method: {
                                card: this.cardElement,
                                billing_details: {
                                    name: nameCard.value,
                                    email: emailCard.value,
                                }
                                //},
                                //setup_future_usage: 'off_session'
                            }
                        }
                    )
                        .then((result) => {

                            if (result.error) {
                                // Show error to your customer
                            } else {
                                if (result.paymentIntent.status === 'succeeded') {
                                    // Show a success message to your customer
                                    // There's a risk of the customer closing the window before callback execution
                                    // Set up a webhook or plugin to listen for the payment_intent.succeeded event
                                    // to save the card to a Customer

                                    // The PaymentMethod ID can be found on result.paymentIntent.payment_method

                                    this.paiementApi.postUpdatePaymentIntent(result.paymentIntent.payment_method)
                                        .subscribe((result) => {


                                            this.paiementApi.postCreateSouscription(eabonne.value)
                                                .subscribe((result) => {
                                                    this.succes = true;
                                                }, (err) => {
                                                    this.echec = true;
                                                });
                                        }, (err) => {
                                            this.echec = true;
                                        });
                                }
                            }
                        }
                            , (err) => {
                                this.echec = true;
                            }
                        )
                });
        });
    }

    resetState() {
        this.succes = false;
        this.echec = false;
    }
}
