import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogData } from '../popin-promesse/popin-promesse.component';
import { PromesseService } from 'src/app/api/services/promesse.service';

@Component({
	selector: 'app-close-payment-serie',
	templateUrl: './close-payment-serie.component.html',
	styleUrls: ['./close-payment-serie.component.scss']
})
export class ClosePaymentSerieComponent implements OnInit {

	constructor(
		private promiseService: PromesseService,
		private dialogRef: MatDialogRef<ClosePaymentSerieComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { bordereauNbr: number, autoEndEntry: boolean, currentNb: number }
	) {
		dialogRef.disableClose = true;
	}

	new_saisie = false;

	ngOnInit() {
	}

	downloadFile() {
		this.promiseService.getBordereauFile(this.data.bordereauNbr).subscribe(data => {
			this.new_saisie = true;
			const fileExtension = '.xlsx'
			const blob = new Blob([data]);
			const downloadLink = URL.createObjectURL(blob);

			const a = document.createElement('a');
			a.href = downloadLink;
			a.download = `${this.data.bordereauNbr}${fileExtension}`;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		})
	}

}
