import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { IVMSchoolFilters } from '../../../../models/ecole.model';
import { SchoolFunctService } from '../../../services/functional-services/school-service/school-funct.service';
import { SCHOOL_FILTER_TYPE } from '../../../../enums/ecole.enum';
import { StaticService } from '../../../services/functional-services/static-service/static-service';
import { IHabilitations, IRegion, IDepartment } from '../../../../models/static.model';

@Component({
	selector: 'app-school-filter-box',
	templateUrl: './school-filter-box.component.html',
	styleUrls: ['./school-filter-box.component.scss']
})
export class SchoolFilterBoxComponent implements OnInit, OnDestroy {

	@Output() getFilteredResult: EventEmitter<void> = new EventEmitter();


	type = [
		{ id: 1, nom: "École Membre" },
		{ id: 2, nom: "École Partenaire" },
		{ id: 3, nom: "Autres Écoles" }
	]

	schoolFilterForm: FormGroup;

	currentFilters: IVMSchoolFilters;

	habilitations: IHabilitations[] = [];
	regions: IRegion[] = []
	departements: IDepartment[] = [];
	allDepartements: IDepartment[] = [];

	typeControl = new FormControl();
	habilitationsControl = new FormControl();
	departementsControl = new FormControl();
	regionsControl = new FormControl();
	uaiControl = new FormControl('');
	cdmControl = new FormControl('');

	private _unsubscriber$ = new Subject();

	chosenRegions: IRegion[] = [];

	constructor(private fb: FormBuilder, private schoolFunctService: SchoolFunctService, private staticService: StaticService) {
		this.schoolFilterForm = fb.group({
			habilitations: this.habilitationsControl,
			departements: this.departementsControl,
			regions: this.regionsControl,
			code_uai: this.uaiControl,
			code_cdm: this.cdmControl,
			type: this.typeControl
		})

	}

	ngOnInit() {
		this.staticService.habilitations$.pipe(takeUntil(this._unsubscriber$)).subscribe(val => { if (val) { this.habilitations = val.map(el => { return { ...el, id: el.habilitationId, nom: el.nom } }) } });
		this.staticService.regions$.pipe(takeUntil(this._unsubscriber$)).subscribe(val => { if (val) { this.regions = val.map(el => { return { ...el, id: el.regionId, nom: el.nom } }) } });
		this.staticService.departements$.pipe(takeUntil(this._unsubscriber$)).subscribe(val => {
			if (val) {
				this.allDepartements = val.map(el => { return { ...el, id: el.departmentId, nom: el.nom } })
				this.departements = this.allDepartements;
			}
		});
		this.updateFilterList();

		this.schoolFunctService.deleteEvent$.subscribe(_ => {
			this.currentFilters = this.schoolFunctService.getFiltersAsArray();
		})

		this.regionsControl.valueChanges.subscribe(data => {
			if (data) {
				this.chosenRegions = data.map(el => {
					return { ...el, id: el.regionId }
				}
				)
			}
			this.updateDepartementFilters();
		})
	}

	submit(event) {
		event.preventDefault();
		this.schoolFunctService.addSchoolFilters(this.schoolFilterForm.value);
		this.updateFilterList();
		this.getFilteredResult.emit();
		this.schoolFilterForm.get('code_uai').reset('');
		this.schoolFilterForm.get('code_cdm').reset('');
	}

	reset(event) {
		event.preventDefault();
		this.schoolFunctService.deleteAllSchoolFilter()
		this.schoolFilterForm.get('code_uai').reset('');
		this.schoolFilterForm.get('code_cdm').reset('');
		this.schoolFilterForm.get('departements').reset();
		this.schoolFilterForm.get('regions').reset();
		this.schoolFilterForm.get('habilitations').reset();
		this.schoolFilterForm.get('type').reset();
		this.departements = this.allDepartements;
	}

	deleteFilter(filterTodelete: { id: number, name: string, type: SCHOOL_FILTER_TYPE }) {
		this.schoolFunctService.deleteSchoolFilter(filterTodelete);
		this.updateFilterList();
		this.getFilteredResult.emit();
	}

	updateFilterList() {
		//update Tag list
		this.currentFilters = this.schoolFunctService.getFiltersAsArray();


		//update 
		this.habilitationsControl.setValue(this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.HABILITATIONS]);
		this.departementsControl.setValue(this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.DEPARTEMENT]);
		this.regionsControl.setValue(this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.REGION]);
		this.typeControl.setValue(this.schoolFunctService.schoolCurrentFilters[SCHOOL_FILTER_TYPE.TYPE]);
	}

	updateDepartementFilters() {
		this.departements = [];

		if (this.chosenRegions.length <= 0) {
			this.departements = this.allDepartements;
		} else {
			var idRegions: string[] = [];
			for (var i = 0; i < this.chosenRegions.length; i++) {
				idRegions.push(this.chosenRegions[i].regionId);
			}
			this.allDepartements.filter(d => {
				if (idRegions.includes(d.regionId.toString())) {
					this.departements.push(d);
				}
			});
		}
	}

	ngOnDestroy() {
		this._unsubscriber$.next();
		this._unsubscriber$.complete();
	}
}

